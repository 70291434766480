/**
 * ArkCheckbox
 */

import React from 'react'

import { Checkbox, CheckboxProps } from 'semantic-ui-react'

import styles from './ArkCheckbox.module.css'

export interface ArkCheckboxProps extends CheckboxProps {}

const ArkCheckbox = (props: ArkCheckboxProps) =>
  <Checkbox {...props} className={`${styles.checkbox} ${props.className || ''}`} />
export default ArkCheckbox
