import React, { createContext, ReactNode, useContext, useEffect } from 'react'
import _ from 'lodash'

import { IMAGES_MOCK_API_ENABLED } from 'src/constants/config'

import { Image, UserCompany } from '../models'
import ServerAPIClient from '../services/ServerAPIClient'
import { MockAPIContextValue, useMockAPI } from './MockAPIProvider'
import { IServerContext, useServer } from './ServerProvider'
import { IUserContext, useUser } from './UserProvider'
import { DEFAULT_IMAGE } from '../models/image'

/**
 * interactive functions include dialog boxes (e.g. 'Something went wrong.')
 */

export type CompanyImageContextValue = {
  uploadImage: (file: File) => Promise<Image | undefined>,
  uploadImageInteractive: (file: File) => Promise<Image | undefined>,
}

const CompanyImageContext = createContext<CompanyImageContextValue>({} as CompanyImageContextValue)

export const useCompanyImage = () => useContext(CompanyImageContext)

type CompanyImageProviderProps = {
  children: ReactNode
}

const CompanyImageProvider = (props: CompanyImageProviderProps) => {
  const mockAPI: MockAPIContextValue = useMockAPI()
  const server: IServerContext = useServer()
  const user: IUserContext = useUser()

  const company: UserCompany = user.store.selectedCompany!
  const serverAPIClient: ServerAPIClient = server.store.apiClient!

  useEffect(() => {
    // console.log('CompanyImageProvider - load')
    return () => {
      // console.log('CompanyImageProvider - unload')
    }
  }, [])

  const uploadImage = async (file: File): Promise<Image | undefined> => {
    try {
      // console.log('CompanyImageProvider - uploadImage - file:', file)
      let image: Image
      if (IMAGES_MOCK_API_ENABLED) {
        const getDataUrl = (file: File): Promise<string> => new Promise((resolve) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result as string)
        })
        const url: string = await getDataUrl(file)
        image = {
          ...DEFAULT_IMAGE,
          id: Date.now(),
          file_name_original: file.name,
          url
        }
        const oldMockImages: Image[] = await mockAPI.getImages()
        const newMockImages: Image[] = [...oldMockImages, image]
        await mockAPI.setImages(newMockImages)
      } else {
        const formData = new FormData()
        formData.append('dataFile', file)
        // FIXME add response type
        const response = await serverAPIClient.apiPost('/company/image/', formData, { 'company-id': company.id })
        // console.log('CompanyImageProvider - uploadImage - response:', response)
        // FIXME check response status
        // FIXME add type guard
        image = _.first(response.data.message)!
      }
      return image
    } catch (error) {
      console.error('CompanyImageProvider - uploadImage - error:', error.message)
    }
  }

  const uploadImageInteractive = async (file: File): Promise<Image | undefined> => {
    try {
      // console.log('CompanyImageProvider - uploadImageInteractive - props:', props)
      const image: Image | undefined = await uploadImage(file)
      if (!image) throw Error('upload image failed')
      return image
    } catch (error) {
      console.error('CompanyImageProvider - uploadImageInteractive - error:', error.message)
      window.alert('Something went wrong.') // FIXME add toast
    }
  }

  return (
    <CompanyImageContext.Provider value={{
      uploadImage,
      uploadImageInteractive
    }}>
      {props.children}
    </CompanyImageContext.Provider>
  )
}

export default CompanyImageProvider
