import React, { useContext, useEffect, useRef, useState } from 'react'
import * as yup from 'yup'
import WebRTCBroadcaster, { WebRTCBroadcasterConnectionState, WebRTCBroadcasterSettings } from './libs/WebRTCBroadcaster/WebRTCBroadcaster'

import { Program } from 'src/core/models'
import { ProjectAdminContext } from 'src/core/providers'
import { NavContext, NavSection } from 'src/core/providers/NavProvider'

import ArkButton from 'src/core/components/ArkButton'
import ArkForm, { ArkFormField, ArkFormFieldOption, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'
import ArkIcon from 'src/core/components/ArkIcon'
import ArkIconButton from 'src/core/components/ArkIconButton'

import styles from './ProjectBroadcastView.module.css'

const formSchema = yup.object().shape({
  program: yup.number().required(),
  videoDevice: yup.string().required(), // TODO: only required if video enabled?
  videoResolution: yup.string().required(), // TODO: only required if video enabled? TODO: check if one of the valid options?
  videoBandwidth: yup.number().required().label('Video Bandwidth').typeError('Video Resolution must be a number') // TODO: only required if video enabled? TODO: min (& max?)?
  // TODO: add other fields...
})

interface ProjectBroadcastVideoRes {
  key: string, width: number, height: number
}

export interface ProjectBroadcastViewProps {
  companyId: number
  projectId: number
}

const ProjectBroadcastView = (props: ProjectBroadcastViewProps) => {
  const { companyId, projectId } = props

  const videoResolutions: Array<ProjectBroadcastVideoRes> = [
    { key: '1920x1080', width: 1920, height: 1080 }, // TESTING: (re)enabled to test recent video res changes - TODO: check if 1080p is broadcast out (in early tests it seemed to output 720p regardless? was it device specific, errored out or something perhaps?)
    { key: '1280x720', width: 1280, height: 720 },
    { key: '640x480', width: 640, height: 480 },
    { key: '320x240', width: 320, height: 240 }
  ]
  const videoResolutionDefault = videoResolutions[0] // default to 1280x720 currently
  const videoBandwidthDefault = 2000

  const projectAdminContext = useContext(ProjectAdminContext)
  const navContext = useContext(NavContext)

  const [programs, setPrograms] = useState<Array<Program> | undefined>(undefined)
  const [loadingPrograms, setLoadingPrograms] = useState<boolean>(false)
  const [loadingError, setLoadingError] = useState<Error | undefined>(undefined)

  const broadcasterRef = useRef<WebRTCBroadcaster>()
  const [videoInputDevices, setVideoInputDevices] = useState<Array<MediaDeviceInfo>>()
  const [audioInputDevices, setAudioInputDevices] = useState<Array<MediaDeviceInfo>>()

  const [isEnabled, setIsEnabled] = useState<boolean>(false)
  const [mediaDevicesLoaded, setMediaDevicesLoaded] = useState<boolean>(false)
  const [canStart, setCanStart] = useState<boolean>(false)
  const [isStarting, setIsStarting] = useState<boolean>(false)
  const [isBroadcasting, setIsBroadcasting] = useState<boolean>(false)
  const [broadcastError, setBroadcastError] = useState<Error | undefined>()

  const [program, setProgram] = useState<Program | undefined>()
  const [videoEnabled, setVideoEnabled] = useState<boolean>(true)
  const [audioEnabled, setAudioEnabled] = useState<boolean>(false)
  const [videoResolution, setVideoResolution] = useState<ProjectBroadcastVideoRes | undefined>(videoResolutionDefault)
  const [videoBandwidth, setVideoBandwidth] = useState<number | undefined>()
  const [videoDevice, setVideoDevice] = useState<string | undefined>()
  const [audioDevice, setAudioDevice] = useState<string | undefined>()

  const videoPreviewRef = useRef<HTMLVideoElement>(null) // React.RefObject<HTMLVideoElement>
  const [flipVideoPreview, setFlipVideoPreview] = useState<boolean>(false)

  const loadPrograms = async () => {
    console.log('ProjectBroadcastView - loadPrograms')
    if (loadingPrograms) return
    try {
      setLoadingPrograms(true)
      setLoadingError(undefined)
      // await new Promise((resolve) => setTimeout(resolve, 2000))
      const programs = await projectAdminContext.actions.getAllCompanyProjectPrograms(companyId, projectId)
      setPrograms(programs ?? undefined)
    } catch (error) {
      setLoadingError(error)
    }
    setLoadingPrograms(false)
  }

  const loadData = async () => {
    console.log('ProjectBroadcastView - loadData')
    if (!loadingError) await loadPrograms()
  }

  const onUpdatePrograms = async () => {
    await loadPrograms()
  }

  const updateCanStart = () => {
    let _canStart = false
    if (program && program.inputAuth && videoDevice) {
      _canStart = true
    }
    console.log('ProjectBroadcastView - updateCanStart - _canStart:', _canStart)
    // DEBUG ONLY: check why we can't start
    if (!_canStart) {
      console.log('ProjectBroadcastView - updateCanStart - WARNING: cannot start with current selections - program:', program)
      if (program && !program.inputAuth && videoDevice) { // TESTING: if its inputAuth specific
        console.log('ProjectBroadcastView - updateCanStart - WARNING: progam has no inputAuth data - cannot use as a webrtc input <<<<<')
      }
    }
    if (_canStart !== canStart) setCanStart(_canStart)
  }

  const loadMediaDevices = async () => {
    console.log('ProjectBroadcastView - loadMediaDevices')
    try {
      const _videoInputDevices: Array<MediaDeviceInfo> = [] // InputDeviceInfo
      const _audioInputDevices: Array<MediaDeviceInfo> = [] // InputDeviceInfo
      const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true }) //, audio: true
      if (!mediaStream) {
        throw new Error('Failed to load available input devices') // NB: shouldn't happen in normal usage (should have already thrown a relevant error), mainly just keeping the linter happy (& added as a precaution while trialing this)
      }
      // console.log('ProjectBroadcastView - loadMediaDevices - mediaStream:', mediaStream)
      const mediaDevices = await navigator.mediaDevices.enumerateDevices()
      // console.log('ProjectBroadcastView - loadMediaDevices - mediaDevices:', mediaDevices)
      for (const mediaDevice of mediaDevices) {
        // console.log('ProjectBroadcastView - loadMediaDevices - mediaDevice:', mediaDevice)
        if (mediaDevice.kind === 'videoinput') {
          _videoInputDevices.push(mediaDevice)
        } else if (mediaDevice.kind === 'audioinput') {
          _audioInputDevices.push(mediaDevice)
        }
      }
      console.log('ProjectBroadcastView - loadMediaDevices - _videoInputDevices:', _videoInputDevices)
      console.log('ProjectBroadcastView - loadMediaDevices - _audioInputDevices:', _audioInputDevices)
      setVideoInputDevices(_videoInputDevices)
      setAudioInputDevices(_audioInputDevices)

      // auto select the first device if one isn't currently selected
      if (!videoDevice && _videoInputDevices && _videoInputDevices.length > 0) {
        setVideoDevice(_videoInputDevices[0].deviceId)
      }
      if (!audioDevice && _audioInputDevices && _audioInputDevices.length > 0) {
        setAudioDevice(_audioInputDevices[0].deviceId)
      }

      setMediaDevicesLoaded(true)
    } catch (error: any) {
      console.error('ProjectBroadcastView - loadMediaDevices - error:', error)
    }
  }

  // NB: only accepts the video & audio inputs, & vide resolution/size here, the rest are supplied when starting a broadcast (webrtcUrl, videoBw etc.)
  const startInputs = async (
    inputVideoDeviceId?: string,
    inputAudioDeviceId?: string,
    videoRes?: ProjectBroadcastVideoRes
  ) => {
    console.log('ProjectBroadcastView - startInputs - inputVideoDeviceId:', inputVideoDeviceId, ' inputAudioDeviceId:', inputAudioDeviceId, ' videoRes:', videoRes)
    // TODO: check if an instance is already set at `window.webRTCBroadcasterInstance` & use that instead?
    if (broadcasterRef.current !== undefined) {
      console.log('ProjectBroadcastView - startInputs - WARNING - broadcasterRef ALREADY SET - HALT <<<<')
      // TODO: handle...
      return
    }
    const width = videoRes?.width ?? videoResolutionDefault.width
    const height = videoRes?.height ?? videoResolutionDefault.height
    const config: WebRTCBroadcasterSettings = {
      whipUrl: undefined,
      videoRequired: inputVideoDeviceId !== undefined,
      audioRequired: inputAudioDeviceId !== undefined, // TODO: add audio support (currently always triggers a permission/system error? maybe related to localhost usage or something dev specific perhaps?)
      videoSelect: inputVideoDeviceId ? { value: inputVideoDeviceId } : undefined,
      audioSelect: inputAudioDeviceId ? { value: inputAudioDeviceId } : undefined,
      width: width,
      height: height,
      // videoBandwidth: videoBw ?? videoBandwidthDefault, // 2000,
      videoElement: videoPreviewRef.current,
      onInputsStarted: () => {
        console.log('ProjectBroadcastView - onInputsStarted')
      },
      onInputsStopped: () => {
        console.log('ProjectBroadcastView - onInputsStopped')
        if (broadcastError) setBroadcastError(undefined) // TESTING: reset errors whenever the inputs are stopped/restarted
      },
      onInputsRestarting: () => {
        console.log('ProjectBroadcastView - onInputsRestarting')
      },
      onInputsRestarted: () => {
        console.log('ProjectBroadcastView - onInputsRestarted')
      },
      onPublisherCreated: (settings: any) => {
        console.log('ProjectBroadcastView - startInputs - onPublisherCreated - settings:', settings) // Ready to WebRTC publishing -
      },
      onConnectionStateChange: (connectionState: WebRTCBroadcasterConnectionState) => {
        console.log('ProjectBroadcastView - startInputs - onConnectionStateChange - connectionState:', connectionState)
        switch (connectionState) {
          case 'new':
          // case 'checking': // TODO: the following references this but the original lib.dom.d.ts `RTCPeerConnectionState` declaration doesn't? - ref: https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/connectionstatechange_event
            // setOnlineStatus("Connecting…");
            break
          case 'connected':
            // setOnlineStatus("Online");
            setIsBroadcasting(true)
            setIsStarting(false)
            onUpdatePrograms()
            break
          case 'disconnected':
            // setOnlineStatus("Disconnecting…");
            setIsBroadcasting(false)
            onUpdatePrograms()
            break
          case 'closed':
            // setOnlineStatus("Offline");
            break
          case 'failed':
            // setOnlineStatus("Error");
            setBroadcastError(new Error('Connection Failed')) // TODO: can we get/find an actual error? and/or will `onConnectionError` always fire as well, if so rely on that?
            setIsStarting(false)
            onUpdatePrograms()
            break
          default:
            // setOnlineStatus("Unknown");
            break
        }
      },
      onIceconnectionStateChange: (connectionIceState: any) => {
        console.log('ProjectBroadcastView - startInputs - onIceconnectionStateChange - connectionIceState:', connectionIceState) // Connection ice state change -
      },
      onOffer: (offer: any) => {
        console.log('ProjectBroadcastView - startInputs - onOffer - SDP offer:', offer)
      },
      onAnswer: (answer: any) => {
        console.log('ProjectBroadcastView - startInputs - onAnswer - SDP answer:', answer)
      },
      onConnectionError: (error?: any) => {
        console.log('ProjectBroadcastView - startInputs - onConnectionError - Connection error - error:', error)
        setIsStarting(false)
        setIsBroadcasting(false) // TOOD: is this correct, we're disconnected if it throws a connection error?
        if (error instanceof Error) {
          setBroadcastError(error)
        } else if (typeof error === 'string') {
          setBroadcastError(new Error(error))
        } else {
          setBroadcastError(new Error('Connection Error')) // generic fallback error
        }
      }
    }
    if (inputAudioDeviceId) {
      // TODO:
      // TODO: the broadcaster doesn't support audio selection yet, only using the default? (which seems to trigger an error on startup with current usage/code?)
      // TODO:
    }

    try {
      broadcasterRef.current = new WebRTCBroadcaster(config)
      await broadcasterRef.current.startInputs()
    } catch (error: any) {
      console.error('ProjectBroadcastView - startInputs - error: ', error)
      // setIsStarting(false)
      setBroadcastError(error) // TODO: will this only fire when the `onConnectionError` doesn't? (if both can fire, which should take priority, or would they both have the same error?)
    }
  }

  // NB: expects the video & audio inputs to have already been set/selected (via the inital call to `startInputs` & any subsequent changes applied via the form UI fields)
  const startBroadcast = async (
    webrtcUrl: string,
    // videoRes?: ProjectBroadcastVideoRes,
    videoBw?: number
  ) => {
    console.log('ProjectBroadcastView - startBroadcast')
    if (!broadcasterRef.current) {
      console.log('ProjectBroadcastView - startBroadcast - ERROR: broadcasterRef.current == undefined <<<<<')
      // TODO: throw error?
      return
    }
    // TODO: make sure inputs have been started <<<<<
    try {
      setIsStarting(true)
      if (broadcastError) setBroadcastError(undefined)

      broadcasterRef.current.setWhipUrl(webrtcUrl)
      broadcasterRef.current.setVideoBandwidth(videoBw ?? videoBandwidthDefault)
      // const width = videoRes?.width ?? videoResolutionDefault.width
      // const height = videoRes?.height ?? videoResolutionDefault.height
      // await broadcasterRef.current.setVideoSize(width, height)

      const didStart = await broadcasterRef.current.publish() // TODO: doesn't always currently trigger a callback for certain errors on startup yet, so temp added a basic bool response (will be undefined if it didn't start, true if it did in theory)
      console.log('ProjectBroadcastView - startBroadcast - publish - didStart:', didStart)
      // if (didStart === true) {
      //   setIsBroadcasting(true)
      // } else {
      //   setIsBroadcasting(false)
      // }
    } catch (error: any) {
      console.error('ProjectBroadcastView - startBroadcast - error: ', error)
      setIsStarting(false)
      setBroadcastError(error) // TODO: will this only fire when the `onConnectionError` doesn't? (if both can fire, which should take priority, or would they both have the same error?)
    }
  }

  const stopBroadcast = async () => {
    console.log('ProjectBroadcastView - stopBroadcast - isBroadcasting:', isBroadcasting)
    if (isBroadcasting || isStarting || broadcasterRef.current !== undefined) {
      await broadcasterRef.current?.stop()
      // NB: don't clear the ref here anymore, now only cleared on unmount
    }
  }

  // NB: currently the form submit toggles between 'start broadcasting' & 'stop broadcasting' states/handling
  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    console.log('ProjectBroadcastView - onFormSubmit - fieldValues: ', fieldValues)
    // const {} = fieldValues

    // always allow 'stop broadcasting' while live
    if (isBroadcasting) {
      stopBroadcast()
      return
    }

    // const program = fieldValues.program && programs ? programs?.find((p) => p.id === fieldValues.program) : undefined
    // console.log('ProjectBroadcastView - onFormSubmit - program: ', program)

    // // TOOD: halt if required fields aren't set
    // if (!fieldValues.program || !program || !program.inputAuth || !fieldValues.videoDevice) {
    //   // TODO: show an error of some sort? (or should the form already handle that & this is just an emergency fallback?)
    //   return
    // }
    if (!program || !program.inputAuth || !videoDevice) {
      // TODO: show an error of some sort? (or should the form already handle that & this is just an emergency fallback?)
      return
    }

    // construct the webrtc url
    const serverUrl = program.serverURL
    const serverPort = 443 // TODO: get this from the program, or if its not supplied by that currently, via some other server supplied call??
    const videoCodec = 'h264'
    const authUser = program.inputAuth?.user ?? ''
    const authPass = program.inputAuth?.pass ?? ''
    const webrtcUrl = `https://${serverUrl}:${serverPort}/${companyId}_${projectId}/${program.id}?whipauth=${authUser}:${authPass}&videocodecs=${videoCodec}`
    console.log('ProjectBroadcastView - onFormSubmit - webrtcUrl: ', webrtcUrl)

    // start the webrtc broadcast
    // NB: passing in (some) pre-updated state vars instead of the fieldValues directly passed in here (see `onFormValueChanged` for state var updates)
    startBroadcast(webrtcUrl, videoBandwidth) // videoEnabled ? videoDevice : undefined, audioEnabled ? audioDevice : undefined, videoResolution
  }

  const onFormValueChanged = async (fieldKey: string, fieldValue: any, _oldFieldValue: any) => {
    console.log('ProjectBroadcastView - onFormValueChanged - fieldKey:', fieldKey, ' fieldValue:', fieldValue, ' typeof: ', typeof fieldValue)
    if (fieldKey === 'program' && (typeof fieldValue === 'number' || fieldValue === undefined)) {
      const _program = fieldValue && programs ? programs?.find((p) => p.id === fieldValue) : undefined
      setProgram(_program)
    } else if (fieldKey === 'videoSourceEnabled' && typeof fieldValue === 'boolean') {
      setVideoEnabled(fieldValue)
    } else if (fieldKey === 'audioSourceEnabled' && typeof fieldValue === 'boolean') {
      setAudioEnabled(fieldValue)
    } else if (fieldKey === 'videoDevice' && typeof fieldValue === 'string') {
      setVideoDevice(fieldValue)
    } else if (fieldKey === 'audioDevice' && typeof fieldValue === 'string') {
      setAudioDevice(fieldValue)
    } else if (fieldKey === 'videoResolution' && typeof fieldValue === 'string') {
      const _videoResolution = videoResolutions.find((videoRes) => videoRes.key === fieldValue)
      setVideoResolution(_videoResolution)
    } else if (fieldKey === 'videoBandwidth' && typeof fieldValue === 'string') {
      const videoBwInt = parseInt(fieldValue)
      console.log('ProjectBroadcastView - onFormValueChanged - videoBwInt:', videoBwInt)
      !isNaN(videoBwInt) ? setVideoBandwidth(videoBwInt) : setVideoBandwidth(undefined)
    } else if (fieldKey === 'flipVideoPreview' && typeof fieldValue === 'boolean') {
      setFlipVideoPreview(fieldValue)
    }
  }

  const onOpenBrowserTab = () => {
    console.log('ProjectBroadcastView - onOpenBrowserTab')
    // open a new tab/window to the project manager
    const path = navContext.actions.getSectionProjectPath(NavSection.project, projectId)
    window.open(path, '_blank')
  }

  // -------

  useEffect(() => {
    // mount
    console.log('ProjectBroadcastView - mount')
    // unmount
    return () => {
      console.log('ProjectBroadcastView - unmount')
      async function unloadAsync () {
        if (isBroadcasting || isStarting) {
          await stopBroadcast()
        }
        if (broadcasterRef.current) {
          await broadcasterRef.current.stopInputs()
          broadcasterRef.current = undefined
        }
        console.log('ProjectBroadcastView - unmount (END)')
      }
      unloadAsync()
    }
  }, [])

  // -------

  // load the project programs on init or company or project change
  useEffect(() => {
    async function loadAsync () {
      await loadData()
    }
    if (!loadingPrograms) {
      loadAsync()
    }
  }, [companyId, projectId])

  useEffect(() => {
    updateCanStart()
  }, [program, videoDevice])

  // load the available inputs & start the inputs for local preview once the user confirms they want to start/enable this mode (NB: this WON'T start broadcasting, but will access the media devices & so prompt the user for permission if its the first time)
  useEffect(() => {
    console.log('ProjectBroadcastView - useEffect - isEnabled - isEnabled:', isEnabled, ' mediaDevicesLoaded:', mediaDevicesLoaded)
    async function loadAsync () {
      // await loadMediaDevices()

      if (isEnabled === true && !broadcasterRef.current && mediaDevicesLoaded) {
        console.log('ProjectBroadcastView - useEffect - isEnabled - program:', program, ' videoDevice:', videoDevice)
        if (!videoDevice) { // !program || !program.inputAuth ||
          console.log('ProjectBroadcastView - useEffect - isEnabled - WARNING: !videoDevice <<<<')
          // TODO: show an error of some sort? (or should the form already handle that & this is just an emergency fallback?)
          return
        }
        // construct the webrtc url if we have the required fields
        let webrtcUrl: string | undefined
        if (program) {
          const serverUrl = program.serverURL
          const serverPort = 2443 // TODO: get this from the program, or if its not supplied by that currently, via some other server supplied call??
          const videoCodec = 'h264'
          const authUser = program.inputAuth?.user ?? ''
          const authPass = program.inputAuth?.pass ?? ''
          webrtcUrl = `https://${serverUrl}:${serverPort}/${companyId}_${projectId}/${program.id}?whipauth=${authUser}:${authPass}&videocodecs=${videoCodec}`
          console.log('ProjectBroadcastView - onFormSubmit - webrtcUrl: ', webrtcUrl)
        }
        await startInputs(videoEnabled ? videoDevice : undefined, audioEnabled ? audioDevice : undefined) // webrtcUrl, videoResolution, videoBandwidth
      } else {
        console.log('ProjectBroadcastView - useEffect - isEnabled - ALREADY STARTED (?) OR NOT READY (?)')
      }
    }
    if (isEnabled && !mediaDevicesLoaded) loadMediaDevices()
    if (isEnabled && mediaDevicesLoaded) loadAsync()
  }, [isEnabled, mediaDevicesLoaded])

  useEffect(() => {
    console.log('ProjectBroadcastView - useEffect - videoEnabled/videoDevice...')
    if (!broadcasterRef.current) {
      console.log('ProjectBroadcastView - useEffect - videoEnabled/videoDevice - ERROR: !broadcasterRef.current')
      return
    }
    const inputVideoDeviceId: string | undefined = videoEnabled ? videoDevice : undefined
    console.log('ProjectBroadcastView - useEffect - videoEnabled/videoDevice - inputVideoDeviceId:', inputVideoDeviceId)
    broadcasterRef.current.setVideoInput(inputVideoDeviceId) // NB: flip to use `await` if we need to run anything else related directly after this call!
  }, [videoEnabled, videoDevice])

  useEffect(() => {
    console.log('ProjectBroadcastView - useEffect - audioEnabled/audioDevice...')
    if (!broadcasterRef.current) {
      console.log('ProjectBroadcastView - useEffect - audioEnabled/audioDevice - ERROR: !broadcasterRef.current')
      return
    }
    const inputAudioDeviceId: string | undefined = audioEnabled ? audioDevice : undefined
    console.log('ProjectBroadcastView - useEffect - audioEnabled/audioDevice - inputAudioDeviceId:', inputAudioDeviceId)
    broadcasterRef.current.setAudioInput(inputAudioDeviceId) // NB: flip to use `await` if we need to run anything else related directly after this call!
    // audioRequired: inputAudioDeviceId !== undefined, // TODO: add audio support (currently always triggers a permission/system error? maybe related to localhost usage or something dev specific perhaps?)
    // audioSelect: inputAudioDeviceId ? { value: inputAudioDeviceId } : undefined,
  }, [audioEnabled, audioDevice])

  useEffect(() => {
    console.log('ProjectBroadcastView - useEffect - videoResolution:', videoResolution)
    if (!broadcasterRef.current) {
      console.log('ProjectBroadcastView - useEffect - videoEnabled/videoDevice - ERROR: !broadcasterRef.current')
      return
    }
    const width = videoResolution?.width ?? videoResolutionDefault.width
    const height = videoResolution?.height ?? videoResolutionDefault.height
    console.log('ProjectBroadcastView - useEffect - width:', width, ' height:', height)
    // TODO: only apply if its changed/different?
    broadcasterRef.current.setVideoSize(width, height) // NB: flip to use `await` if we need to run anything else related directly after this call!
  }, [videoResolution])

  // -------

  const canEditForm = !isStarting && !isBroadcasting

  const formFields: Array<ArkFormField> = []

  const programOptions: Array<ArkFormFieldOption> = []
  programOptions.push({ key: 'program_none', text: 'Select a program', value: undefined })
  if (programs) {
    for (const program of programs) {
      programOptions.push({
        key: 'program_' + program.id,
        // text: program.name,
        value: program.id,
        children: (
          <div className='text'>{program.name} {program.isOnline ? <span className={styles.progOnline}>ONLINE (not available)</span> : <span className={styles.progOffline}>OFFLINE (available)</span>}</div>
        ),
        disabled: program.isOnline
      })
    }
  }

  const videoDeviceOptions: Array<ArkFormFieldOption> = []
  // videoDeviceOptions.push({ key: 'video_none', text: 'Select a video input', value: undefined })
  if (videoInputDevices) {
    for (const videoInputDevice of videoInputDevices) {
      videoDeviceOptions.push({ key: videoInputDevice.deviceId, text: videoInputDevice.label, value: videoInputDevice.deviceId })
    }
  }

  const audioDeviceOptions: Array<ArkFormFieldOption> = []
  // audioDeviceOptions.push({ key: 'audio_none', text: 'Select an audio input', value: undefined })
  if (audioInputDevices) {
    for (const audioInputDevice of audioInputDevices) {
      audioDeviceOptions.push({ key: audioInputDevice.deviceId, text: audioInputDevice.label, value: audioInputDevice.deviceId })
    }
  }

  const videoResolutionOptions: Array<ArkFormFieldOption> = []
  for (const videoRes of videoResolutions) {
    videoResolutionOptions.push({ key: `videoRes_${videoRes.key}`, text: videoRes.key, value: videoRes.key })
  }
  const videoResolutionKey: string | undefined = videoResolution?.key

  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'outputGroup',
    fields: [
      {
        type: ArkFormFieldType.Fieldset,
        key: 'outputFieldset',
        label: 'Output',
        fields: [
          {
            type: ArkFormFieldType.Group,
            key: 'programGroup',
            fields: [
              {
                type: ArkFormFieldType.Dropdown,
                key: 'program',
                label: 'Program',
                required: true,
                defaultValue: program?.id,
                options: programOptions,
                fieldProps: {
                  loading: loadingPrograms,
                  scrolling: true // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
                },
                disabled: !canEditForm
              },
              // { type: ArkFormFieldType.Button, key: 'programRefresh', label: 'UPDATE', fieldProps: { onClick: onUpdatePrograms, style: { marginTop: 24, marginLeft: 10 } } },
              {
                type: ArkFormFieldType.Field,
                key: 'programRefreshField',
                className: styles.programsRefreshField,
                content: (
                  <ArkIconButton
                    name='restart'
                    onClick={() => onUpdatePrograms()}
                    size={24}
                  />
                )
              }
            ],
            fieldProps: { widths: 'equal', style: { justifyContent: 'space-between', gap: '0px' } }
          }
        ],
        collapsible: false,
        collapsed: false,
        // fieldProps: { style: { marginBottom: '10px' /*, minWidth: '500px' */ } }
        fieldProps: { style: { flexGrow: 1, flexBasis: '67%', minWidth: '200px', marginBottom: '5px' } }
      },
      {
        type: ArkFormFieldType.Fieldset,
        key: 'controlsFieldset',
        // label: 'Output',
        className: styles.controlsFieldset,
        fields: [
          {
            type: ArkFormFieldType.Group,
            key: 'buttons',
            className: styles.controlsGroup,
            fields: [
              {
                type: ArkFormFieldType.Button,
                key: 'submit',
                label: (!isBroadcasting ? 'START BROADCASTING' : 'STOP BROADCASTING'),
                fieldProps: {
                  basic: true,
                  color: (!isBroadcasting ? 'green' : 'red'),
                  loading: isStarting
                },
                disabled: !canStart
              }
            ]
            // fieldProps: { widths: 'equal', inline: false }
          }
        ],
        collapsible: false,
        collapsed: false,
        // fieldProps: { style: { marginBottom: '10px' /*, minWidth: '500px' */ } }
        fieldProps: { style: { flexGrow: 1, flexBasis: '33%', minWidth: '200px', marginBottom: '5px' } }
      }
    ],
    // fieldProps: { style: { flexGrow: 1, flexBasis: '50%', minWidth: '200px' } }
    fieldProps: { widths: 'equal', style: { justifyContent: 'space-between', gap: '10px', marginTop: '10px', marginBottom: '0px' } }
  })

  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'sourcesGroup',
    fields: [
      {
        type: ArkFormFieldType.Fieldset,
        key: 'videoSourceFieldset',
        label: 'Video Source',
        fields: [
          {
            type: ArkFormFieldType.Group,
            key: 'videoSourceOptionsGroup',
            fields: [
              {
                type: ArkFormFieldType.Checkbox,
                key: 'videoSourceEnabled',
                label: 'Enable Video',
                required: false,
                defaultValue: videoEnabled,
                disabled: !canEditForm
              }
            ],
            fieldProps: { widths: 'equal' }
          },
          {
            type: ArkFormFieldType.Dropdown,
            key: 'videoDevice',
            label: 'Video Device',
            required: videoEnabled,
            value: videoDevice, // NB: using `value` & not `defaultValue` so we can auto assign a value after form init once the video devices data has loaded
            options: videoDeviceOptions,
            disabled: !videoEnabled || !canEditForm,
            fieldProps: { scrolling: true } // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
          },
          {
            type: ArkFormFieldType.Group,
            key: 'videoOptionsGroup',
            fields: [
              {
                type: ArkFormFieldType.Dropdown,
                key: 'videoResolution',
                label: 'Video Resolution',
                required: videoEnabled,
                defaultValue: videoResolutionKey,
                options: videoResolutionOptions,
                disabled: !videoEnabled || !canEditForm,
                fieldProps: { scrolling: true } // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
              },
              { type: ArkFormFieldType.Input, key: 'videoBandwidth', label: 'Video Bandwidth (kbps)', defaultValue: '2000', required: videoEnabled, disabled: !videoEnabled || !canEditForm, fieldProps: { /* disabled: true */ } }
            ],
            fieldProps: { widths: 'equal', style: { marginTop: '10px' } }
          }
        ],
        fieldProps: { style: { flexGrow: 1, flexBasis: '33%', minWidth: '200px' } }
      },
      {
        type: ArkFormFieldType.Fieldset,
        key: 'audioSourceFieldset',
        label: 'Audio Source',
        fields: [
          {
            type: ArkFormFieldType.Group,
            key: 'audioSourceOptionsGroup',
            fields: [
              {
                type: ArkFormFieldType.Checkbox,
                key: 'audioSourceEnabled',
                label: 'Enable Audio',
                required: false,
                defaultValue: audioEnabled,
                disabled: !canEditForm
              }
            ],
            fieldProps: { widths: 'equal' }
          },
          {
            type: ArkFormFieldType.Dropdown,
            key: 'audioDevice',
            label: 'Audio Device',
            required: false,
            value: audioDevice, // NB: using `value` & not `defaultValue` so we can auto assign a value after form init once the audio devices data has loaded
            options: audioDeviceOptions,
            disabled: !audioEnabled || !canEditForm,
            fieldProps: { scrolling: true } // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
          }
        ],
        fieldProps: { style: { flexGrow: 1, flexBasis: '33%', minWidth: '200px' } }
      },
      {
        type: ArkFormFieldType.Fieldset,
        key: 'videoPreviewFieldset',
        label: 'Preview',
        fields: [
          {
            type: ArkFormFieldType.Field,
            key: 'videoPreview',
            content: (
              <video className={flipVideoPreview ? styles.flipVideo : ''} ref={videoPreviewRef} autoPlay muted playsInline></video>
            )
          },
          {
            type: ArkFormFieldType.Checkbox,
            key: 'flipVideoPreview',
            label: 'Flip Video Preview',
            required: false
            // defaultValue: hotlinkEnabled, // TODO: <<
          }
        ],
        fieldProps: { style: { flexGrow: 1, flexBasis: '33%', minWidth: '200px' } }
      }
    ],
    fieldProps: { widths: 'equal', style: { justifyContent: 'space-between', gap: '10px', marginTop: '0px', marginBottom: '0px' } }
  })

  if (isBroadcasting || isStarting) {
    formFields.push({
      type: ArkFormFieldType.Group,
      key: 'noticeGroup',
      fields: [
        {
          type: ArkFormFieldType.Fieldset,
          key: 'noticeFieldset',
          // label: '',
          className: styles.tabWarningFieldset,
          fields: [
            {
              type: ArkFormFieldType.Field,
              key: 'closeTabNotice',
              content: (
                <div className={styles.tabWarningNotice}>
                  <div className={styles.warningIcon}><ArkIcon name='warning' size={36} color={'#f3be0e'} /></div>
                  <div className={styles.warningTxt}>
                    <p>Closing this tab will stop the broadcast - leave it open to keep the broadcast live</p>
                    <p>To continue broadcasting and using RePro Project manager in a new tab - click the button to the right...</p>
                  </div>
                  <ArkButton className={styles.warningBtn} type="button" color="blue" basic size="large" fluid disabled={false} onClick={onOpenBrowserTab}>
                    Continue in a new Browser Tab
                  </ArkButton>
                </div>
              )
            }
          ],
          fieldProps: { style: { flexGrow: 1, flexBasis: '33%', minWidth: '200px', marginTop: '0px', marginBottom: '0px' } }
        }
      ],
      fieldProps: { widths: 'equal', style: { justifyContent: 'space-between', gap: '10px', marginTop: '0px', marginBottom: '0px' } }
    })
  }

  // -------

  return (
    <div className={styles.broadcastView}>
      {/* <div><button onClick={onStart}>START</button></div> */}
      <ArkForm
        formKey="broadcast"
        inverted
        formError={loadingError || broadcastError}
        formFields={formFields}
        formSchema={formSchema}
        onFormSubmit={onFormSubmit}
        onValueChanged={onFormValueChanged}
        showLabels={true}
        insideModal={false}
        updateFieldValuesOnExternalChange={true}
      >
      </ArkForm>
      {!isEnabled && (
        <div className={styles.broadcastEnableOverlay}>
          <div className={styles.broadcastEnableMask}></div>
          <div className={styles.broadcastEnableContent}>
            <h2>Enable Broadcast Mode?</h2>
            <p>This will prompt you for permission to access your camera & mic the first time.</p>
            <ArkButton fluid color="blue" onClick={() => {
              setIsEnabled(true)
            }}>ENABLE</ArkButton>
          </div>
        </div>
      )}
    </div>
  )
}

export { ProjectBroadcastView }
