import React from 'react'

import { withProjectAdminContext, IProjectAdminMultiContext } from 'src/core/providers'

import { Group } from 'src/core/models'

import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { OBJECT_GROUP_NAME } from 'src/constants/strings'

export type ProjectGroupDeleteCallback = (group: Group) => void

interface IProps extends IProjectAdminMultiContext {
  companyId: number
  projectId: number
  group: Group
  onDidDelete?: ProjectGroupDeleteCallback
}
interface IState {}

class GroupDeleteView extends React.Component<IProps & { className?: string }, IState> {
  render () {
    const { group, className } = this.props
    if (!group) return (<></>)
    return (
      <div className={(className ? ' ' + className : '')}>
        <ArkSpacer />
        <ArkManagerDeleteButton
          itemId={group.id}
          itemName={group.name}
          itemTypeName={OBJECT_GROUP_NAME}
          buttonTitle={'DELETE ' + OBJECT_GROUP_NAME}
          onDelete={this.onDelete}
          onDeleteComplete={() => { if (this.props.onDidDelete) this.props.onDidDelete(group) }}
          onDeleteAllowed={() => { return !group.isDefaultGroup }}
          notAllowedTitle={'Default group warning'}
          notAllowedMsg={'The default group cannot be deleted'}
        />
      </div>
    )
  }

  onDelete = async (_itemId: number): Promise<boolean> => {
    // NB: DON'T catch the error/exception here, let it throw (or if you do, rethrow it so the calling code can catch & display it)
    const { companyId, projectId, group } = this.props
    const deleteResult = await this.props.projectAdminContext.actions.deleteProjectGroup(companyId, projectId, group.id)
    console.log('GroupDeleteView - onDelete - deleteProjectGroup - deleteResult: ', deleteResult)
    return deleteResult
  }
}

export default withProjectAdminContext(GroupDeleteView)
