import { BaseModel } from './base_model'
import { ActionPermissions } from './'

export enum GroupType {
  std = 0,
  org = 1
}

// NB: these args are named from the api & used with api calls, so keeping them in this format instead of camelCase (could otherwise map them if we want to keep camelCase in our code)
// eslint-disable-next-line camelcase
export type GroupChannelOperation = { operation: number, group_id: number, channel_id: number }

export class Group extends BaseModel {
  id: number
  name: string

  projectId: number
  isDefaultGroup: boolean
  isOrganisationGroup: boolean
  parentGroupId?: number

  desc?: string

  allowPermissions: boolean
  actionPermissions?: ActionPermissions

  usersCount: number
  channelsCount: number
  programsCount: number

  subGroups?: Array<Group> // TODO: DEPRECIATE? no longer adding models inside others directly (so it matches the api responses closer)

  constructor (
    id: number,
    name: string,
    projectId: number,
    isDefaultGroup: boolean,
    isOrganisationGroup: boolean,
    parentGroupId?: number,
    desc?: string,
    allowPermissions: boolean = false,
    actionPermissions?: ActionPermissions,
    usersCount: number = 0,
    channelsCount: number = 0,
    programsCount: number = 0
  ) {
    super()
    this.id = id
    this.name = name
    this.projectId = projectId
    this.isDefaultGroup = isDefaultGroup
    this.isOrganisationGroup = isOrganisationGroup
    this.parentGroupId = parentGroupId
    this.desc = desc
    this.allowPermissions = allowPermissions
    this.actionPermissions = actionPermissions
    this.usersCount = usersCount
    this.channelsCount = channelsCount
    this.programsCount = programsCount
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (id: number, json: any) : Group | null {
    if (!json) return null
    const actionPermissions = ActionPermissions.fromJSON(json) ?? undefined
    return new Group(
      id,
      json.name,
      json.project_id,
      json.flag_default ?? false,
      json.flag_organization_group,
      json.parent_group_id,
      json.description,
      json.allow_permissions,
      actionPermissions,
      json.users_count,
      json.channels_count,
      json.programs_count
    )
  }

  // TODO: DEPRECIATE?
  // TODO: only supports 1 level down, make this support querying for multi-levels down (maybe require passing in the ids of each parent instead of searching across the whole tree?)
  getSubGroup = (groupId: number) : Group | null => {
    if (this.subGroups && this.subGroups.length > 0) {
      for (const group of this.subGroups) {
        if (group.id === groupId) {
          return group
        }
      }
    }
    return null
  }
}
