import React, { useContext, useEffect, useRef } from 'react'

import { ProjectAdminContext } from 'src/core/providers'

import { ProjectUser } from 'src/core/models'

import ArkCheckbox, { ArkCheckboxProps } from 'src/core/components/ArkCheckbox'
import ArkLoader from 'src/core/components/ArkLoader'
import ArkMessage from 'src/core/components/ArkMessage'

import styles from './ProjectUserAccessToggle.module.css'

export type ProjectUserAccessToggleOnChangeCallback = (value: boolean) => void

interface IProps {
  companyId: number
  projectId: number
  user: ProjectUser
  showStatusText?: boolean
  onChange?: ProjectUserAccessToggleOnChangeCallback
}

const ProjectUserAccessToggle = (props: IProps) => {
  const mounted = useRef(false)

  const { companyId, projectId, user, showStatusText, onChange: _onChange } = props

  const { actions: projectAdminActions } = useContext(ProjectAdminContext)

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [submitValue, setSubmitValue] = React.useState<boolean>(false)
  const [, setHasSaved] = React.useState<boolean>(false) // hasSaved // TODO: do we need to show a success message?
  const [error, setError] = React.useState<Error | undefined>(undefined)

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const reset = () => {
    setIsSubmitting(false)
    setSubmitValue(false)
    setHasSaved(false)
    setError(undefined)
  }

  // -------

  // TESTING: equivalent to the componentDidUpdate method `if (this.props.user?.id !== prevProps.user?.id) { this.reset() }`
  useEffect(() => {
    if (user?.id) {
      reset()
    }
  }, [user?.id])

  // -------

  const updateProjectUserAccessEnabled = async (companyId: number, projectId: number, userId: number, accessEnabled: boolean) => {
    try {
      setIsSubmitting(true)
      setSubmitValue(accessEnabled)
      setError(undefined)
      await new Promise(resolve => setTimeout(resolve, 500)) // add a brief delay, so the user see's the loading indicator on quick api calls (rather than showing a success message, this makes it kind of obvious it did something & so worked)
      // throw new Error('TEST ERROR') // DEBUG ONLY - force an error instead of calling the api to make styling it easier
      const result = await projectAdminActions.updateUserProjectAccessEnabled(companyId, projectId, userId, accessEnabled)
      if (result && _onChange) _onChange(accessEnabled) // NB: its up to the calling code to update the local users projectAccessEnabled value (or reload it from the api)
      if (mounted.current) {
        setIsSubmitting(false)
        setHasSaved(result)
      }
    } catch (error) {
      console.error('ProjectUsersPage - updateProjectUserAccessEnabled - error: ', error)
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
      // TODO: need to also revert the checkbox toggle status to reflect it failed <<<<
    }
  }

  // -------

  const accessEnabled = isSubmitting ? submitValue : user.projectAccessEnabled // use the new value while submitting (& on success the user will be updated to use the new value)
  const _showStatusText = showStatusText !== undefined ? showStatusText : false
  return (
    <>
      <div className={styles.accessToggle}>
        {!isSubmitting && (
          <ArkCheckbox
            toggle
            // defaultChecked={user.projectAccessEnabled}
            checked={accessEnabled}
            disabled={isSubmitting}
            onChange={async (event: React.FormEvent<HTMLInputElement>, data: ArkCheckboxProps) => {
              event.preventDefault()
              event.stopPropagation()
              const newAccessEnabled = data.checked ?? false
              if (newAccessEnabled !== user.projectAccessEnabled) {
                await updateProjectUserAccessEnabled(companyId, projectId, user.id, newAccessEnabled)
              }
            }}
          />
        )}
        {isSubmitting && <ArkLoader small />}
        {!isSubmitting && _showStatusText && (<span className={styles.status}>&nbsp;{accessEnabled ? '(ENABLED)' : '(DISABLED)'}</span>)}
      </div>
      {error && (
        <div>
          <ArkMessage size='small' compact error className={styles.error}>
            <ArkMessage.Header>Update Error</ArkMessage.Header>
            <p>{error.message}</p>
          </ArkMessage>
        </div>
      )}
    </>
  )
}

export default ProjectUserAccessToggle
