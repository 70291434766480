import React from 'react'

import { withProjectAdminContext, IProjectAdminMultiContext } from 'src/core/providers'

import { Group, User, GroupUserOperation } from 'src/core/models'

import ArkAvatar from 'src/core/components/ArkAvatar'
import ArkButton from 'src/core/components/ArkButton'
import ArkDataMappingForm, { ArkDataMappingItemChanges, ArkManagerFilteredItem } from 'src/core/components/ArkDataMappingForm/ArkDataMappingForm'
import ArkHighlightedText from 'src/core/components/ArkHighlightedText'
import ArkManagerFilterForm from 'src/core/components/ArkManagerListView/ArkManagerFilterForm'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { OBJECT_GROUP_NAME, OBJECT_PROJECT_NAME, OBJECT_USER_NAME_PLURAL } from 'src/constants/strings'

import styles from './ProjectGroupUsersPanel.module.css'
import dataMappingFormStyles from 'src/core/components/ArkDataMappingForm/ArkDataMappingForm.module.css'

interface IProps extends IProjectAdminMultiContext {
  companyId: number
  projectId: number
  group: Group
  onChange?: Function
}
interface IState {

  loading: boolean
  groupUsers: Array<User>
  projectUsers: Array<User>

  filteredProjectUsers?: Array<ArkManagerFilteredItem<User>>
  filter?: string

  selectedIds: Array<number>

  savedIds?: Array<number> // had an opertaion save/submit ok (e.g. add/del)
  saveErrors?: Map<number, any>

  showForm: boolean
  isSubmitting: boolean
  hasUpdated: boolean
  error?: Error
}

class ProjectGroupUsersPanel extends React.Component<IProps, IState> {
  _isMounted: boolean = false

  constructor (props: IProps) {
    super(props)
    this.state = {
      loading: false,
      groupUsers: [], // only users mapped to this group
      projectUsers: [], // all users within the project
      filteredProjectUsers: undefined,
      filter: undefined,
      selectedIds: [],
      showForm: false,
      isSubmitting: false,
      hasUpdated: false
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  componentDidUpdate (prevProps: IProps) {
    if (this.props.group.id !== prevProps.group.id) {
      this.resetView()
    }
  }

  render () {
    const { companyId, projectId, group } = this.props
    const { loading, showForm, isSubmitting, hasUpdated, error, projectUsers, selectedIds, filteredProjectUsers, filter } = this.state
    if (!group) return (<></>)
    return (
      <ArkPanel bordered={showForm} title={showForm ? OBJECT_GROUP_NAME + ' ' + OBJECT_USER_NAME_PLURAL : null}>

        {!showForm && (
          <ArkButton type="button" fluid size="large" loading={loading} onClick={this.onShowForm}>EDIT {OBJECT_GROUP_NAME} {OBJECT_USER_NAME_PLURAL}</ArkButton>
        )}

        {showForm && (
          <>
            <ArkDataMappingForm
              id="group-users"
              // title="Group Users"
              sourceItems={projectUsers /* .map(projectUser => { return { id: projectUser.id, title: projectUser.name() } }) */}
              mappedIds={selectedIds}
              isLoading={loading}
              isSaving={isSubmitting}
              successMessage={hasUpdated && 'The ' + OBJECT_GROUP_NAME + ' ' + OBJECT_USER_NAME_PLURAL + ' have been updated.' }{/* TODO: add details of how many where added/updated/removed from the chhanel? */...[]}
              errorMessage={error && error.message}
              disabled={group.isDefaultGroup}
              disabledMessage={'You cannot disable ' + OBJECT_USER_NAME_PLURAL + ' in the ' + OBJECT_PROJECT_NAME + ' default ' + OBJECT_GROUP_NAME + '.'}
              onCancel={() => { this.resetView() }}
              onSave={(mappedIds?: Array<number>, changes?: ArkDataMappingItemChanges) => {
                console.log('ProjectGroupUsersPanel - ArkDataMappingForm - onSave - mappedIds: ', mappedIds, ' changes: ', changes)
                if (mappedIds && changes) {
                  this.saveChanges(companyId, projectId, group, mappedIds, changes)
                }
              }}
              // filtering & custom item rendering support:
              filterForm={this.renderProjectUserFilterForm()}
              filterText={filter}
              filteredSourceItems={filteredProjectUsers}
              itemCompare={(newItem: User, oldItem: User) => {
                return newItem.id === oldItem.id && newItem.name() === oldItem.name()
              }}
              itemRow={(user: User, _isMapped: boolean) => {
                return (
                  <div className={styles.userRow}>
                    <ArkAvatar
                      type={user.userAvatarType()}
                      name={user.name()}
                      size='30'
                      filter={filter}
                      filterHighlight={true}
                    />
                    <ArkHighlightedText highlight={filter} text={user.name()} className={styles.text} />
                  </div>
                )
              }}
              onClearFilter={() => this.filterProjectUsers('')}
            />
            <ArkSpacer />
          </>
        )}

      </ArkPanel>
    )
  }

  // -------

  renderProjectUserFilterForm = () => {
    const { filter } = this.state
    return (
      <ArkManagerFilterForm
        autoComplete={false}
        className={dataMappingFormStyles.filterForm}
        filterTitle='Filter by name'
        filterValue={filter ?? ''}
        onFilterChange={(filter: string) => {
          this.filterProjectUsers(filter)
        }}
      />
    )
  }

  // -------

  resetView = () => {
    this.resetForm()
    this.setState({ showForm: false })
  }

  resetForm = () => {
    if (this.state.isSubmitting) return // don't allow resetting while submitting
    this.setState({
      isSubmitting: false,
      hasUpdated: false,
      error: undefined,
      savedIds: undefined,
      saveErrors: undefined
    })
  }

  // -------

  onShowForm = async () => {
    console.log('ProjectGroupUsersPanel - onShowForm')
    await this.loadUserssData() // TESTING: moved this to only trigger when the form should show (avoid making api queries till we need the data)
    this.setState({ showForm: true, error: undefined })
  }

  onHideForm = () => {
    console.log('ProjectGroupUsersPanel - onHideForm')
    this.setState({ showForm: false, error: undefined })
  }

  // -------

  saveChanges = async (companyId: number, projectId: number, group: Group, selectedIds: Array<number>, selectedIdChanges: ArkDataMappingItemChanges) => {
    this.setState({
      isSubmitting: true,
      hasUpdated: false,
      error: undefined,
      savedIds: undefined,
      saveErrors: undefined
    })

    // convert the list off adds & dels to an operations list in the format the api expects
    const operations = this.generateGroupUserUpdateOperations(group.id, selectedIdChanges)
    console.log('ProjectGroupUsersPanel - saveChanges - operations: ', operations)

    if (operations.length === 0) {
      console.log('ProjectGroupUsersPanel - saveChanges - 0 operations - HALT')
      this.setState({ isSubmitting: false, hasUpdated: false }) // TODO: show an error/warning?
      return
    }

    let savedIds: Array<number> | undefined
    let newSelectedIds: Array<number> | undefined
    let saveErrors: Map<number, any> | undefined
    let hasUpdated = false
    let error: Error | undefined

    try {
      // TODO: WARNING: BUG > with the new provider setup - if your own user is in the changes, it also triggers a user data update
      // TODO: WARNING:       ...this seems to trigger a page (or high up component) refresh & looses our group seelction, hides the sidebar, won't see the success etc.
      // TODO: WARNING:       ...find a way to avoid the page reset! <<<<
      // run all the add & delete mapping operations
      const result = await this.props.projectAdminContext.actions.updateProjectGroupUsers(companyId, projectId, group.id, operations)
      console.log('ProjectGroupUsersPanel - saveChanges - updateProjectGroupUsers - result: ', result, ' typeof: ', typeof result, ' instanceof Map: ', result instanceof Map)

      if (result === true) { // all operations succeeded
        if (this._isMounted) {
          hasUpdated = true
          savedIds = operations.map(operation => operation.user_id)
          newSelectedIds = selectedIds
        }
      } else if (result instanceof Map) { // 1 or more operations had errors (but some may have still succeeded)
        // all operations failed
        if (result.size === operations.length) {
          hasUpdated = false
          savedIds = []
          newSelectedIds = this.state.selectedIds // TESTING: revert to the previous form selection before the submit
          console.log('ProjectGroupUsersPanel - saveChanges - updateProjectGroupUsers - ALL FAILED - newSelectedIds: ', newSelectedIds)
        } else { // only some operations failed
          hasUpdated = true // set true if some added/updated ok
          savedIds = [] // get a list of successful operations
          for (const operation of operations) {
            if (result.has(operation.user_id) === false) {
              savedIds.push(operation.user_id)
            }
          }
          newSelectedIds = selectedIds.filter((selectedId) => !result.has(selectedId)) // TODO: does this only account for adding/enabling an id, not removing one??
          console.log('ProjectGroupUsersPanel - saveChanges - updateProjectGroupUsers - SOME FAILED - newSelectedIds: ', newSelectedIds)
        }
        saveErrors = result // the result contains errors for each operation that failed
        error = { message: 'A problem occurred saving one or more of the changes, please try again.' } as any
      } else { // general/fallback error (with the whole api call)
        hasUpdated = false
        error = { message: 'A problem occurred saving the changes, please try again.' } as any
      }

      console.log('ProjectGroupUsersPanel - saveChanges - hasUpdated: ', hasUpdated, ' savedIds: ', savedIds, ' newSelectedIds: ', newSelectedIds)
      if (this._isMounted) {
        this.setState({
          isSubmitting: false,
          hasUpdated,
          savedIds,
          selectedIds: newSelectedIds ?? [],
          saveErrors,
          error
        })
      }
      if (hasUpdated) {
        if (this.props.onChange) this.props.onChange()
      }
    } catch (error) {
      if (this._isMounted) {
        this.setState({ isSubmitting: false, hasUpdated, savedIds, saveErrors, error: error as Error })
      }
    }
  }

  // -------

  loadUserssData = async () => {
    console.log('ProjectGroupUsersPanel - loadUserssData')

    await this.loadProjectUsers()
    await this.loadGroupUsers()
  }

  loadProjectUsers = async () => {
    if (this.state.loading === true) return false
    const companyId = this.props.companyId
    const projectId = this.props.projectId
    if (companyId && projectId) {
      try {
        this.setState({ loading: true })
        const users = await this.props.projectAdminContext.actions.getProjectUsers(companyId, projectId)
        this.setState({
          loading: false,
          projectUsers: users || []
        })
      } catch (error) {
        console.error('ProjectGroupUsersPanel - loadProjectUsers - error: ', error)
        this.setState({
          loading: false,
          projectUsers: []
          // TODO: add an error prop & display an error message if this happens
        })
      }
    }
  }

  loadGroupUsers = async () => {
    console.log('ProjectGroupUsersPanel - loadGroupChannels - this.state.loading: ', this.state.loading)
    if (this.state.loading === true) return false
    const companyId = this.props.companyId
    const projectId = this.props.projectId
    const groupId = this.props.group.id
    if (companyId && projectId && groupId) {
      try {
        this.setState({ loading: true })
        const users = await this.props.projectAdminContext.actions.getProjectGroupUsers(companyId, projectId, groupId)

        // TESTING: re-set the selectedIds to show the mappings saved from the api
        // NB: this will override any edits/changes to the form selection by the user, if this gets called after initial load
        const selectedIds: Array<number> = []
        if (users) {
          for (const groupUser of users) {
            selectedIds.push(groupUser.id)
          }
        }
        console.log('ProjectGroupUsersPanel - loadGroupChannels - selectedIds: ', selectedIds)

        this.setState({
          loading: false,
          groupUsers: users || [],
          selectedIds
        })
      } catch (error) {
        console.error('ProjectGroupUsersPanel - loadChannels - error: ', error)
        this.setState({
          loading: false,
          groupUsers: []
          // TODO: add an error prop & display an error message if this happens
        })
      }
    }
  }

  // -------

  filterProjectUsers = (_filter: string) => {
    const { loading, projectUsers } = this.state // selectedIds
    if (loading) return
    // NB: we currently filter out items even if they're enabled/mapped, but when you submit they will still submit as enabled/mapped (we just don't visually show them for now)
    // TODO: should we show filtered out items that are enabled/mapped in some way, to make it obvious they'll remain selected when you save the mapping form?
    const filter = _filter.length > 0 ? _filter : undefined
    const filteredProjectUsers = filter
      ? projectUsers.reduce<Array<ArkManagerFilteredItem<User>>>((r, user) => {
        // TODO: also allow filtering by user email here (if we actually show it in the listing & not just the name??)
        let nameMatch = false
        // let emailMatch = false
        if (user.name().toLowerCase().includes(filter.toLowerCase())) {
          nameMatch = true
        }
        // if (group.desc?.toLowerCase().includes(filter.toLowerCase())) {
        //   emailMatch = true
        // }
        // console.log('filterUsers - user.name(): ', user.name(), ' nameMatch: ', nameMatch, ' emailMatch: ', emailMatch)
        if (nameMatch) { // || emailMatch) {
          const matchingFields: Array<string> = []
          if (nameMatch) matchingFields.push('name')
          // if (emailMatch) matchingFields.push('email')
          const filteredItem: ArkManagerFilteredItem<User> = {
            item: user,
            matchingFields
          }
          r.push(filteredItem)
        }
        return r
      }, [] as Array<ArkManagerFilteredItem<User>>)
      : undefined
    this.setState({ filter, filteredProjectUsers })
  }

  clearFilteredProjectPrograms = () => {
    this.setState({ filter: undefined, filteredProjectUsers: undefined })
  }

  // -------

  // TESTING: takes the result of ArkDataMappingItemChanges (additions & deletions) & converts it to the format the api endpoint expects
  // TODO: move to the relevant api class so its re-usable else-where...
  generateGroupUserUpdateOperations = (groupId: number, itemChanges: ArkDataMappingItemChanges) => {
    const addOperations: Array<GroupUserOperation> = []
    const delOperations: Array<GroupUserOperation> = []
    for (const addItem of itemChanges.add) {
      addOperations.push({
        operation: 0, // 0 === ADD
        group_id: groupId,
        user_id: addItem
      })
    }
    for (const delItem of itemChanges.del) {
      delOperations.push({
        operation: 2, // 2 == DELETE
        group_id: groupId,
        user_id: delItem
      })
    }
    return [...delOperations, ...addOperations]
  }
}

export default withProjectAdminContext(ProjectGroupUsersPanel)
