/**
 * GroupNode
 */

import React from 'react'
import { Node } from 'react-flow-renderer'

import { pluralize } from 'src/core/utilities/strings'

import { NodeDataGroup } from '../types'
import BaseNode from './BaseNode'

import styles from './GroupNode.module.css'

const GroupNode = (props: Node<NodeDataGroup>) => {
  const { data } = props
  if (!data) return null
  const { name, numberOfChannels, numberOfPrograms, numberOfUsers } = data

  const popupBody = (
    <>
      <div>{`${numberOfPrograms} ${pluralize('program', numberOfPrograms)}`}</div>
      <div>{`${numberOfChannels} ${pluralize('channel', numberOfChannels)}`}</div>
      <div>{`${numberOfUsers} ${pluralize('user', numberOfUsers)}`}</div>
    </>
  )

  return (
    <BaseNode
      iconName='group-filled'
      popupBody={popupBody}
      popupName={name}
      popupPosition='right center'
      popupTitle='Group'
      {...props}
    >
      <div className={styles.container}>
        <div className={styles.name}>{name}</div>
      </div>
    </BaseNode>
  )
}

export default GroupNode
