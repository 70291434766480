import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import _ from 'lodash'

import { Channel, Notice } from 'src/core/models'
import { NoticeConditions } from 'src/core/models/notice'
import { useUser } from 'src/core/providers/UserProvider'
import { decodeNoticeConditions } from 'src/core/utilities/notice'

const CHECK_INTERVAL: number = 30000 // 30 seconds

export interface IViewerNoticeContext {
  dismissNotice: (channelId: number, noticeId: number) => void
  getFilteredNotices: (channel: Channel) => Notice[]
}

interface ViewerNoticeProviderProps {
  children: ReactNode
}

export const ViewerNoticeContext = createContext<IViewerNoticeContext>({} as IViewerNoticeContext)

export const useViewerNotice = () => useContext(ViewerNoticeContext)

const ViewerNoticeProvider = (props: ViewerNoticeProviderProps) => {
  const { children } = props

  const user = useUser()

  const [, setCount] = useState<number>(0)
  const [dismissed, setDismissed] = useState<{ channelId: number, noticeId: number }[]>([])

  const admin: boolean = user.actions.isAdmin()

  /**
   * timer (force re-render)
   */

  useEffect(() => {
    // console.log('ViewerNoticeProvider - timer - start')
    let count: number = 0
    const timer = setInterval(() => {
      count++
      setCount(count)
    }, CHECK_INTERVAL)
    return () => {
      // console.log('ViewerNoticeProvider - timer - stop')
      clearInterval(timer)
    }
  }, [])

  const dismissNotice = (channelId: number, noticeId: number): void => {
    // console.log('ViewerNoticeProvider - dismissNotice - channelId:', channelId, 'noticeId:', noticeId)
    setDismissed([...dismissed, { channelId, noticeId }])
  }

  const getFilteredNotices = (channel: Channel): Notice[] => {
    // console.log('ViewerNoticeProvider - getFilteredNotices - channel:', channel)
    let notices: Notice[] = channel.notices

    // dismissed
    notices = _.filter(notices, notice => {
      if (notice.dismissible || (notice.dismissible_admin && admin)) {
        return !_.some(dismissed, item => item.channelId === channel.id && item.noticeId === notice.id)
      }
      return true
    })

    // conditions
    notices = _.filter(notices, notice => {
      if (notice.conditions) {
        const conditions: NoticeConditions = decodeNoticeConditions(notice.conditions)
        if (conditions.dateRange) {
          const currentTime = new Date().getTime()
          const startTime = new Date(conditions.dateRange.start).getTime()
          const endTime = new Date(conditions.dateRange.end).getTime()
          return _.inRange(currentTime, startTime, endTime)
        }
      }
      return true
    })

    return notices
  }

  return (
    <ViewerNoticeContext.Provider value={{
      dismissNotice,
      getFilteredNotices
    }}>
      {children}
    </ViewerNoticeContext.Provider>
  )
}

export default ViewerNoticeProvider
