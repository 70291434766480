/**
 * ArkLoaderView
 */

import React from 'react'

import ArkLoader from '../ArkLoader'

import styles from './ArkLoaderView.module.css'

interface ArkLoaderViewProps {
  className?: string;
  message?: string
}

const ArkLoaderView = (props: ArkLoaderViewProps) => {
  const { className, message } = props

  return (
    <div className={`${styles.view} ${className || ''}`}>
      <ArkLoader message={message} />
    </div>
  )
}

export default ArkLoaderView
