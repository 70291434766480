/**
 * ProjectGroupListItem
 */

import React from 'react'

import ArkListItem, { ArkListItemDetail } from 'src/core/components/ArkListItem'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { Group } from 'src/core/models'

import styles from './ProjectGroupListItem.module.css'

interface ProjectGroupListItemProps {
  active: boolean
  filter?: string
  group: Group
  onClick: () => void
  onEditClick?: () => void
}

const ProjectGroupListItem = (props: ProjectGroupListItemProps) => {
  const {
    active,
    filter,
    group,
    onClick,
    onEditClick
  } = props

  const rightComponentDesktop = (
    <>
      <ArkListItemDetail horizontal title='Users:'>
        <div className={styles.detailText}>{group.usersCount}</div>
      </ArkListItemDetail>
      <ArkSpacer />
      <ArkListItemDetail horizontal title='Channels:'>
        <div className={styles.detailText}>{group.channelsCount}</div>
      </ArkListItemDetail>
    </>
  )

  return (
    <ArkListItem
      active={active}
      description={group.desc}
      filter={filter}
      onClick={onClick}
      onEditClick={onEditClick}
      rightComponentDesktop={rightComponentDesktop}
      title={group.name}
    />
  )
}

export default ProjectGroupListItem
