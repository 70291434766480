import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Button, Form, Input } from 'semantic-ui-react'

import { OBJECT_CHANNEL_NAME } from 'src/constants/strings'
import ArkButton, { ArkButtonGroup } from 'src/core/components/ArkButton'
import ArkCheckbox from 'src/core/components/ArkCheckbox'
import ArkDropdown from 'src/core/components/ArkDropdown'
import ArkIcon from 'src/core/components/ArkIcon'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkPopup from '../../../core/components/ArkPopup'
import ArkSlider from 'src/core/components/ArkSlider'
import ArkSpacer from 'src/core/components/ArkSpacer'
import {
  PROGRAM_BUFFER_MAX,
  PROGRAM_BUFFER_MIN,
  PROGRAM_BUFFER_OPTION_BALANCED,
  PROGRAM_BUFFER_OPTION_FAST,
  PROGRAM_BUFFER_OPTION_STABLE,
  PROGRAM_BUFFER_STEP,
  PROGRAM_OPTIONS_SHOW_ADVANCED,
  PROGRAM_OPTIONS_SHOW_ADVANCED_PARAMETERS,
  PROGRAM_OPTIONS_SHOW_BUFFER,
  VIDEO_PLAYER_ABR_RESOLUTIONS,
  VIDEO_PLAYER_SUPPORTED_RESOLUTIONS
} from '../../../constants/config'
import { useDebounceEffect } from 'src/core/hooks/debounce'
import { useViewer } from 'src/viewer/providers/ViewerProvider'

import styles from './ChannelOptionsPopup.module.css'
import { PlayerResolution } from 'src/core/types/player'

interface ChannelOptionsPopupProps {
  disabled: boolean
}

const ChannelOptionsPopup = (props: ChannelOptionsPopupProps) => {
  const { disabled } = props

  const viewer = useViewer()

  const [_advancedParameters, _setAdvancedParameters] = useState(viewer.getChannelAdvancedParameters())
  const [_buffer, _setBuffer] = useState(viewer.getChannelBuffer())
  const [isAdvancedParametersValid, setIsAdvancedParametersValid] = useState(true)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [showAdvanced, setShowAdvanced] = useState(false)

  useEffect(() => {
    try {
      if (_advancedParameters) JSON.parse(_advancedParameters)
      setIsAdvancedParametersValid(true)
      viewer.setChannelAdvancedParameters(_advancedParameters)
    } catch (_error) {
      setIsAdvancedParametersValid(false)
    }
  }, [_advancedParameters])

  useDebounceEffect(() => {
    viewer.setChannelBuffer(_buffer)
    viewer.restart()
  }, [_buffer], 1000)

  /**
   * actions
   */

  const onAdvancedParametersSubmit = () => {
    console.log('ChannelOptionsPopup - onAdvancedParametersSubmit')
    try {
      if (_advancedParameters) JSON.parse(_advancedParameters)
      setIsAdvancedParametersValid(true)
      viewer.setChannelAdvancedParameters(_advancedParameters)
      viewer.restart()
    } catch (_error) {
      setIsAdvancedParametersValid(false)
    }
  }

  const onDefaultsClick = () => {
    console.log('ChannelOptionsPopup - onDefaultsClick')
    viewer.resetChannel()
  }

  /**
   * render
   */

  // resolution
  const abr: boolean = viewer.getChannelAbr()
  const resolution: PlayerResolution = viewer.getChannelResolution()
  const resolutionOptions = _.map(VIDEO_PLAYER_SUPPORTED_RESOLUTIONS, value => (
    {
      active: value === resolution,
      onClick: () => viewer.setChannelResolution(value),
      text: `${abr && _.includes(VIDEO_PLAYER_ABR_RESOLUTIONS, value) ? 'Up to ' : ''}${value}`,
      value
    }
  ))

  // playback
  const playbackOptions = [
    {
      active: !viewer.stopped,
      onClick: () => viewer.setStopped(false),
      text: !viewer.stopped ? 'Started' : 'Start',
      value: !viewer.stopped ? 'Started' : 'Start'
    },
    {
      active: viewer.stopped,
      onClick: () => viewer.setStopped(true),
      text: viewer.stopped ? 'Stopped' : 'Stop',
      value: viewer.stopped ? 'Stopped' : 'Stop'
    },
    {
      active: viewer.restarting,
      disabled: viewer.stopped,
      onClick: !viewer.stopped ? viewer.restart : undefined,
      text: viewer.restarting ? 'Restarting…' : 'Restart',
      value: viewer.restarting ? 'Restarting…' : 'Restart'
    }
  ]
  let playbackValue = viewer.stopped ? 'Stopped' : 'Started'
  if (viewer.restarting) playbackValue = 'Restarting…'

  const passthrough: boolean = viewer.getChannelPassthrough()

  return (
    <ArkPopup
      className={styles.popup}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      position='top right'
      title={`${OBJECT_CHANNEL_NAME} video options`.toUpperCase()}
      trigger={
        <ArkIconButton
          data-test-id="ark-channel-video-quality-btn" // e2e testing identifier
          disabled={disabled}
          name='video-quality'
          open={isOpen}
        />
      }
    >
      <div className={styles.label}>Quality:</div>
      <ArkSpacer small />

      {/* passthrough */}
      <ArkButtonGroup fluid size='small'>
        <ArkButton
          active={!passthrough}
          color={!passthrough ? 'blue' : undefined}
          onClick={() => viewer.setChannelPassthrough(false)}
        >
          Transcoder
        </ArkButton>
        <ArkButton
          active={passthrough}
          color={passthrough ? 'blue' : undefined}
          onClick={() => viewer.setChannelPassthrough(true)}
        >
          Passthrough
        </ArkButton>
      </ArkButtonGroup>
      <ArkSpacer large />

      {!passthrough && (
        <>
          {/* abr */}
          <ArkCheckbox
            checked={abr && _.includes(VIDEO_PLAYER_ABR_RESOLUTIONS, resolution)}
            disabled={!_.includes(VIDEO_PLAYER_ABR_RESOLUTIONS, resolution)}
            label='Automatically adjust quality'
            onChange={() => viewer.setChannelAbr(!abr)}
            size='mini'
            toggle
          />
          <ArkSpacer large />

          {/* resolution */}
          <ArkDropdown
            fluid
            selection
            options={resolutionOptions}
            value={resolution}
            data-test-id="ark-channel-options-quality-dropdown" // e2e testing identifier
          />
        </>
      )}

      {/* buffer */}
      {PROGRAM_OPTIONS_SHOW_BUFFER && (
        <>
          <ArkSpacer large />
          <div className={styles.bufferLabelContainer}>
            <div className={styles.label}>Buffer:</div>
            <span className={styles.bufferValue}>
              {`${_buffer.toLocaleString()} ms`}
            </span>
          </div>
          <ArkSpacer small />
          <Button.Group fluid size='tiny'>
            <ArkButton
              active={_buffer === PROGRAM_BUFFER_OPTION_FAST}
              color={_buffer === PROGRAM_BUFFER_OPTION_FAST ? 'blue' : undefined}
              onClick={() => _setBuffer(PROGRAM_BUFFER_OPTION_FAST)}
            >
              Fast
            </ArkButton>
            <ArkButton
              active={_buffer === PROGRAM_BUFFER_OPTION_BALANCED}
              color={_buffer === PROGRAM_BUFFER_OPTION_BALANCED ? 'blue' : undefined}
              onClick={() => _setBuffer(PROGRAM_BUFFER_OPTION_BALANCED)}
            >
              Balanced
            </ArkButton>
            <ArkButton
              active={_buffer === PROGRAM_BUFFER_OPTION_STABLE}
              color={_buffer === PROGRAM_BUFFER_OPTION_STABLE ? 'blue' : undefined}
              onClick={() => _setBuffer(PROGRAM_BUFFER_OPTION_STABLE)}
            >
              Stable
            </ArkButton>
          </Button.Group>
          <ArkSpacer small />
          <ArkSlider
            max={PROGRAM_BUFFER_MAX}
            min={PROGRAM_BUFFER_MIN}
            onChange={event => _setBuffer(parseInt(event.target.value))}
            step={PROGRAM_BUFFER_STEP}
            value={_buffer}
          />
        </>
      )}

      {/* advanced */}
      {PROGRAM_OPTIONS_SHOW_ADVANCED && (
        <>
          <ArkSpacer large />
          <div className={styles.advancedButton} onClick={() => setShowAdvanced(!showAdvanced)}>
            <div>Advanced options</div>
            <ArkIcon name={showAdvanced ? 'angle-double-up' : 'angle-double-down'} size={18} />
          </div>
        </>
      )}

      {showAdvanced && (
        <>
          {/* playback */}
          <ArkSpacer large />
          <div className={styles.label}>Playback state:</div>
          <ArkSpacer small />
          <ArkDropdown fluid options={playbackOptions} selection value={playbackValue} />

          {/* advanced parameters */}
          {PROGRAM_OPTIONS_SHOW_ADVANCED_PARAMETERS && (
            <>
              <ArkSpacer large />
              <div className={styles.label}>Advanced parameters (JSON):</div>
              <ArkSpacer small />
              <Form className={styles.advancedParametersForm} onSubmit={onAdvancedParametersSubmit}>
                <Form.Field className={styles.advancedParametersFormField} error={!isAdvancedParametersValid}>
                  <Input
                    className={styles.advancedParametersInput}
                    fluid
                    onChange={event => _setAdvancedParameters(event.target.value)}
                    value={_advancedParameters}
                  />
                </Form.Field>
                <ArkIconButton
                  className={styles.advancedParametersButton}
                  color='var(--blue)'
                  name='arrow-right'
                  onClick={onAdvancedParametersSubmit}
                  size={48}
                />
              </Form>
              <ArkSpacer small />
              <div className={styles.description}>Example: <code>{'{"aspect_ratio":"4:3"}'}</code></div>
            </>
          )}

          {/* defaults */}
          <ArkSpacer large />
          <div className={styles.defaultsContainer}>
            <ArkButton onClick={onDefaultsClick} size='tiny'>Defaults</ArkButton>
          </div>
        </>
      )}
    </ArkPopup>
  )
}

export default ChannelOptionsPopup
