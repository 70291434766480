import React from 'react'

import AppNotice from '../../components/AppNotice/AppNotice'
import ArkViewerPage from '../../components/ArkViewerPage/ArkViewerPage'
import ChannelMixerModal from '../../components/ChannelView/ChannelMixerModal'
import ChannelView from '../../components/ChannelView/ChannelView'
import AudioLevelsProvider from '../../providers/AudioLevelsProvider'
import ViewerProvider from '../../providers/ViewerProvider'
import ViewerNoticeProvider from 'src/viewer/providers/ViewerNoticeProvider'

class ViewerHomePage extends React.Component {
  render () {
    return (
      <ViewerNoticeProvider>
        <ViewerProvider>
          <AudioLevelsProvider>
            <ArkViewerPage noScroll>
              <AppNotice>
                <ChannelView />
                <ChannelMixerModal />
              </AppNotice>
            </ArkViewerPage>
          </AudioLevelsProvider>
        </ViewerProvider>
      </ViewerNoticeProvider>
    )
  }
}
export default ViewerHomePage
