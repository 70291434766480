/**
 * ArkAvatar
 *
 * A custom wrapper component around the react-avatar `Avatar` component that adds a few additional features..
 * - adds support for filter text highlighting AND for setting a pre-determined `type` to auto configure commonly used avatar styles/setups
 * - text highlighting:
 *   -- `Avatar` only supports string initials & no custom children, so we wrap it in a custom div & then overlay our own text element with highlighting support
 *   -- the main aim with this support is to mimic the normal `Avatar` look/styling but add highlighting
 * - auto config styles:
 *   -- set the new `type` prop to on of the predefined `ArkAvatarType` enum values to have it auto styled for that particular type
 *   -- (meaning you don't need to manually set each prop individually to match its style, although you can override individual props if you need too)
 * - avatar overlay icons:
 *   - also supports adding additional ArkIcon over the top of the avatar to further customise it (used with some of the custom `ArkAvatarType` styles)
 */

import React from 'react'
import Avatar, { ReactAvatarProps } from 'react-avatar'

import ArkHighlightedText from '../ArkHighlightedText'
import ArkIcon, { IconName } from '../ArkIcon'

import styles from './ArkAvatar.module.css'

// the default max initials to show in the avatar if non is specified (NB: some types set/override this)
const ArkAvatarDefaultMaxInitials = 3

// optional customised styling for ArkAvatar - auto configures various props for commonly used avatar types used throughout the site
export enum ArkAvatarType {
  user,
  userGuest,
  userProjectManager,
  userProjectAdmin,
  userCompanyAdmin,
  userSiteAdmin,
  group,
  channel,
  program
}

export enum ArkAvatarOverlayPosition {
  topRight,
  topLeft
}

export interface ArkAvatarProps extends ReactAvatarProps {
  type?: ArkAvatarType
  filter?: string
  filterHighlight?: boolean // enable this to support highlighting of text chars that match the specified filter text prop
  initials?: string | ((name?: string, props?: any) => string)
  maxInitials?: number
  size?: string
  overlayIconName?: IconName
  overlayIconPos?: ArkAvatarOverlayPosition // defaults to .topRight
  overlayIconSize?: number
  filterTextClassName?: string // NB: added to allow overriding of the text size in certain use cases (TODO: can the filter text size better match the default instead & then remove this?)
}

const ArkAvatar = (defaultProps: ArkAvatarProps) => {
  // check if a type was specified & load its props to style the avatar if so
  // NB: if extending/adding more & setting a `className` make sure to also support the default/incoming version if its set
  let typeProps: ArkAvatarProps | undefined
  if (defaultProps.type !== undefined) {
    // common styling for each base type user/group/channel/program
    switch (defaultProps.type) {
      // common user styling (applied to all users) - see further down for user sub-type specific overrides
      case ArkAvatarType.user:
      case ArkAvatarType.userGuest:
      case ArkAvatarType.userProjectManager:
      case ArkAvatarType.userProjectAdmin:
      case ArkAvatarType.userCompanyAdmin:
      case ArkAvatarType.userSiteAdmin:
        typeProps = {
          round: true,
          textSizeRatio: 2.25
          // overlayIconName: 'user-filled',
          // className: `${defaultProps.className ? defaultProps.className + ' ' : ''}${styles.userAvatar}`
          // maxInitials: 3
        }
        break
      case ArkAvatarType.group:
        break
      case ArkAvatarType.channel:
        break
      case ArkAvatarType.program:
        break
    }
    // sub-type specifics
    if (typeProps) {
      switch (defaultProps.type) {
        case ArkAvatarType.user:
          typeProps.className = `${typeProps.className ? typeProps.className + ' ' : ''}${styles.userAvatar}`
          break
        case ArkAvatarType.userGuest:
          typeProps.overlayIconName = 'user-link'
          typeProps.className = `${typeProps.className ? typeProps.className + ' ' : ''}${styles.userGuestAvatar}`
          typeProps.color = 'var(--bg-dark-card)' // '#000000'
          typeProps.maxInitials = defaultProps.maxInitials ?? 1
          break
        case ArkAvatarType.userProjectManager:
          typeProps.overlayIconName = 'user-project-manager'
          typeProps.className = `${typeProps.className ? typeProps.className + ' ' : ''}${styles.userProjectManagerAvatar}`
          break
        case ArkAvatarType.userProjectAdmin:
          typeProps.overlayIconName = 'user-project-admin'
          typeProps.className = `${typeProps.className ? typeProps.className + ' ' : ''}${styles.userProjectAdminAvatar}`
          break
        case ArkAvatarType.userCompanyAdmin:
          typeProps.overlayIconName = 'user-org-admin'
          typeProps.className = `${typeProps.className ? typeProps.className + ' ' : ''}${styles.userCompanyAdminAvatar}`
          break
        case ArkAvatarType.userSiteAdmin:
          typeProps.overlayIconName = 'user-admin'
          typeProps.className = `${typeProps.className ? typeProps.className + ' ' : ''}${styles.userSiteAdminAvatar}`
          break
      }
    }
  }

  // merge the type specific props with the incoming/default ones if a type was specified
  // NB: the className is handled specially to allow both the type & default/incoming to be applied at the same time
  // TODO: when `typeClassName` is assigned to `alteredProps` below, are we loosing the `defaultProps.className`? briefly tried to add it back in, but seemed to double it up for some reason, so left for now
  const typeClassName = typeProps?.className
  const maxInitials = typeProps?.maxInitials ?? defaultProps.maxInitials ?? ArkAvatarDefaultMaxInitials
  const alteredProps: ArkAvatarProps = (typeProps !== undefined)
    ? { ...typeProps, ...defaultProps, ...(typeClassName ? { className: typeClassName /* + (defaultProps.className ? ' ' + defaultProps.className : '') */ } : {}), ...{ maxInitials } }
    : { ...defaultProps, ...{ maxInitials } }
  const { className, filter, filterHighlight, name, size: _size, overlayIconName, overlayIconPos, filterTextClassName } = alteredProps
  const size = _size ?? '40'

  // NB: cloned from the Avatar source so we can access/use it at this level (& slightly tweaked for TS support) - ref: https://github.com/ambassify/react-avatar/blob/master/src/utils.js
  const defaultInitials = (name?: string, _props?: any): string => {
    const { maxInitials } = _props
    if (!name) return ''
    return name.split(/\s/)
      .map(part => part.substring(0, 1).toUpperCase())
      .filter(v => !!v)
      .slice(0, maxInitials)
      .join('')
      .toUpperCase()
  }

  // NB: cloned from the Avatar source (& slightly tweaked for TS support) - ref: https://github.com/ambassify/react-avatar/blob/master/src/sources/Value.js
  const getInitials = () => {
    const { name, initials } = alteredProps
    if (typeof initials === 'string') return initials
    if (typeof initials === 'function') return initials(name, alteredProps)
    return defaultInitials(name, alteredProps)
  }

  // if NOT using the text highlight feature we skip all custom `Avatar` wrapper handling & just return it directly...
  if (!filterHighlight && !overlayIconName) {
    return (
      <Avatar
        {...alteredProps}
        className={styles.avatar + (className ? ' ' + className : '')}
        initials={getInitials()}
        size={size}
      />
    )
  }

  // if we ARE using text highlighting wrap the `Avatar` component in our element & display the text 'initials' ourselves so we can support highlighting...
  // TESTING: match the react-avatar sizing calcs (NB: we re-use its parseSize function directly, with minor tweaks to make it TS compatible)
  // react-avatar sizing function ref: https://github.com/ambassify/react-avatar/blob/23fe94f2c9b6e4c91a321160a51523fff33da813/src/utils.js
  const reSize = /^([-+]?(?:\d+(?:\.\d+)?|\.\d+))([a-z]{2,4}|%)?$/
  const parseSize = (size?: string) => {
    size = '' + size
    const [, value = '0', unit = 'px'] = reSize.exec(size) || []
    return { value: parseFloat(value), str: value + unit, unit }
  }
  const parsedSize = parseSize(size)

  let overlayIconClass = styles.iconTR
  switch (overlayIconPos) {
    case ArkAvatarOverlayPosition.topLeft: overlayIconClass = styles.iconTL
  }
  const overlayIconSize = alteredProps.overlayIconSize ? alteredProps.overlayIconSize : (parsedSize.value / 2) // auto size the icon to 1/2 the avatar if a specific size isn't set

  return (
    <div className={styles.avatarWrapper} style={{ width: parsedSize.str, height: parsedSize.str }}>
      <Avatar
        {...alteredProps}
        className={styles.avatar + (className ? ' ' + className : '')}
        initials={filterHighlight ? ' ' : getInitials()} // if filter highlighting is enabled don't add the iniitals within the Avatar element (it only supports strings)
        size={size}
      />
      {/* add a custom text 'initials' overlay utilising the `ArkHighlightedText` component, it gets styled to look like the normal `Avatar` initials text */}
      {filterHighlight && (<div className={styles.avatarText + (filterTextClassName ? ' ' + filterTextClassName : '')} title={name}>
        <ArkHighlightedText highlight={filter}>{getInitials()}</ArkHighlightedText>
      </div>)}
      {overlayIconName && (<ArkIcon className={`${styles.icon} ${overlayIconClass}`} color='var(--bg-dark-card)' name={overlayIconName} size={overlayIconSize} />)}
    </div>
  )
}

export default ArkAvatar
