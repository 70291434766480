import React, { useContext } from 'react'

import { ProjectAdminContext } from 'src/core/providers'

import { ProjectUser } from 'src/core/models'

import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { OBJECT_GUEST_NAME, OBJECT_USER_NAME } from 'src/constants/strings'

export type ProjectUserDeleteCallback = (user: ProjectUser) => void

interface IProps {
  companyId: number
  projectId: number
  user: ProjectUser
  onDelete?: ProjectUserDeleteCallback
  className?: string
}

const ProjectUserDeleteView = (props: IProps) => {
  const { companyId, projectId, user, onDelete: _onDelete, className } = props

  const { actions: projectAdminActions } = useContext(ProjectAdminContext)

  const onDelete = async (_itemId: number): Promise<boolean> => {
    // NB: DON'T catch the error/exception here, let it throw (or if you do, rethrow it so the calling code can catch & display it)
    if (user.isGuest) {
      const deleteResult = await projectAdminActions.removeSharedGuestUserFromProject(companyId, projectId, user.id)
      console.log('ProjectUserDeleteView - onDelete - removeUserFromProject (guest) - deleteResult: ', deleteResult)
      return deleteResult
    } else {
      const deleteResult = await projectAdminActions.removeUserFromProject(companyId, projectId, user.id)
      console.log('ProjectUserDeleteView - onDelete - removeUserFromProject (normal user) - deleteResult: ', deleteResult)
      return deleteResult
    }
  }

  if (!user) return null
  const title = user.isGuest ? 'REMOVE ' + OBJECT_GUEST_NAME + ' FROM PROJECT' : 'REMOVE ' + OBJECT_USER_NAME + ' FROM PROJECT'
  return (
    <div className={(className ? ' ' + className : '')}>
      <ArkSpacer />
      <ArkManagerDeleteButton
        itemId={user.id}
        itemName={user.name()}
        itemTypeName={(user.isGuest ? OBJECT_GUEST_NAME + ' ' : '') + OBJECT_USER_NAME}
        buttonTitle={title}
        deleteWord='Remove'
        deletedWord='Removed'
        onDelete={onDelete}
        onDeleteComplete={() => { if (_onDelete) _onDelete(user) }}
      />
    </div>
  )
}

export default ProjectUserDeleteView
