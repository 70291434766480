/**
 * NodeGraphView
 *
 * https://reactflow.dev/examples/layouting/
 * https://github.com/dagrejs/dagre/pull/271
 */

/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { useContext, useEffect, useRef, useState } from 'react'
import dagre from 'dagre'
import _ from 'lodash'
import ReactFlow, {
  Edge,
  Elements,
  isNode,
  Node,
  OnLoadParams,
  Position,
  ReactFlowProvider
} from 'react-flow-renderer'

import {
  PROJECT_CHECKLIST_ENABLED,
  PROJECT_NODE_GRAPH_DAGRE_ENABLED,
  PROJECT_NODE_GRAPH_SELECTION_ENABLED
} from 'src/constants/config'
import * as ROUTES from 'src/constants/routes'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkOnboardingPopup from 'src/core/components/ArkOnboardingPopup'
import ArkResponsive from 'src/core/components/ArkResponsive'
import { ResponsiveContext } from 'src/core/providers/ResponsiveProvider'

import { ChecklistContext } from '../checklist/ChecklistProvider'
import ChannelNode from './components/ChannelNode'
import EmptyNode from './components/EmptyNode'
import FilterModal from './components/FilterModal'
import FilterPopup from './components/FilterPopup'
import GroupNode from './components/GroupNode'
import ProgramNode from './components/ProgramNode'
import UserNode from './components/UserNode'
import { NodeGraphContext } from './NodeGraphProvider'
import {
  NodeDataChannel,
  NodeDataEmpty,
  NodeDataGroup,
  NodeDataProgram,
  NodeDataUser,
  NodeIdPrefix,
  NodeSection,
  NodeType
} from './types'
import {
  getChannelEdgeHealth,
  getEdgeColor,
  getProgramEdgeHealth,
  getIsEdgeAnimated,
  getIsEdgeBlurred,
  getIsNodeBlurred,
  getNodeRankFromSection,
  getNumberOfOfflineProgramsForChannel,
  getNumberOfChannelsForGroup,
  getNumberOfProgramsForChannel,
  getNumberOfProgramsForGroup,
  getNumberOfUsersForGroup
} from './utilities'

import styles from './NodeGraphView.module.css'

const DEFAULT_NODE_POSITION = { x: 0, y: 0 }
const DEFAULT_NODE_STYLE = { transition: 'all 0.5s ease 0s' }

/**
 * layouting
 */

const getLayoutedElements = (elements: Elements): Elements => {
  console.log('NodeGraphView - getLayoutedElements - elements:', elements)

  if (!PROJECT_NODE_GRAPH_DAGRE_ENABLED) {
    return _.map(elements, element => {
      if (isNode(element)) {
        const index = _.chain(elements)
          .filter(theElement => theElement.data?.section === element.data.section)
          .indexOf(element)
          .value()
        switch (element.data.section) {
          case NodeSection.Programs: element.position = { x: 80 * index, y: 0 }; break
          case NodeSection.Channels: element.position = { x: 320 * index, y: 160 }; break
          case NodeSection.Groups: element.position = { x: 320 * index, y: 320 }; break
          case NodeSection.Users: element.position = { x: 80 * index, y: 480 }; break
        }
        element.sourcePosition = Position.Bottom
        element.targetPosition = Position.Top
      }
      return element
    })
  }

  const graph = new dagre.graphlib.Graph({ compound: true })
  graph.setDefaultEdgeLabel(() => ({}))
  graph.setGraph({ nodesep: 0, rankdir: 'TB', ranksep: 240, ranker: 'none' })

  _.each(elements, element => {
    if (isNode(element)) {
      let width = 0
      switch (element.type as NodeType) {
        case NodeType.Program: width = 80; break
        case NodeType.Channel: width = 200; break
        case NodeType.Group: width = 200; break
        case NodeType.User: width = 80; break
        case NodeType.Empty: width = 200; break
      }
      graph.setNode(element.id, { rank: getNodeRankFromSection(element.data.section as NodeSection), width, height: 80 })
    } else {
      graph.setEdge(element.source, element.target)
    }
  })

  dagre.layout(graph)

  return _.map(elements, element => {
    if (isNode(element)) {
      const node = graph.node(element.id)
      element.position = {
        x: node.x - node.width / 2,
        y: node.y - node.height / 2
      }
      element.sourcePosition = Position.Bottom
      element.targetPosition = Position.Top
    }
    return element
  })
}

/**
 * main
 */

const NodeGraphView = () => {
  const { store: responsiveStore } = useContext(ResponsiveContext)
  const { isMobile } = responsiveStore

  const { actions: checklistActions, store: checklistStore } = useContext(ChecklistContext)
  const { setShowChecklist } = checklistActions
  const { showChecklist } = checklistStore

  const { actions, store } = useContext(NodeGraphContext)
  const { navigateTo, setShowFilter, setIsFullscreen } = actions
  const {
    filteredData,
    showFilter,
    isFullscreen,
    numberOfHiddenChannels,
    numberOfHiddenGroups,
    numberOfHiddenPrograms,
    numberOfHiddenUsers,
    unfilteredData
  } = store

  const container = useRef<HTMLDivElement>(null)
  const focused = useRef<string>()
  const selected = useRef<string>()

  const [elements, setElements] = useState<Elements>([])
  const [isFit, setIsFit] = useState<boolean>(true)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams>()
  const [windowSize, setWindowSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 })

  useEffect(() => {
    const listener = () => setWindowSize({ width: window.innerWidth, height: window.innerHeight })
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [])

  useEffect(() => {
    if (!filteredData) return
    updateElements()
  }, [filteredData])

  useEffect(() => {
    if (!filteredData) return
    if (isFit) fitView()
  }, [filteredData, isFullscreen, reactFlowInstance, showChecklist, windowSize])

  if (!filteredData || !unfilteredData) return null

  const { channels, groups, programs, users } = filteredData

  /**
   * utilities
   */

  const getElements = (focused: string | undefined = undefined, selected: string | undefined = undefined): Elements => {
    console.log('NodeGraphView - getElements - focused:', focused, 'selected:', selected)

    // program nodes
    const programNodes: Node<NodeDataProgram>[] = _.map(programs, program => {
      const id = `${NodeIdPrefix.Program + program.id}`
      const isBlurred = getIsNodeBlurred({ data: filteredData, focused, node: id, selected })
      const data: NodeDataProgram = {
        blurred: isBlurred,
        colour: program.colour,
        focused: !!focused && !isBlurred,
        isOnline: program.isOnline,
        name: program.name,
        onViewClick: () => navigateTo(ROUTES.PROJECT_MANAGER_PROGRAMS, program.id),
        section: NodeSection.Programs,
        selected: id === focused || id === selected,
        shortName: program.shortName
      }
      return {
        id,
        data,
        position: DEFAULT_NODE_POSITION,
        style: DEFAULT_NODE_STYLE,
        type: NodeType.Program
      }
    })

    // channel nodes
    const channelNodes: Node<NodeDataChannel>[] = _.map(channels, channel => {
      const id = `${NodeIdPrefix.Channel + channel.id}`
      const isBlurred = getIsNodeBlurred({ data: filteredData, focused, node: id, selected })
      const data: NodeDataChannel = {
        blurred: isBlurred,
        colour: channel.colour,
        focused: !!focused && !isBlurred,
        name: channel.name,
        numberOfOfflinePrograms: getNumberOfOfflineProgramsForChannel(channel.id, unfilteredData),
        numberOfPrograms: getNumberOfProgramsForChannel(channel.id, unfilteredData),
        onViewClick: () => navigateTo(ROUTES.PROJECT_MANAGER_CHANNELS, channel.id),
        section: NodeSection.Channels,
        selected: id === focused || id === selected
      }
      return {
        id,
        data,
        position: DEFAULT_NODE_POSITION,
        style: DEFAULT_NODE_STYLE,
        type: NodeType.Channel
      }
    })

    // group nodes
    const groupNodes: Node<NodeDataGroup>[] = _.map(groups, group => {
      const id = `${NodeIdPrefix.Group + group.id}`
      const isBlurred = getIsNodeBlurred({ data: filteredData, focused, node: id, selected })
      const data: NodeDataGroup = {
        blurred: isBlurred,
        focused: !!focused && !isBlurred,
        name: group.name,
        numberOfChannels: getNumberOfChannelsForGroup(group.id, unfilteredData),
        numberOfPrograms: getNumberOfProgramsForGroup(group.id, unfilteredData),
        numberOfUsers: getNumberOfUsersForGroup(group.id, unfilteredData),
        onViewClick: () => navigateTo(ROUTES.PROJECT_MANAGER_GROUPS, group.id),
        section: NodeSection.Groups,
        selected: id === focused || id === selected
      }
      return {
        id,
        data,
        position: DEFAULT_NODE_POSITION,
        style: DEFAULT_NODE_STYLE,
        type: NodeType.Group
      }
    })

    // user nodes
    const userNodes: Node<NodeDataUser>[] = _.map(users, user => {
      const id = `${NodeIdPrefix.User + user.id}`
      const isBlurred = getIsNodeBlurred({ data: filteredData, focused, node: id, selected })
      const data: NodeDataUser = {
        avatarType: user.avatarType,
        blurred: isBlurred,
        focused: !!focused && !isBlurred,
        guest: user.guest,
        name: user.name,
        onViewClick: () => navigateTo(ROUTES.PROJECT_MANAGER_USERS, user.id),
        section: NodeSection.Users,
        selected: id === focused || id === selected
      }
      return {
        id,
        data,
        position: DEFAULT_NODE_POSITION,
        style: DEFAULT_NODE_STYLE,
        type: NodeType.User
      }
    })

    // empty nodes
    const emptyNodes: Node<NodeDataEmpty>[] = []
    if (!_.some(programs)) {
      emptyNodes.push({
        id: 'empty-programs',
        data: {
          numberHidden: numberOfHiddenPrograms,
          onHiddenClick: onFilterClick,
          section: NodeSection.Programs,
          title: 'Programs'
        },
        position: DEFAULT_NODE_POSITION,
        style: DEFAULT_NODE_STYLE,
        type: NodeType.Empty
      })
    }
    if (!_.some(channels)) {
      emptyNodes.push({
        id: 'empty-channels',
        data: {
          numberHidden: numberOfHiddenChannels,
          onHiddenClick: onFilterClick,
          section: NodeSection.Channels,
          title: 'Channels'
        },
        position: DEFAULT_NODE_POSITION,
        style: DEFAULT_NODE_STYLE,
        type: NodeType.Empty
      })
    }
    if (!_.some(groups)) {
      emptyNodes.push({
        id: 'empty-groups',
        data: {
          numberHidden: numberOfHiddenGroups,
          onHiddenClick: onFilterClick,
          section: NodeSection.Groups,
          title: 'Groups'
        },
        position: DEFAULT_NODE_POSITION,
        style: DEFAULT_NODE_STYLE,
        type: NodeType.Empty
      })
    }
    if (!_.some(users)) {
      emptyNodes.push({
        id: 'empty-users',
        data: {
          numberHidden: numberOfHiddenUsers,
          onHiddenClick: onFilterClick,
          section: NodeSection.Users,
          title: 'Users'
        },
        position: DEFAULT_NODE_POSITION,
        style: DEFAULT_NODE_STYLE,
        type: NodeType.Empty
      })
    }

    // program edges (lines)
    const programEdges: Edge[] = _.chain(channels)
      .map(channel => _.map(channel.programs, id => {
        const source = `${NodeIdPrefix.Program + id}`
        const target = `${NodeIdPrefix.Channel + channel.id}`
        const health = getProgramEdgeHealth(id, filteredData)
        const isAnimated = getIsEdgeAnimated(health)
        const isBlurred = getIsEdgeBlurred({ data: filteredData, focused, selected, source, target })
        return {
          id: `${source}-${target}`,
          animated: isAnimated,
          source,
          style: {
            opacity: isBlurred ? 0.25 : 1,
            stroke: getEdgeColor(health),
            strokeWidth: 2,
            strokeDasharray: isAnimated ? undefined : '4,4',
            transition: '0.5s'
          },
          target
        }
      }))
      .flatten()
      .value()

    // channel edges (lines)
    const channelEdges: Edge[] = _.chain(groups)
      .map(group => _.map(group.channels, id => {
        const source = `${NodeIdPrefix.Channel + id}`
        const target = `${NodeIdPrefix.Group + group.id}`
        const health = getChannelEdgeHealth(id, filteredData)
        const isAnimated = getIsEdgeAnimated(health)
        const isBlurred = getIsEdgeBlurred({ data: filteredData, focused, selected, source, target })
        return {
          id: `${source}-${target}`,
          animated: isAnimated,
          source,
          style: {
            opacity: isBlurred ? 0.25 : 1,
            stroke: getEdgeColor(health),
            strokeDasharray: isAnimated ? undefined : '4,4',
            strokeWidth: 2,
            transition: '0.5s'
          },
          target
        }
      }))
      .flatten()
      .value()

    // group edges (lines)
    const groupEdges: Edge[] = _.chain(groups)
      .map(group => _.map(group.users, id => {
        const source = `${NodeIdPrefix.Group + group.id}`
        const target = `${NodeIdPrefix.User + id}`
        const isBlurred = getIsEdgeBlurred({ data: filteredData, focused, selected, source, target })
        return ({
          id: `${source}-${target}`,
          source,
          style: {
            opacity: isBlurred ? 0.25 : 1,
            stroke: 'var(--bd-light)',
            strokeDasharray: '4,4',
            strokeWidth: 2,
            transition: '0.5s'
          },
          target
        })
      }))
      .flatten()
      .value()

    return [
      ...programNodes,
      ...channelNodes,
      ...groupNodes,
      ...userNodes,
      ...emptyNodes,
      ...programEdges,
      ...channelEdges,
      ...groupEdges
    ]
  }

  const updateElements = () => {
    console.log('NodeGraphView - updateElements')
    setElements(getLayoutedElements(getElements(focused.current, selected.current)))
  }

  const onLoad = (reactFlowInstance: OnLoadParams) => {
    console.log('NodeGraphView - onLoad')
    setReactFlowInstance(reactFlowInstance)
    setTimeout(() => setIsLoaded(true), 100)
  }

  const fitView = async () => {
    console.log('NodeGraphView - fitView')
    setTimeout(() => {
      reactFlowInstance?.fitView({
        minZoom: isMobile ? 0.5 : undefined,
        padding: 0.15
      })
      setIsFit(true)
    }, 100)
  }

  const setFocused = (id: string | undefined) => {
    console.log('NodeGraphView - setFocused - id:', id)
    focused.current = id
    updateElements()
  }

  const setSelected = (id: string | undefined) => {
    console.log('NodeGraphView - setSelected - id:', id)
    if (!PROJECT_NODE_GRAPH_SELECTION_ENABLED) return
    selected.current = id === selected.current ? undefined : id
    updateElements()
  }

  /**
   * actions
   */

  const onFilterClick = () => {
    console.log('NodeGraphView - onFilterClick')
    setShowFilter(true)
  }

  const onFitClick = () => {
    console.log('NodeGraphView - onFitClick')
    if (isFit) {
      setIsFit(false)
    } else {
      fitView()
    }
  }

  const onFullscreenClick = () => {
    console.log('NodeGraphView - onFullscreenClick')
    setIsFullscreen(!isFullscreen)
  }

  const onNodeMouseEnter = (_event: React.MouseEvent<Element, MouseEvent>, node: Node<any>) => {
    console.log('NodeGraphView - onFullscreenClick - event:', _event, 'node:', node)
    if (showFilter) return
    if (node.type === NodeType.Empty) return
    setFocused(node.id)
  }

  const onZoomInClick = () => {
    console.log('NodeGraphView - onZoomInClick')
    reactFlowInstance?.zoomIn()
  }

  const onZoomOutClick = () => {
    console.log('NodeGraphView - onZoomOutClick')
    reactFlowInstance?.zoomOut()
  }

  /**
   * render
   */

  // console.log('NodeGraphView - render')

  const filterButtonComponent = (
    <ArkOnboardingPopup
      content={'This helps you to filter what\'s shown on the project map.'}
      id='projectNodeGraphFilter'
      show
    >
      <ArkIconButton
        className={`${styles.controlsButton} ${isMobile ? styles.controlsButtonMobile : ''}`}
        color={showFilter ? 'var(--tx-light)' : 'var(--bd-lighter)'}
        hoverColor='var(--tx-light)'
        onClick={() => setShowFilter(!showFilter)}
        name='filter'
        size={isMobile ? 20 : 24}
      />
    </ArkOnboardingPopup>
  )

  const topRightControlsComponent = (
    <div className={`${styles.topRightControls} ${isMobile ? styles.topRightControlsMobile : ''}`}>
      <ArkResponsive desktopOnly>
        <FilterPopup trigger={filterButtonComponent} />
      </ArkResponsive>
      <ArkResponsive mobileOnly>
        {filterButtonComponent}
        <FilterModal />
      </ArkResponsive>
      <ArkResponsive desktopOnly>
        <ArkIconButton
          className={`${styles.controlsButton} ${isMobile ? styles.controlsButtonMobile : ''}`}
          color='var(--bd-lighter)'
          hoverColor='var(--tx-light)'
          name={isFullscreen ? 'fullscreen-off' : 'fullscreen-on'}
          onClick={onFullscreenClick}
          size={24}
        />
      </ArkResponsive>
      {PROJECT_CHECKLIST_ENABLED && (
        <ArkIconButton
          className={`${styles.controlsButton} ${isMobile ? styles.controlsButtonMobile : ''}`}
          color={showChecklist ? 'var(--tx-light)' : 'var(--bd-lighter)'}
          disabled={isFullscreen}
          hoverColor='var(--tx-light)'
          name='clipboard'
          onClick={() => setShowChecklist(!showChecklist)}
          size={isMobile ? 20 : 24}
        />
      )}
    </div>
  )

  const bottomRightControlsComponent = (
    <div className={`${styles.bottomRightControls} ${isMobile ? styles.bottomRightControlsMobile : ''}`}>
      <ArkResponsive desktopOnly>
        <ArkIconButton
          className={`${styles.controlsButton} ${isMobile ? styles.controlsButtonMobile : ''}`}
          color='var(--bd-lighter)'
          hoverColor='var(--tx-light)'
          name='zoom-in'
          onClick={onZoomInClick}
          size={24}
        />
        <ArkIconButton
          className={`${styles.controlsButton} ${isMobile ? styles.controlsButtonMobile : ''}`}
          color='var(--bd-lighter)'
          hoverColor='var(--tx-light)'
          name='zoom-out'
          onClick={onZoomOutClick}
          size={24}
        />
      </ArkResponsive>
      <ArkIconButton
        className={`${styles.controlsButton} ${isMobile ? styles.controlsButtonMobile : ''}`}
        color={isFit ? 'var(--tx-light)' : 'var(--bd-lighter)'}
        hoverColor='var(--tx-light)'
        name='fit'
        onClick={onFitClick}
        size={isMobile ? 16.5 : 20}
      />
    </div>
  )

  return (
    <ReactFlowProvider>
      <div
        className={`${styles.container} ${isLoaded ? styles.containerLoaded : ''} ${isMobile ? styles.containerMobile : ''}`}
        ref={container}
      >
        <ReactFlow
          className={`${styles.reactFlow} ${showFilter ? styles.reactFlowDisabled : ''}`}
          elements={elements}
          maxZoom={1.5}
          minZoom={0.25}
          nodesConnectable={false}
          nodesDraggable={false}
          nodeTypes={{ channel: ChannelNode, empty: EmptyNode, group: GroupNode, program: ProgramNode, user: UserNode }}
          onElementClick={(_event, element) => setSelected(element.id)}
          onLoad={onLoad}
          onMoveStart={() => setIsFit(false)}
          onNodeMouseEnter={onNodeMouseEnter}
          onNodeMouseLeave={() => setFocused(undefined)}
          onPaneClick={() => setSelected(undefined)}
          selectNodesOnDrag={false}
        />
        {topRightControlsComponent}
        {bottomRightControlsComponent}
      </div>
    </ReactFlowProvider>
  )
}

export default NodeGraphView
