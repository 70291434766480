import { Card } from './card'

export type NoticeConditions = {
  version: 1,
  dateRange?: {
    start: string
    end: string
  }
}

export type Notice = {
  id: number
  project_id: number
  createdAt: string
  updatedAt: string

  // permissions
  all_channels: boolean
  channels: number[]

  // details
  enabled: boolean
  name: string;
  description: string;
  dismissible: boolean
  dismissible_admin: boolean
  priority: number | null
  conditions: NoticeConditions | string | null

  // card
  card_id: number | null
  card: Card | null
}

export const DEFAULT_NOTICE: Notice = {
  id: 0,
  project_id: 0,

  createdAt: '1970-01-01T00:00:00.000Z',
  updatedAt: '1970-01-01T00:00:00.000Z',

  // permissions
  all_channels: true,
  channels: [],

  // details
  enabled: true,
  name: '',
  description: '',
  dismissible: true,
  dismissible_admin: true,
  priority: 5,
  conditions: null,

  // card
  card_id: null,
  card: null
}

export const DEFAULT_NOTICE_CONDITIONS: NoticeConditions = {
  version: 1
}
