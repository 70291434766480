import { jsonObject, jsonMember, jsonMapMember, TypedJSON } from 'typedjson'

import { BaseModel } from './base_model'

type UserCompaniesCache = Map<number, UserCompanyCache>
type UserProjectsCache = Map<number, UserProjectCache>

const NODE_GRAPH_SHOW_ALL_PROJECT_USERS_DEFAULT = true
const NODE_GRAPH_SHOW_ORPHANED_CHANNELS_DEFAULT = true
const NODE_GRAPH_SHOW_ORPHANED_GROUPS_DEFAULT = true
const NODE_GRAPH_SHOW_ORPHANED_PROGRAMS_DEFAULT = true
const NODE_GRAPH_SHOW_ORPHANED_USERS_DEFAULT = true
const NODE_GRAPH_SHOW_PROGRAMS_DEFAULT = true
const NODE_GRAPH_SHOW_USERS_DEFAULT = true

@jsonObject
export class NodeGraphOptions extends BaseModel {
  @jsonMember(Boolean)
  showAllProjectUsersGroup: boolean

  @jsonMember(Boolean)
  showOrphanedChannels: boolean

  @jsonMember(Boolean)
  showOrphanedGroups: boolean

  @jsonMember(Boolean)
  showOrphanedPrograms: boolean

  @jsonMember(Boolean)
  showOrphanedUsers: boolean

  @jsonMember(Boolean)
  showPrograms: boolean

  @jsonMember(Boolean)
  showUsers: boolean

  constructor () {
    super()
    this.showAllProjectUsersGroup = NODE_GRAPH_SHOW_ALL_PROJECT_USERS_DEFAULT
    this.showOrphanedChannels = NODE_GRAPH_SHOW_ORPHANED_CHANNELS_DEFAULT
    this.showOrphanedGroups = NODE_GRAPH_SHOW_ORPHANED_GROUPS_DEFAULT
    this.showOrphanedPrograms = NODE_GRAPH_SHOW_ORPHANED_PROGRAMS_DEFAULT
    this.showOrphanedUsers = NODE_GRAPH_SHOW_ORPHANED_USERS_DEFAULT
    this.showPrograms = NODE_GRAPH_SHOW_PROGRAMS_DEFAULT
    this.showUsers = NODE_GRAPH_SHOW_USERS_DEFAULT
  }

  getJSON () : string {
    const serializer = new TypedJSON(NodeGraphOptions)
    return serializer.stringify(this)
  }
}

@jsonObject
export class UserProjectCache extends BaseModel {
  @jsonMember(Number)
  id: number // projectId

  @jsonMember(Number)
  currentChannelId?: number

  @jsonMember(Boolean)
  hideChecklist: boolean

  @jsonMember(NodeGraphOptions)
  nodeGraphOptions: NodeGraphOptions

  @jsonMember(String)
  projectManagerSection?: string

  constructor (id: number, currentChannelId?: number, projectManagerSection?: string) {
    super()
    this.id = id
    this.currentChannelId = currentChannelId
    this.hideChecklist = false
    this.nodeGraphOptions = new NodeGraphOptions()
    this.projectManagerSection = projectManagerSection
  }

  getJSON () : string {
    const serializer = new TypedJSON(UserProjectCache)
    return serializer.stringify(this)
  }
}

@jsonObject
export class UserCompanyCache extends BaseModel {
  @jsonMember(Number)
  id: number // companyId

  @jsonMember(Number)
  currentProjectId?: number

  @jsonMapMember(Number, () => UserProjectCache)
  projectsCache?: UserProjectsCache

  constructor (id: number, currentProjectId?: number) {
    super()
    this.id = id
    this.currentProjectId = currentProjectId
  }

  getJSON () : string {
    const serializer = new TypedJSON(UserCompanyCache)
    return serializer.stringify(this)
  }
}

@jsonObject
export class UserCache extends BaseModel {
  @jsonMember(Number)
  id: number // uid

  @jsonMember(Number)
  currentCompanyId?: number

  @jsonMapMember(Number, () => UserCompanyCache)
  companiesCache?: UserCompaniesCache

  constructor (id: number, currentCompanyId?: number) {
    super()

    this.id = id
    this.currentCompanyId = currentCompanyId
  }

  getJSON () : string {
    const serializer = new TypedJSON(UserCache)
    return serializer.stringify(this)
  }

  static fromJSONString (userId: number, jsonString: string) : UserCache | null {
    const serializer = new TypedJSON(UserCache)
    const object = serializer.parse(jsonString)
    if (object && !object.id) {
      object.id = userId
    }
    return object ?? null
  }

  getSelectedCompanyCache (companyId: number) {
    if (this.companiesCache) {
      const companyCache = this.companiesCache.get(companyId)
      if (companyCache) {
        return companyCache
      }
    }
    return undefined
  }

  setSelectedCompanyProject (companyId: number, projectId?: number) {
    if (!this.companiesCache) {
      this.companiesCache = new Map<number, UserCompanyCache>()
    }
    let companyCache = this.companiesCache.get(companyId)
    if (companyCache) {
      companyCache.currentProjectId = projectId
    } else {
      companyCache = new UserCompanyCache(companyId, projectId)
      this.companiesCache.set(companyId, companyCache)
    }
  }

  getSelectedCompanyProjectId (companyId: number) {
    const companyCache = this.getSelectedCompanyCache(companyId)
    if (companyCache) {
      return companyCache.currentProjectId
    }
    return undefined
  }

  getSelectedCompanyProjectCache (companyId: number, projectId: number) {
    const companyCache = this.getSelectedCompanyCache(companyId)
    if (companyCache && companyCache.projectsCache) {
      return companyCache.projectsCache.get(projectId)
    }
    return undefined
  }

  setSelectedCompanyProjectCache (companyId: number, projectId: number, projectCache: UserProjectCache) {
    const companyCache = this.getSelectedCompanyCache(companyId)
    if (companyCache && companyCache.projectsCache) {
      return companyCache.projectsCache.set(projectId, projectCache)
    }
    return undefined
  }

  setSelectedCompanyProjectChannel (companyId: number, projectId?: number, channelId?: number) {
    if (!projectId || !channelId) {
      return // NB: not currently handling 'deselecting', may want to update this to handle it in the future...
    }
    if (!this.companiesCache || !this.companiesCache.has(companyId)) {
      this.setSelectedCompanyProject(companyId, projectId)
    }
    const companyCache = this.companiesCache?.get(companyId)
    if (companyCache) {
      if (!companyCache.projectsCache) {
        companyCache.projectsCache = new Map<number, UserProjectCache>()
      }
      let projectCache = companyCache.projectsCache.get(projectId)
      if (projectCache) {
        projectCache.currentChannelId = channelId
      } else {
        projectCache = new UserProjectCache(projectId, channelId)
        companyCache.projectsCache.set(projectId, projectCache)
      }
    }
  }

  getSelectedCompanyProjectChannelId (companyId: number, projectId: number) {
    const projectCache = this.getSelectedCompanyProjectCache(companyId, projectId)
    if (projectCache) {
      return projectCache.currentChannelId
    }
    return undefined
  }

  setSelectedCompanyProjectManagerSection (companyId: number, projectId?: number, section?: string) {
    if (!projectId) {
      return
    }
    if (!this.companiesCache || !this.companiesCache.has(companyId)) {
      this.setSelectedCompanyProject(companyId, projectId)
    }
    const companyCache = this.companiesCache?.get(companyId)
    if (companyCache) {
      if (!companyCache.projectsCache) {
        companyCache.projectsCache = new Map<number, UserProjectCache>()
      }
      let projectCache = companyCache.projectsCache.get(projectId)
      if (projectCache) {
        projectCache.projectManagerSection = section
      } else {
        projectCache = new UserProjectCache(projectId, undefined, section)
        companyCache.projectsCache.set(projectId, projectCache)
      }
    }
  }

  getSelectedCompanyProjectManagerSection (companyId: number, projectId: number) {
    const projectCache = this.getSelectedCompanyProjectCache(companyId, projectId)
    if (projectCache) {
      return projectCache.projectManagerSection
    }
    return undefined
  }
}
