/**
 * FilterView
 */

import React, { useContext } from 'react'

import ArkButton from 'src/core/components/ArkButton'
import ArkCheckbox from 'src/core/components/ArkCheckbox'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { NodeGraphContext } from '../NodeGraphProvider'

import styles from './FilterView.module.css'

const FilterView = () => {
  const { actions, store } = useContext(NodeGraphContext)
  const {
    resetFilters,
    setShowAllProjectUsersGroup,
    setShowOrphanedChannels,
    setShowOrphanedGroups,
    setShowOrphanedPrograms,
    setShowOrphanedUsers,
    setShowPrograms,
    setShowUsers
  } = actions
  const {
    showAllProjectUsersGroup,
    showOrphanedChannels,
    showOrphanedGroups,
    showOrphanedPrograms,
    showOrphanedUsers,
    showPrograms,
    showUsers
  } = store

  return (
    <>
      <div>Hide or show:</div>
      <ArkSpacer />
      <ArkCheckbox
        checked={showPrograms}
        label='Programs'
        onChange={(_event, data) => setShowPrograms(!!data.checked)}
        toggle
      />
      <ArkSpacer />
      <ArkCheckbox
        checked={showUsers}
        label='Users'
        onChange={(_event, data) => setShowUsers(!!data.checked)}
        toggle
      />
      <ArkSpacer large />
      <ArkCheckbox
        checked={showOrphanedPrograms}
        disabled={!showPrograms}
        label='Unconnected Programs'
        onChange={(_event, data) => setShowOrphanedPrograms(!!data.checked)}
        toggle
      />
      <ArkSpacer />
      <ArkCheckbox
        checked={showOrphanedChannels}
        label='Unconnected Channels'
        onChange={(_event, data) => setShowOrphanedChannels(!!data.checked)}
        toggle
      />
      <ArkSpacer />
      <ArkCheckbox
        checked={showOrphanedGroups}
        label='Unconnected Groups'
        onChange={(_event, data) => setShowOrphanedGroups(!!data.checked)}
        toggle
      />
      <ArkSpacer />
      <ArkCheckbox
        checked={showOrphanedUsers}
        disabled={!showUsers}
        label='Unconnected Users'
        onChange={(_event, data) => setShowOrphanedUsers(!!data.checked)}
        toggle
      />
      <ArkSpacer large />
      <ArkCheckbox
        checked={showAllProjectUsersGroup}
        label={'\'All Project Users\' group'}
        onChange={(_event, data) => setShowAllProjectUsersGroup(!!data.checked)}
        toggle
      />
      <ArkSpacer large />
      <div className={styles.resetButtonContainer}>
        <ArkButton onClick={resetFilters} size='tiny'>Reset</ArkButton>
      </div>
    </>
  )
}

export default FilterView
