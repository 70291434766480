import ServerAPIClient from './ServerAPIClient'
import { Group, GroupType, Channel, GroupChannelOperation, GroupUserOperation, Program, GroupProgramOperation, ProjectUser, UserProjectRole } from '../models'

class ServerGroupAPI {
  private _apiClient: ServerAPIClient

  constructor (apiClient: ServerAPIClient) {
    this._apiClient = apiClient
  }

  // -------

  // returns project groups only the user has access too
  getProjectGroups = async (companyId: number, projectId: number): Promise<Array<Group> | null> => {
    try {
      const response = await this._apiClient.apiGet('/group', { 'company-id': companyId, 'project-id': projectId })
      // parse all groups from the response into a flat array (ignores sub group mapping at this point)
      const allGroups: Array<Group> = []
      if (response.data && response.data.result && response.data.result) {
        const groupsData = response.data.result
        for (const groupData of groupsData) {
          const group = Group.fromJSON(groupData.id, groupData)
          if (group) {
            allGroups.push(group)
          }
        }
      }
      // sort/order the groups array by name (before we then build the parent/sub tree structure)
      // NB: now also sorts the default group to the top/first
      allGroups.sort((a: Group, b: Group) => {
        if (a.isDefaultGroup) return -1
        if (b.isDefaultGroup) return 1
        // return a.name.localeCompare(b.name)
        // sort/order the groups array by name using a more intelligent natural sort
        return a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true })
      })
      // convert the list of all groups into a tiered tree with each group having a list of their sub groups within them (if any)
      // first add groups to their relevant parent's subGroups array
      for (const group of allGroups) {
        if (group.parentGroupId !== undefined && group.parentGroupId !== null) {
          let parentGroup: Group | null = null
          for (const group2 of allGroups) {
            if (group2.id === group.parentGroupId) {
              parentGroup = group2
              break
            }
          }
          if (parentGroup) {
            if (!parentGroup.subGroups) {
              parentGroup.subGroups = []
            }
            parentGroup.subGroups.push(group)
          }
        }
      }
      // create a return array of just the top level groups (no parent set)
      const tieredGroups: Array<Group> = []
      for (const group of allGroups) {
        if (group.parentGroupId === undefined || group.parentGroupId === null) {
          tieredGroups.push(group)
        }
      }
      return tieredGroups
    } catch (error) {
      console.error('ServerGroupAPI - getProjectGroups - error: ', error)
      throw error
    }
  }

  getProjectGroup = async (companyId: number, projectId: number, groupId: number): Promise<Group | null> => {
    try {
      const response = await this._apiClient.apiGet('/group/' + groupId, { 'company-id': companyId, 'project-id': projectId })
      if (response.data && response.data.result && response.data.result) {
        const groupData = response.data.result
        return Group.fromJSON(groupData.id, groupData)
      }
      return null
    } catch (error) {
      console.error('ServerGroupAPI - getProjectGroup - error: ', error)
      throw error
    }
  }

  addProjectGroup = async (companyId: number, projectId: number, groupName: string, groupType: GroupType = GroupType.std, parentGroupId?: number, desc?: string): Promise<Group | null> => {
    const data: {[key: string]: any} = {
      name: groupName,
      flag_organization_group: groupType === GroupType.org,
      allow_permissions: false // TODO: <<<
    }
    if (parentGroupId) data.parent_group_id = parentGroupId
    if (desc) data.description = desc
    try {
      const response = await this._apiClient.apiPost('/group', data, { 'company-id': companyId, 'project-id': projectId })
      // TODO: also check response status? returns 201 on succcess
      if (response.data && response.data.result && response.data.result) {
        const groupData = response.data.result
        return Group.fromJSON(groupData.id, groupData)
      }
      return null
    } catch (error) {
      console.error('ServerGroupAPI - addProjectGroup - error: ', error)
      throw error
    }
  }

  updateProjectGroup = async (companyId: number, projectId: number, group: Group): Promise<boolean> => {
    const data: {[key: string]: any} = {
      name: group.name
      // NB: you cannot change the groupType (flag_organization_group field) once a group has been created
      // TODO: other options...
    }
    if (group.parentGroupId) {
      data.parent_group_id = group.parentGroupId
    }
    if (group.desc) {
      data.description = group.desc
    }
    try {
      const response = await this._apiClient.apiPut('/group/' + group.id, data, { 'company-id': companyId, 'project-id': projectId })
      // TODO: what will the response be... this may not be correct!! <<<
      if (response.data && response.data.result && response.data.result) {
        // const groupData = response.data.result
        return true
      }
      return false
    } catch (error) {
      console.error('ServerGroupAPI - updateProjectGroup - error: ', error)
      throw error
    }
  }

  deleteProjectGroup = async (companyId: number, projectId: number, groupId: number): Promise<boolean> => {
    try {
      const response = await this._apiClient.apiDelete('/group/' + groupId, undefined, { 'company-id': companyId, 'project-id': projectId })
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerGroupAPI - deleteProjectGroup - error: ', error)
      throw error
    }
  }

  // -------

  getProjectGroupUsers = async (companyId: number, projectId: number, groupId: number): Promise<Array<ProjectUser> | null> => {
    try {
      const response = await this._apiClient.apiGet('/group/' + groupId + '/users', { 'company-id': companyId, 'project-id': projectId })
      const users: Array<ProjectUser> = []
      if (response.data && response.data.result) {
        // TEMP: api v0.3.28+ support - the users array is now nested under a 'users' sub-object
        // TEMP: ..with fallback to the old flat array structure for older api version support (until all api servers are updated with v0.3.28+)
        // TODO: this is in relation to org-groups support, which we fully implement in the web-app #927 branch (currently WIP)
        // TODO: ..once merged that will overwrite & extend this code to support the org-group `flag_org_group` field & the new nested users array structure
        const groupUsersData = response.data.result.users ?? response.data.result
        for (const userData of groupUsersData) {
          const user = ProjectUser.fromJSON(
            userData.user.id,
            {
              ...userData.user,
              ...{ company_role: userData.company_role },
              ...{ company_status: userData.company_status },
              ...{ project_id: projectId, project_owner: userData.owner, project_role: userData.project_role /*, project_access_enabled: userData.enabled ?? false */ }
            }
          )
          if (user) {
            users.push(user)
          }
        }
      }
      // sort/order the users array by project role & then user name (NB: the User name() returns the full name if set, or email as a fallback)
      users.sort((a: ProjectUser, b: ProjectUser) => {
        const aProjectRole = a.projectRole && a.projectRole > UserProjectRole.unknown ? a.projectRole : UserProjectRole.member
        const bProjectRole = b.projectRole && b.projectRole > UserProjectRole.unknown ? b.projectRole : UserProjectRole.member
        // return aRole - bRole || a.name().localeCompare(b.name())
        // sort/order the users array by user name using a more intelligent natural sort
        // return aProjectRole - bProjectRole || a.name().localeCompare(b.name(), navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true })
        // TESTING: now also taking all user roles into account: site admin, org admin, project admin, project manager, & guest roles/types
        // nudge the project role up by +10, then set org admin as 5, & site-admin/god as 1, & guest as 20 to push it to the end
        const aRoleAll = a.isSiteAdmin() ? 1 : (a.isCompanyAdmin() ? 5 : (!a.isGuest ? (aProjectRole + 10) : 20))
        const bRoleAll = b.isSiteAdmin() ? 1 : (b.isCompanyAdmin() ? 5 : (!b.isGuest ? (bProjectRole + 10) : 20))
        return aRoleAll - bRoleAll || a.name().localeCompare(b.name(), navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true })
      })
      return users
    } catch (error) {
      console.error('ServerGroupAPI - getProjectGroupUsers - error: ', error)
      throw error
    }
  }

  // adds & deletes group user mappings
  // returns true if all operations (add/del) went ok, or a map/object of user id's with their error responses if any did (but others may have added ok)
  // NB: although the endpoint supports mapping users across multiple groups, we currently only support working with a single group at a time to keep the logic simpler
  updateProjectGroupUsers = async (companyId: number, projectId: number, groupId: number, operations: Array<GroupUserOperation>): Promise<boolean | Map<number, any>> => {
    const data: {[key: string]: any} = {
      users_group: operations
    }
    try {
      const response = await this._apiClient.apiPost('/group/' + groupId + '/users', data, { 'company-id': companyId, 'project-id': projectId })
      if (response.data && response.data.result && response.data.result) {
        // NB: the results of this endpoint only include entries for any errors, if its not in the response it was actioned ok
        if (response.data.result.usersKo && response.data.result.usersKo.length > 0) {
          const usersGroupsKo = response.data.result.usersKo
          const operationErrors = new Map<number, any>()
          for (const userGroupsKo of usersGroupsKo) {
            operationErrors.set(parseInt(userGroupsKo.user_id), userGroupsKo)
          }
          return operationErrors
        }
      }
      return true
    } catch (error) {
      console.error('ServerGroupAPI - updateProjectGroupUsers - error: ', error)
      throw error
    }
  }

  // -------

  getProjectGroupChannels = async (companyId: number, projectId: number, groupId: number): Promise<Array<Channel> | null> => {
    try {
      const response = await this._apiClient.apiGet('/group/' + groupId + '/channels', { 'company-id': companyId, 'project-id': projectId })
      const channels: Array<Channel> = []
      if (response.data && response.data.result && response.data.result) {
        const groupChannelsData = response.data.result
        for (const channelData of groupChannelsData) {
          const channel = Channel.fromJSON(channelData.id, channelData)
          if (channel) {
            channels.push(channel)
          }
        }
      }
      return channels
    } catch (error) {
      console.error('ServerGroupAPI - getProjectGroupChannels - error: ', error)
      throw error
    }
  }

  // adds & deletes group channel mappings
  // returns true if all operations (add/del) went ok, or a map/object of channel id's with their error responses if any did (but others may have added ok)
  // NB: although the endpoint supports mapping channels across multiple groups, we currently only support working with a single group at a time to keep the logic simpler
  updateProjectGroupChannels = async (companyId: number, projectId: number, groupId: number, operations: Array<GroupChannelOperation>): Promise<boolean | Map<number, any>> => {
    const data: {[key: string]: any} = {
      channels_groups: operations
    }
    try {
      const response = await this._apiClient.apiPost('/group/channels', data, { 'company-id': companyId, 'project-id': projectId })
      if (response.data && response.data.result && response.data.result) {
        // NB: the results of this endpoint only include entries for any errors, if its not in the response it was actioned ok
        if (response.data.result.channelsGroupsKo && response.data.result.channelsGroupsKo.length > 0) {
          const channelsGroupsKo = response.data.result.channelsGroupsKo
          const operationErrors = new Map<number, any>()
          for (const channelGroupsKo of channelsGroupsKo) {
            operationErrors.set(parseInt(channelGroupsKo.channel_id), channelGroupsKo)
          }
          return operationErrors
        }
      }
      return true
    } catch (error) {
      console.error('ServerGroupAPI - updateProjectGroupChannels - error: ', error)
      throw error
    }
  }

  // -------

  getProjectGroupPrograms = async (companyId: number, projectId: number, groupId: number): Promise<Array<Program> | null> => {
    try {
      const response = await this._apiClient.apiGet('/group/' + groupId + '/programs', { 'company-id': companyId, 'project-id': projectId })
      const programs: Array<Program> = []
      if (response.data && response.data.result && response.data.result) {
        const groupProgramsData = response.data.result
        for (const programData of groupProgramsData) {
          const program = Program.fromJSON(programData.id, programData)
          if (program) {
            programs.push(program)
          }
        }
      }
      return programs
    } catch (error) {
      console.error('ServerGroupAPI - getProjectGroupPrograms - error: ', error)
      throw error
    }
  }

  // adds & deletes group program mappings
  // returns true if all operations (add/del) went ok, or a map/object of channel id's with their error responses if any did (but others may have added ok)
  // NB: although the endpoint supports mapping channels across multiple groups, we currently only support working with a single group at a time to keep the logic simpler
  updateProjectGroupPrograms = async (companyId: number, projectId: number, groupId: number, operations: Array<GroupProgramOperation>): Promise<boolean | Map<number, any>> => {
    const data: {[key: string]: any} = {
      programs_groups: operations
    }
    try {
      const response = await this._apiClient.apiPost('/group/programs', data, { 'company-id': companyId, 'project-id': projectId })
      if (response.data && response.data.result && response.data.result) {
        // NB: the results of this endpoint only include entries for any errors, if its not in the response it was actioned ok
        if (response.data.result.programsGroupsKo && response.data.result.programsGroupsKo.length > 0) {
          const programsGroupsKo = response.data.result.programsGroupsKo
          const operationErrors = new Map<number, any>()
          for (const programGroupsKo of programsGroupsKo) {
            operationErrors.set(parseInt(programGroupsKo.program_id), programGroupsKo)
          }
          return operationErrors
        }
      }
      return true
    } catch (error) {
      console.error('ServerGroupAPI - updateProjectGroupPrograms - error: ', error)
      throw error
    }
  }

  // -------
}

export default ServerGroupAPI
