/**
 * utilities
 */

import _ from 'lodash'

import { DEFAULT_GROUP_NAME } from './NodeGraphProvider' // TODO move to config
import {
  NodeGraphChannel,
  NodeGraphData,
  NodeGraphGroup,
  NodeGraphProgram,
  NodeGraphUser,
  EdgeHealth,
  NodeIdPrefix,
  NodeSection,
  NodeType
} from './types'

/**
 * data
 */

export const getIsChannelOrphaned = (channel: NodeGraphChannel, data: NodeGraphData): boolean => {
  const inGroup = _.chain(data.groups)
    .map('channels')
    .flatten()
    .uniq()
    .includes(channel.id)
    .value()
  return !_.some(channel.programs) && !inGroup
}

export const getIsGroupOrphaned = (group: NodeGraphGroup, _data: NodeGraphData): boolean => {
  return !_.some(group.channels) && !_.some(group.users)
}

export const getIsProgramOrphaned = (program: NodeGraphProgram, data: NodeGraphData): boolean => {
  return !_.chain(data.channels)
    .map('programs')
    .flatten()
    .uniq()
    .includes(program.id)
    .value()
}

export const getIsUserOrphaned = (user: NodeGraphUser, data: NodeGraphData, allProjectUsers?: boolean): boolean => {
  const isOrphaned = !_.chain(data.groups)
    .filter(group => !(!allProjectUsers && group.name === DEFAULT_GROUP_NAME))
    .map('users')
    .flatten()
    .uniq()
    .includes(user.id)
    .value()
  return isOrphaned
}

export const getSomeChannelsForDefaultGroup = (data: NodeGraphData): boolean => {
  return _.chain(data.groups)
    .find({ name: DEFAULT_GROUP_NAME })
    .get('channels')
    .some()
    .value()
}

/**
 * numbers
 */

export const getNumberOfOfflineProgramsForChannel = (id: number, data: NodeGraphData) => {
  return _.chain(data.channels)
    .find({ id })
    .get('programs')
    .map(id => _.find(data.programs, { id }))
    .compact()
    .filter(program => !program.isOnline)
    .size()
    .value()
}

export const getNumberOfProgramsForChannel = (id: number, data: NodeGraphData) => {
  return _.chain(data.channels)
    .find({ id })
    .get('programs')
    .size()
    .value()
}

export const getNumberOfChannelsForGroup = (id: number, data: NodeGraphData) => {
  return _.chain(data.groups)
    .find({ id })
    .get('channels')
    .size()
    .value()
}

export const getNumberOfProgramsForGroup = (id: number, data: NodeGraphData) => {
  return _.chain(data.groups)
    .find({ id })
    .get('channels')
    .map(id => _.find(data.channels, { id }))
    .compact()
    .map('programs')
    .flatten()
    .uniq()
    .size()
    .value()
}

export const getNumberOfUsersForGroup = (id: number, data: NodeGraphData) => {
  return _.chain(data.groups)
    .find({ id })
    .get('users')
    .size()
    .value()
}

/**
 * nodes
 */

const getNodeIdWithoutPrefix = (node?: string): number => {
  if (!node) return 0
  node = node.replace(NodeIdPrefix.Channel, '')
  node = node.replace(NodeIdPrefix.Group, '')
  node = node.replace(NodeIdPrefix.Program, '')
  node = node.replace(NodeIdPrefix.User, '')
  return parseInt(node)
}

export const getNodeRankFromSection = (section: NodeSection) => {
  switch (section) {
    case NodeSection.Programs: return 1
    case NodeSection.Channels: return 2
    case NodeSection.Groups: return 3
    case NodeSection.Users: return 4
    default: return 0
  }
}

const getNodeType = (node?: string): NodeType | undefined => {
  if (!node) return undefined
  if (node.includes(NodeIdPrefix.Channel)) return NodeType.Channel
  if (node.includes(NodeIdPrefix.Group)) return NodeType.Group
  if (node.includes(NodeIdPrefix.Program)) return NodeType.Program
  if (node.includes(NodeIdPrefix.User)) return NodeType.User
  return undefined
}

const getAreNodesConnected = (
  { data, source, target }:
  { data: NodeGraphData, source: string, target: string }
): boolean => {
  const sourceId = getNodeIdWithoutPrefix(source)
  const targetId = getNodeIdWithoutPrefix(target)
  const sourceNodeType = getNodeType(source)
  const targetNodeType = getNodeType(target)

  switch (sourceNodeType) {
    case NodeType.Channel:
      switch (targetNodeType) {
        case NodeType.Program: {
          return _.chain(data.channels)
            .find({ id: sourceId })
            .get('programs')
            .includes(targetId)
            .value()
        }
        case NodeType.Group: {
          return _.chain(data.groups)
            .find({ id: targetId })
            .get('channels')
            .includes(sourceId)
            .value()
        }
        case NodeType.User: {
          return _.chain(data.groups)
            .filter(group => _.includes(group.users, targetId))
            .map('channels')
            .flatten()
            .uniq()
            .includes(sourceId)
            .value()
        }
      }
      break

    case NodeType.Group:
      switch (targetNodeType) {
        case NodeType.Program: {
          return _.chain(data.groups)
            .find({ id: sourceId })
            .get('channels')
            .map(id => _.find(data.channels, { id }))
            .compact()
            .map('programs')
            .flatten()
            .uniq()
            .includes(targetId)
            .value()
        }
        case NodeType.Channel: {
          return _.chain(data.groups)
            .find({ id: sourceId })
            .get('channels')
            .includes(targetId)
            .value()
        }
        case NodeType.User: {
          return _.chain(data.groups)
            .find({ id: sourceId })
            .get('users')
            .includes(targetId)
            .value()
        }
      }
      break

    case NodeType.Program:
      switch (targetNodeType) {
        case NodeType.Channel: {
          return _.chain(data.channels)
            .find({ id: targetId })
            .get('programs')
            .includes(sourceId)
            .value()
        }
        case NodeType.Group: {
          return _.chain(data.groups)
            .find({ id: targetId })
            .get('channels')
            .map(id => _.find(data.channels, { id }))
            .compact()
            .map('programs')
            .flatten()
            .uniq()
            .includes(sourceId)
            .value()
        }
        case NodeType.User: {
          const programChannels = _.chain(data.channels)
            .filter(channel => _.includes(channel.programs, sourceId))
            .map('id')
            .value()
          const userChannels = _.chain(data.groups)
            .filter(group => _.includes(group.users, targetId))
            .map('channels')
            .flatten()
            .uniq()
            .value()
          return _.chain(programChannels)
            .intersection(userChannels)
            .some()
            .value()
        }
      }
      break

    case NodeType.User:
      switch (targetNodeType) {
        case NodeType.Program: {
          const programChannels = _.chain(data.channels)
            .filter(channel => _.includes(channel.programs, targetId))
            .map('id')
            .value()
          const userChannels = _.chain(data.groups)
            .filter(group => _.includes(group.users, sourceId))
            .map('channels')
            .flatten()
            .uniq()
            .value()
          return _.chain(programChannels)
            .intersection(userChannels)
            .some()
            .value()
        }
        case NodeType.Channel: {
          return _.chain(data.groups)
            .filter(group => _.includes(group.users, sourceId))
            .map('channels')
            .flatten()
            .uniq()
            .includes(targetId)
            .value()
        }
        case NodeType.Group: {
          return _.chain(data.groups)
            .find({ id: targetId })
            .get('users')
            .includes(sourceId)
            .value()
        }
      }
      break
  }

  return false
}

export const getIsNodeOrphaned = (node: string, data: NodeGraphData): boolean => {
  const id = getNodeIdWithoutPrefix(node)
  const nodeType = getNodeType(node)

  switch (nodeType) {
    case NodeType.Channel: {
      const inGroup = _.chain(data.groups)
        .map('channels')
        .flatten()
        .uniq()
        .includes(id)
        .value()
      const hasPrograms = _.chain(data.channels)
        .find({ id })
        .some('programs')
        .value()
      return !inGroup && !hasPrograms
    }

    case NodeType.Group: {
      const group = _.find(data.groups, { id })
      return !_.some(group?.channels) && !_.some(group?.users)
    }

    case NodeType.Program: {
      return !_.chain(data.channels)
        .map('programs')
        .flatten()
        .uniq()
        .includes(id)
        .value()
    }

    case NodeType.User: {
      return !_.chain(data.groups)
        .map('users')
        .flatten()
        .uniq()
        .includes(id)
        .value()
    }

    default:
      return false
  }
}

export const getIsNodeBlurred = (
  { data, focused, node, selected }:
  {
    data: NodeGraphData
    focused?: string
    node: string
    selected?: string
  }
): boolean => {
  if (selected) {
    if (node === selected) {
      return false
    } else {
      return !getAreNodesConnected({ data, source: selected, target: node })
    }
  } else if (focused) {
    if (node === focused) {
      return false
    } else {
      return !getAreNodesConnected({ data, source: focused, target: node })
    }
  } else if (getIsNodeOrphaned(node, data)) {
    return true
  } else {
    return false
  }
}

/**
 * edges (lines)
 */

export const getEdgeColor = (health: EdgeHealth) => {
  switch (health) {
    case EdgeHealth.Green: return 'var(--green)'
    case EdgeHealth.Amber: return 'var(--yellow)'
    case EdgeHealth.Red: return 'var(--red)'
    case EdgeHealth.Undefined:
    default: return 'var(--bd-light)'
  }
}

export const getIsEdgeAnimated = (health: EdgeHealth) => {
  switch (health) {
    case EdgeHealth.Green:
    case EdgeHealth.Amber:
      return true
    case EdgeHealth.Red:
    case EdgeHealth.Undefined:
    default:
      return false
  }
}

export const getIsEdgeBlurred = (
  { data, focused, selected, source, target }:
  {
    data: NodeGraphData
    focused?: string
    selected?: string
    source: string
    target: string
  }
): boolean => {
  return (
    getIsNodeBlurred({ data, focused, node: source, selected }) ||
    getIsNodeBlurred({ data, focused, node: target, selected }))
}

export const getProgramEdgeHealth = (id: number, data: NodeGraphData): EdgeHealth => {
  const isOnline = !!_.chain(data.programs)
    .find({ id })
    .get('isOnline')
    .value()
  return isOnline ? EdgeHealth.Green : EdgeHealth.Red
}

export const getChannelEdgeHealth = (id: number, data: NodeGraphData): EdgeHealth => {
  const channel = _.find(data.channels, { id })
  if (!channel || !_.some(channel.programs)) return EdgeHealth.Undefined
  if (_.every(channel.programs, id => getProgramEdgeHealth(id, data) === EdgeHealth.Green)) return EdgeHealth.Green
  if (_.some(channel.programs, id => getProgramEdgeHealth(id, data) === EdgeHealth.Green)) return EdgeHealth.Amber
  return EdgeHealth.Red
}
