import React, { useContext } from 'react'
import _ from 'lodash'
import { DropdownItemProps } from 'semantic-ui-react'

import ArkButton from 'src/core/components/ArkButton'
import ArkDropdown from 'src/core/components/ArkDropdown'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { AUTO_SOLO_ENABLED, USER_SELECTION_DROPDOWNS_DESELECT_ENABLED } from 'src/constants/config'
import { OBJECT_CHANNEL_NAME, OBJECT_CHANNEL_NAME_PLURAL } from 'src/constants/strings'
import { useViewer } from 'src/viewer/providers/ViewerProvider'
import { GlobalConfigContext } from 'src/core/providers'

import ChannelAudioToolbar from './ChannelAudioToolbar'
import ChannelLayoutPopup from './ChannelLayoutPopup'
import ChannelOptionsPopup from './ChannelOptionsPopup'

import styles from './ChannelHeader.module.css'

interface ChannelHeaderProps {
  onFullscreenClick: () => void
}

const ChannelHeader = (props: ChannelHeaderProps) => {
  const { onFullscreenClick } = props

  const viewer = useViewer()

  const channelOptions: DropdownItemProps[] = _.map(viewer.channels, channel => (
    {
      text: channel.name,
      value: channel.id
    }
  ))

  const noChannel: boolean = !viewer.channel
  const { store: configStore } = useContext(GlobalConfigContext)
  return (
    <div className={`${styles.header} ${viewer.fullscreen ? styles.fullscreen : ''}`}>
      <ArkDropdown
        className={styles.channelDropdown}
        clearable={USER_SELECTION_DROPDOWNS_DESELECT_ENABLED}
        data-test-id="ark-channel-dropdown" // e2e testing identifier
        noResultsMessage={`No ${OBJECT_CHANNEL_NAME_PLURAL} available`}
        placeholder={`Select ${OBJECT_CHANNEL_NAME}`}
        selection
        options={channelOptions}
        onChange={(_event, data) =>
          data.value ? viewer.selectChannel(data.value as number) : viewer.deselectChannel()
        }
        value={viewer.channel?.id}
      />

      <ArkSpacer grow />
      {AUTO_SOLO_ENABLED && configStore.config.showAutoSoloTopBar && (
        <>
          <ArkButton
            color={viewer.getChannelAutoSolo() ? 'green' : undefined}
            disabled={noChannel}
            data-test-id="ark-channel-solo-btn" // e2e testing identifier
            onClick={() => viewer.setChannelAutoSolo(!viewer.getChannelAutoSolo())}
          >
            AUTO SOLO
          </ArkButton>
          <ArkSpacer />
        </>
      )}
      {viewer.audioStarted
        ? (
          <ArkButton
            color={viewer.getChannelMute() ? 'red' : undefined}
            disabled={noChannel}
            data-test-id="ark-channel-mute-btn" // e2e testing identifier
            onClick={() => viewer.setChannelMute(!viewer.getChannelMute())}
          >
            MUTE
          </ArkButton>
        )
        : (
          <ArkButton
            color='green'
            data-test-id="ark-channel-start-audio-btn" // e2e testing identifier
            onClick={() => viewer.startAudio()}
          >
            START AUDIO
          </ArkButton>
        )}
      <ArkSpacer />
      <ChannelAudioToolbar disabled={noChannel} />
      <ArkSpacer />
      <ArkIconButton
        data-test-id="ark-channel-mixer-btn" // e2e testing identifier
        disabled={noChannel}
        name='mixer'
        onClick={() => viewer.setShowMixer(!viewer.showMixer)}
      />
      <ArkIconButton
        data-test-id="ark-channel-restart-btn" // e2e testing identifier
        disabled={noChannel || viewer.restarting}
        name='restart'
        onClick={viewer.restart}
        size={24}
      />
      <ChannelOptionsPopup disabled={noChannel} />
      <ChannelLayoutPopup disabled={noChannel} />
      <ArkIconButton
        data-test-id="ark-channel-fullscreen-btn" // e2e testing identifier
        name={viewer.fullscreen ? 'fullscreen-off' : 'fullscreen-on'}
        onClick={onFullscreenClick}
      />
    </div>
  )
}

export default ChannelHeader
