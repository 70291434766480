import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { DropdownItemProps } from 'semantic-ui-react'

import { OBJECT_CARD_NAME, OBJECT_CHANNEL_NAME_PLURAL, OBJECT_NOTICE_NAME, SECTION_VIEWER_NAME } from 'src/constants/strings'
import ArkButton from 'src/core/components/ArkButton'
import ArkCheckbox from 'src/core/components/ArkCheckbox'
import ArkDropdown from 'src/core/components/ArkDropdown'
import ArkFieldset from 'src/core/components/ArkFieldset'
import ArkFormLabel from 'src/core/components/ArkForm/ArkFormLabel'
import ArkFormText from 'src/core/components/ArkForm/ArkFormText'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkHint from 'src/core/components/ArkHint'
import ArkInput from 'src/core/components/ArkInput'
import ArkModal from 'src/core/components/ArkModal'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { Card, Channel, Notice } from 'src/core/models'
import { DEFAULT_NOTICE, DEFAULT_NOTICE_CONDITIONS, NoticeConditions } from 'src/core/models/notice'
import { ProjectCardContextValue, useProjectCard } from 'src/core/providers/ProjectCardProvider'
import { ProjectChannelContextValue, useProjectChannel } from 'src/core/providers/ProjectChannelProvider'
import { decodeNoticeConditions } from 'src/core/utilities/notice'

import styles from './NoticeCreateUpdateModal.module.css'

// FIXME use <ArkForm>

interface NoticeCreateUpdateModalProps {
  notice?: Notice
  onClose: () => void
  onSubmit: (props: Partial<Notice>) => void
  open: boolean
}

const NoticeCreateUpdateModal = (props: NoticeCreateUpdateModalProps) => {
  // console.log('NoticeCreateUpdateModal - notice:', props.notice)

  const projectCard: ProjectCardContextValue = useProjectCard()
  const projectChannel: ProjectChannelContextValue = useProjectChannel()

  const [allChannels, setAllChannels] = useState<boolean>(false)
  const [cardId, setCardId] = useState<number>()
  const [channelIds, setChannelIds] = useState<number[]>([])
  const [dateRange, setDateRange] = useState<boolean>(false)
  const [dateRangeStart, setDateRangeStart] = useState<string>('')
  const [dateRangeEnd, setDateRangeEnd] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [dismissible, setDismissible] = useState<boolean>(false)
  const [dismissibleAdmin, setDismissibleAdmin] = useState<boolean>(false)
  const [enabled, setEnabled] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [priority, setPriority] = useState<number>(0)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const cards: Card[] = projectCard.getCards()
  const channels: Channel[] = projectChannel.getChannels()
  const create: boolean = !props.notice

  useEffect(() => {
    if (!props.open) return
    setAllChannels(props.notice ? props.notice.all_channels : DEFAULT_NOTICE.all_channels)
    setCardId(props.notice?.card_id || undefined)
    setChannelIds(props.notice?.all_channels ? [] : props.notice?.channels || [])
    setDescription(props.notice?.description || DEFAULT_NOTICE.description)
    setDismissible(props.notice ? props.notice.dismissible : DEFAULT_NOTICE.dismissible)
    setDismissibleAdmin(props.notice ? props.notice.dismissible_admin : DEFAULT_NOTICE.dismissible_admin)
    setEnabled(props.notice ? props.notice.enabled : DEFAULT_NOTICE.enabled)
    setName(props.notice?.name || DEFAULT_NOTICE.name)
    setPriority(props.notice?.priority || DEFAULT_NOTICE.priority!)
    setSubmitting(false)

    // conditions
    const conditions: NoticeConditions = decodeNoticeConditions(props.notice?.conditions)
    setDateRange(!!conditions.dateRange)

    const date: string = new Date().toISOString().split('T')[0]
    setDateRangeStart(
      conditions.dateRange
        ? conditions.dateRange.start.replace(':00.000', '').replace('Z', '')
        : `${date}T09:00`
    )
    setDateRangeEnd(
      conditions.dateRange
        ? conditions.dateRange.end.replace(':00.000', '').replace('Z', '')
        : `${date}T17:00`
    )
  }, [props.open])

  useEffect(() => {
    if (!cardId) return
    const card: Card = _.find(cards, { id: cardId })!
    setName(`${card.name} ${OBJECT_NOTICE_NAME}`)
  }, [cardId])

  const onSubmit: () => void = () => {
    // console.log('NoticeCreateUpdateModal - onSubmit')
    if (!cardId) {
      window.alert('Invalid card.') // FIXME add toast
      return
    }
    if (!name) {
      window.alert('Invalid name.') // FIXME add toast
      return
    }
    if (
      dateRange &&
      (!dateRangeStart || !dateRangeEnd || new Date(dateRangeEnd).getTime() <= new Date(dateRangeStart).getTime())
    ) {
      window.alert('Invalid date & time range.') // FIXME add toast
      return
    }
    setSubmitting(true)
    const conditions: NoticeConditions = {
      ...DEFAULT_NOTICE_CONDITIONS,
      ...(dateRange && {
        dateRange: {
          start: `${dateRangeStart}:00.000Z`,
          end: `${dateRangeEnd}:00.000Z`
        }
      })
    }
    const newProps: Partial<Notice> = {
      card_id: cardId,
      name,
      description,
      all_channels: allChannels,
      channels: _.orderBy(channelIds, 'name'),
      conditions,
      dismissible,
      dismissible_admin: dismissibleAdmin,
      enabled,
      priority
    }
    // console.log('NoticeCreateUpdateModal - onSubmit - props:', newProps)
    props.onSubmit(newProps)
  }

  const cardOptions: DropdownItemProps[] = _.map(cards, card => ({
    text: card.name,
    value: card.id
  }))

  const channelOptions: DropdownItemProps[] = _.map(channels, channel => ({
    text: channel.name,
    value: channel.id
  }))

  const priorityOptions: DropdownItemProps[] = [
    { text: '1 (highest)', value: 1 },
    { text: '2', value: 2 },
    { text: '3', value: 3 },
    { text: '4', value: 4 },
    { text: '5 (default)', value: 5 },
    { text: '6', value: 6 },
    { text: '7', value: 7 },
    { text: '8', value: 8 },
    { text: '9', value: 9 },
    { text: '10 (lowest)', value: 10 }
  ]

  return (
    <ArkModal onClose={props.onClose} open={props.open}>
      <ArkHeader className={styles.header} as="h2" inverted>
        {`${create ? 'Create' : 'Edit'} ${OBJECT_NOTICE_NAME}`}
      </ArkHeader>
      <ArkFieldset legend={OBJECT_CARD_NAME}>
        <ArkDropdown
          fluid
          onChange={(_event, data) => setCardId(data.value as number)}
          options={cardOptions}
          selection
          placeholder={`Select ${OBJECT_CARD_NAME}…`}
          value={cardId}
        />
      </ArkFieldset>
      <ArkSpacer large />
      <ArkFieldset legend='Details'>
        <ArkInput
          label='Name'
          onChange={(event) => setName(event.target.value)}
          placeholder={`My ${OBJECT_NOTICE_NAME}`}
          required
          value={name}
        />
        <ArkSpacer large />
        <ArkInput
          label='Description'
          onChange={(event) => setDescription(event.target.value)}
          placeholder='My description'
          value={description}
        />
      </ArkFieldset>
      <ArkSpacer large />
      <ArkFieldset legend='Channels'>
        <div>Specify which channels display this notice.</div>
        <ArkSpacer large />
        <ArkCheckbox
          checked={allChannels}
          label={`All ${OBJECT_CHANNEL_NAME_PLURAL}`}
          onChange={() => setAllChannels(!allChannels)}
          toggle
        />
        <ArkSpacer large />
        <ArkFormLabel>{`Specific ${OBJECT_CHANNEL_NAME_PLURAL.toLowerCase()}`}</ArkFormLabel>
        <ArkDropdown
          disabled={allChannels}
          fluid
          multiple
          onChange={(_event, data) => setChannelIds(data.value as number[])}
          options={channelOptions}
          placeholder={`Select ${OBJECT_CHANNEL_NAME_PLURAL.toLowerCase()}`}
          selection
          value={allChannels ? [] : channelIds}
        />
      </ArkFieldset>
      <ArkSpacer large />
      <ArkFieldset legend='Options'>
        <ArkCheckbox
          checked={enabled}
          label='Enabled'
          onChange={() => setEnabled(!enabled)}
          toggle
        />
        <ArkSpacer />
        <div className={styles.checkboxContainer}>
          <ArkCheckbox
            checked={dismissible}
            label='Dismissible by regular users'
            onChange={() => setDismissible(!dismissible)}
            toggle
          />
          <ArkHint
            iconSize={20}
            message={`Whether this ${OBJECT_NOTICE_NAME.toLowerCase()} can be dismissed (closed) by regular users in the ${SECTION_VIEWER_NAME}.`}
            popupPosition='bottom left'
            popupSize='small'
            title='Dismissible by regular users'
            type='info-circle'
          />
        </div>
        <ArkSpacer />
        <div className={styles.checkboxContainer}>
          <ArkCheckbox
            checked={dismissible || dismissibleAdmin}
            disabled={dismissible}
            label='Dismissible by admins & managers'
            onChange={() => setDismissibleAdmin(!dismissibleAdmin)}
            toggle
          />
          <ArkHint
            iconSize={20}
            message={`Whether this ${OBJECT_NOTICE_NAME.toLowerCase()} can be dismissed (closed) by admins & managers in the ${SECTION_VIEWER_NAME}.`}
            popupPosition='bottom left'
            popupSize='small'
            title='Dismissible by admins & managers'
            type='info-circle'
          />
        </div>
        <ArkSpacer large />
        <ArkFormLabel>Priority</ArkFormLabel>
        <ArkDropdown
          fluid
          onChange={(_event, data) => setPriority(data.value as number)}
          options={priorityOptions}
          selection
          value={priority}
        />
        <ArkFormText>Higher priority notices are displayed first.</ArkFormText>
      </ArkFieldset>
      <ArkSpacer large />
      <ArkFieldset legend='Conditions'>
        <div>Specify the conditions under which this notice is displayed.</div>
        <ArkSpacer large />
        <ArkCheckbox
          checked={dateRange}
          label='Date & time range'
          onChange={() => setDateRange(!dateRange)}
          toggle
        />
        {dateRange && (
          <>
            <ArkSpacer large />
            <div className={styles.dateRangeContainer}>
              <ArkInput
                label='Start'
                onChange={(event) => setDateRangeStart(event.target.value)}
                type='datetime-local'
                value={dateRangeStart}
              />
              <ArkSpacer large />
              <ArkInput
                label='End'
                onChange={(event) => setDateRangeEnd(event.target.value)}
                type='datetime-local'
                value={dateRangeEnd}
              />
            </div>
            <ArkFormText>All dates and times are UTC.</ArkFormText>
          </>
        )}
      </ArkFieldset>
      <ArkSpacer large />
      <div className={styles.footer}>
        <ArkButton
          basic
          className={styles.footerButton}
          color='orange'
          onClick={props.onClose}
          size='large'
        >
          Cancel
        </ArkButton>
        <ArkSpacer grow />
        <ArkButton
          basic
          className={styles.footerButton}
          loading={submitting}
          color='blue'
          onClick={onSubmit}
          size='large'
        >
          {create ? 'Create' : 'Save'}
        </ArkButton>
      </div>
    </ArkModal>
  )
}

export default NoticeCreateUpdateModal
