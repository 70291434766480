import React from 'react'
import Avatar from 'react-avatar'

import * as CONFIG from 'src/constants/config'

import { Channel, Group, ProjectUser } from 'src/core/models'

import ProjectGroupUsersPanel from './ProjectGroupUsersPanel'
import ProjectGroupChannelsPanel from './ProjectGroupChannelsPanel'
import ProjectGroupProgramsPanel from './ProjectGroupProgramsPanel'
import GroupDeleteView, { ProjectGroupDeleteCallback } from './GroupDeleteView'

import ArkAvatar from 'src/core/components/ArkAvatar'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkLoader from 'src/core/components/ArkLoader'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { OBJECT_CHANNEL_NAME_PLURAL, OBJECT_USER_NAME_PLURAL, OBJECT_GROUP_NAME } from 'src/constants/strings'

import styles from './ProjectGroupSidebar.module.css'

export type ProjectGroupEditCallback = (group: Group) => void

interface IProps {
  companyId: number
  projectId: number
  group?: Group
  onChange?: Function
  onEdit?: ProjectGroupEditCallback
  onDidDelete?: ProjectGroupDeleteCallback

  loadingUsers: boolean
  groupUsers?: Array<ProjectUser>
  loadingChannels: boolean
  groupChannels?: Array<Channel>
}
interface IState {}

class ProjectGroupSidebar extends React.Component<IProps, IState> {
  render () {
    const { companyId, projectId, group, onChange, onEdit: _onEdit, onDidDelete } = this.props
    if (!group) return (<></>)
    // const isDefaultGroup = group.isDefaultGroup
    const isOrganisationGroup = group.isOrganisationGroup
    // const groupType = isDefaultGroup ? 'Default (Full)' : (isOrganisationGroup ? 'Organisation' : 'Full')

    // FIXME refactor since moving from list item
    const renderGroupUsers = () => {
      const { loadingUsers, groupUsers } = this.props
      const userCount = !loadingUsers ? groupUsers?.length ?? 0 : undefined
      return (
        <div className={styles.groupUsers + ' ' + styles.list}>
          <ArkHeader as='h3' inverted>{OBJECT_USER_NAME_PLURAL}{userCount !== undefined ? ': ' + userCount : ''}</ArkHeader>
          {loadingUsers && <div className={styles.loader}><ArkLoader small inline /></div>}
          {!loadingUsers && groupUsers && groupUsers.length > 0 && (
            <ul>
              {groupUsers.map((user: ProjectUser) => {
                return (
                  <li key={'group_user_' + user.id}>
                    <ArkAvatar
                      type={user.userAvatarType()}
                      name={user.name()}
                      size="30"
                    />
                  </li>
                )
              })}
            </ul>
          )}
          {!loadingUsers && (!groupUsers || groupUsers.length === 0) && (<span>⚠️ No {OBJECT_USER_NAME_PLURAL} assigned to this {OBJECT_GROUP_NAME}</span>)}
        </div>
      )
    }

    const renderGroupChannels = () => {
      const { loadingChannels, groupChannels } = this.props
      const channelCount = !loadingChannels ? groupChannels?.length ?? 0 : undefined
      return (
        <div className={styles.groupChannels + ' ' + styles.list}>
          <ArkHeader as='h3' inverted>{OBJECT_CHANNEL_NAME_PLURAL}{channelCount !== undefined ? ': ' + channelCount : ''}</ArkHeader>
          {loadingChannels && <div className={styles.loader}><ArkLoader small inline /></div>}
          {!loadingChannels && groupChannels && groupChannels.length > 0 && (
            <ul>
              {groupChannels.map((channel: Channel) => {
                return (
                  <li key={'group_channel_' + channel.id}>
                    <Avatar className={styles.channelAvatar} name={channel.name} size="30" textSizeRatio={2.2} round={false} color={channel.colour}/>
                  </li>
                )
              })}
            </ul>
          )}
          {!loadingChannels && (!groupChannels || groupChannels.length === 0) && (<span>⚠️ No {OBJECT_CHANNEL_NAME_PLURAL} assigned to this {OBJECT_GROUP_NAME}</span>)}
        </div>
      )
    }

    const onEdit = () => {
      if (_onEdit && group) _onEdit(group)
    }

    return (
      <>

        <ArkPanel bordered className={styles.sidebarHeader}>
          <ArkPanel.Header className={styles.sidebarTitle} title={group.name} iconName={'group'} />
          <ArkIconButton
            className={styles.menuButton}
            name='settings'
            onClick={onEdit}
            size={24}
          />
        </ArkPanel>

        <ArkSpacer />

        {/* <ArkButton type="button" fluid size="large" onClick={onEdit}>EDIT {OBJECT_GROUP_NAME}</ArkButton> */}

        {/* <ArkPanel bordered>
          <ArkPanel.Properties>
            <ArkPanel.PropertyRow title='ID:' value={group.id} />
            <ArkPanel.PropertyRow title={OBJECT_GROUP_NAME + ' Type:'} value={groupType} />
          </ArkPanel.Properties>
        </ArkPanel> */}

        <ArkSpacer />

        {renderGroupUsers()}

        <ArkSpacer />

        {renderGroupChannels()}

        <ArkSpacer large />

        {!isOrganisationGroup && (
          <>

            <ProjectGroupUsersPanel
              companyId={companyId}
              projectId={projectId}
              group={group}
              onChange={onChange}
            />

            <ArkSpacer />

            <ProjectGroupChannelsPanel
              companyId={companyId}
              projectId={projectId}
              group={group}
              onChange={onChange}
            />

            {/* NB: we don't support group>program mapping in the viewer yet, so hiding this on the live build until we do */}
            {/* UPDATE: commented out for all uses for now, so we can tweak the layout/spacing to work properly for initial lauch */}
            {CONFIG.PROJECT_MGR_GROUP_PROGRAMS_ENABLED && (
              <>
                <ArkSpacer />
                <ProjectGroupProgramsPanel
                  companyId={companyId}
                  projectId={projectId}
                  group={group}
                  onChange={onChange}
                />
              </>
            )}

          </>
        )}

        <ArkSpacer grow />

        <GroupDeleteView
          companyId={companyId}
          projectId={projectId}
          group={group}
          onDidDelete={onDidDelete}
        />

      </>
    )
  }
}

export default ProjectGroupSidebar
