/**
 * ProjectProgramListItem
 */

import React from 'react'

import { Program, Project, UserProject } from 'src/core/models'

import { DEFAULT_PICKER_COLOR } from 'src/constants/config'

import ArkAvatar from 'src/core/components/ArkAvatar'
import ArkHint, { HintType, PopupPosition, PopupSize } from 'src/core/components/ArkHint'
import ArkIcon from 'src/core/components/ArkIcon'
import ArkListItem, { ArkListItemDetail } from 'src/core/components/ArkListItem'
import ArkSpacer from 'src/core/components/ArkSpacer'

import ProjectProgramHotlinkIcon from './ProjectProgramHotlinkIcon'

import styles from './ProjectProgramListItem.module.css'

interface ProjectProgramListItemProps {
  companyId: number
  project: UserProject | Project
  program: Program
  active: boolean
  activeProgram?: Program // for automatically closing input url - TODO: port/re-implement? (not supported since the refactor)
  filter?: string
  serverProgramConfigVersion?: number // the current program config version the server uses (so we can check if the program is older & can/should be re-created/updated)
  onClick: () => void
  onEditClick?: () => void
}

const ProjectProgramListItem = (props: ProjectProgramListItemProps) => {
  const {
    project,
    program,
    active,
    // activeProgram,
    filter,
    serverProgramConfigVersion,
    onClick,
    onEditClick
  } = props

  const programUpdateAvailable = program.shouldUpdateConfigVersion(serverProgramConfigVersion)

  const leftComponent = (
    <ArkAvatar
      className={styles.avatar}
      color={program.colour || DEFAULT_PICKER_COLOR}
      initials={program.shortNameCapitalised()}
      maxInitials={3}
      name={program.name}
      round={false}
      size='40'
      filter={filter}
      filterHighlight={true}
    />
  )

  const rightComponentDesktop = (
    <>
      {/**
        * NB: no longer showing the input url view inline here, relying on the sidebar instead
        * TODO: if re-enabling this, we need to consider how to show both the input & output urls inline (not just one or the other)
        * TODO: & also need to consider how to show or at least hint at being able to view the individual fields like we can in the sidebar
        * TODO: removed the ProjectProgramURLView component originally used here, see the newer more generic ArkPreviewCopyView one instead
        * TODO: ..ProjectProgramURLView supported a selectedProgramId prop which was used to hide the popup if it was visible while the selection changed, would need to re-implement similar in ArkPreviewCopyView?
        **/}
      {/* <ArkListItemDetail horizontal title='Input URL:'>
        <ProjectProgramURLView program={program} selectedProgramId={activeProgram?.id} />
      </ArkListItemDetail> */}
      <ArkSpacer />
      <ArkListItemDetail>
        <div className={`${styles.status} ${program.isOnline ? styles.online : styles.offline}`}>
          {program.isOnline ? '⊛ ONLINE' : '⊘ OFFLINE'}
        </div>
        {programUpdateAvailable && (
          <div className={styles.programUpdateAvailableNotice}>
            <div className={styles.warningIcon}><ArkIcon name='warning' size={22} color={'#f3be0e'} /></div>
            <div className={styles.warningText}>Update Available</div>
          </div>
        )}
      </ArkListItemDetail>
    </>
  )

  const hintWarningElement = (hintType: HintType, iconSize: number, popupSize: PopupSize, title: string, message: string | React.ReactNode, triggerText?: string | React.ReactNode, popupPosition?: PopupPosition, disabled?: boolean, otherProps?: {[key: string] : any}) => {
    return (
      <ArkHint
        className={styles.programWarningHint}
        type={hintType}
        iconSize={iconSize}
        popupSize={popupSize}
        title={
          <div className={styles.programWarningHintTitle}>
            <ArkIcon name={'warning'} size={iconSize} color={'#f3be0e'} className={styles.programWarningHintTitleIcon} />
            {title}
          </div>
        }
        message={message}
        triggerText={triggerText} // shown next to the trigger icon
        popupPosition={popupPosition}
        disabled={disabled}
        color={'#f3be0e'}
        // open={true} // DEBUG ONLY
        {...otherProps}
      ></ArkHint>
    )
  }

  // NB: the api now returns the srt latency with both the input & output fields BUT both are the same value (& we submit as a single value when editing)
  // NB: ..so we just grab the value off one of the srt input/output fields (shouldn't matter which)
  const srtInputData = program?.getSRTInputData()
  const srtLatency = srtInputData?.extraFields?.latency ? srtInputData?.extraFields?.latency as number : undefined // NB: was: `program?.srtLatency`

  const showAudioOnlyNoProtocolWarning = (program.isAudioOnly && project.transcoderSettings !== undefined && !project.transcoderSettings.hasAudioOnlyProtocolsEnabled())
  const showHotlinkEnabledWarning = program.hotlinkEnabled && program.hotlinkProtocols && program.hotlinkProtocols.length > 0
  const latencyTxt = srtLatency !== undefined ? `SRT Latency: ${srtLatency}ms | ` : ''
  const programIdTxt = `ID: ${program.id}`
  const desc = `${latencyTxt}${programIdTxt}`
  const descWithHotlinkStatus = showHotlinkEnabledWarning
    ? <>{desc} | <ProjectProgramHotlinkIcon program={program} triggerClassName={styles.programHotlinkBtn} popupClassName={styles.programHotlinkPopup} contentClassName={styles.programHotlinkPopupContent} titleClassName={styles.programHotlinkPopupTitle} /></>
    : <>{desc}</>
  const descNoFilter = (showAudioOnlyNoProtocolWarning
    ? <>{descWithHotlinkStatus} | {hintWarningElement('exclamation-triangle', 18, 'small', 'Audio Warning', 'Audio only program without audio only protocol set.', 'Audio Warning')}</>
    : <>{descWithHotlinkStatus}</>)
  return (
    <ArkListItem
      active={active}
      // description={desc} // NB: the current project programs filtering doesn't include any fields used here (& we need to be careful if we enable it & it only includes some fields, as the text match display will blindly match all fields regardless, so those fields should be moved to the `descriptionNoFilter` value instead)
      descriptionNoFilter={descNoFilter}
      filter={filter}
      leftComponent={leftComponent}
      onClick={onClick}
      onEditClick={onEditClick}
      rightComponentDesktop={rightComponentDesktop}
      title={program.name}
    />
  )
}

export default ProjectProgramListItem
