import React, { useContext, useEffect, useRef, useState } from 'react'

import * as ROUTES from 'src/constants/routes'
import { UserContext, ProjectAdminContext } from 'src/core/providers'

import { ProjectUser, UserCompanyStatus } from 'src/core/models'

import ArkProjectManagerPage from 'src/manager/project/components/ArkProjectManagerPage/ArkProjectManagerPage'
import ProjectUserSidebar from './ProjectUserSidebar'
import ProjectUserForm from './ProjectAddUserForm'
import ProjectUserListItem from './ProjectUserListItem'
import ProjectGuestForm from './ProjectGuestForm'
import ProjectGuestLinkConstructorView from './ProjectGuestLinkConstructorView'

import ArkProjectStatusBanner from 'src/core/components/ArkProjectStatusBanner'
import ArkManagerContentView from 'src/core/components/ArkManagerContentView/ArkManagerContentView'
import ArkManagerListView, { ArkManagerFilteredItem, SectionSchema } from 'src/core/components/ArkManagerListView/ArkManagerListView'
import ArkManagerFilterForm from 'src/core/components/ArkManagerListView/ArkManagerFilterForm'
import ArkModal from 'src/core/components/ArkModal'

import { OBJECT_PROJECT_NAME, OBJECT_TEAM_NAME, SECTION_MANAGER_SUFFIX_NAME } from 'src/constants/strings'
import { PROJECT_GUEST_EDIT_ENABLED } from 'src/constants/config'

interface IProps {}

const ProjectUsersPage = (_props: IProps) => {
  const mounted = useRef(false)

  const { actions: userActions, store: userStore } = useContext(UserContext)
  const { actions: projectAdminActions } = useContext(ProjectAdminContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [users, setUsers] = useState<Array<ProjectUser>>([])
  const [filteredUsers, setFilteredUsers] = useState<Array<ArkManagerFilteredItem<ProjectUser>> | undefined>(undefined)
  const [filter, setFilter] = useState<string | undefined>(undefined)
  const [selectedUser, setSelectedUser] = useState<ProjectUser | undefined>(undefined)
  const [showUserFormModal, setShowUserFormModal] = useState<boolean>(false)
  const [showGuestFormModal, setShowGuestFormModal] = useState<boolean>(false)
  const [showGuestLinkModalForUser, setShowGuestLinkModalForUser] = useState<ProjectUser | undefined>(undefined)
  const [editUser, setEditUser] = useState<ProjectUser | undefined>(undefined) // NB: currently only supporting this for guest users & only if the editing user is a site-admin/god

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const selectUser = (user?: ProjectUser) => {
    setSelectedUser(user)
  }

  // -------

  const filterUsers = (_filter: string) => {
    if (loading) return
    const filter = _filter.length > 0 ? _filter : undefined
    const filteredUsers = filter
      ? users.reduce<Array<ArkManagerFilteredItem<ProjectUser>>>((r, user) => {
        let nameMatch = false
        let emailMatch = false
        if (user.name().toLowerCase().includes(filter.toLowerCase())) {
          nameMatch = true
        }
        if (!user.isGuest && user.email?.toLowerCase().includes(filter.toLowerCase())) {
          emailMatch = true
        }
        // guest description filtering support
        if (user.isGuest && user.lastName && user.lastName.toLowerCase().includes(filter.toLowerCase())) {
          nameMatch = true // NB: guest users user the `lastName` field as a desc but we still treat it as a name match for filtering (to avoid having to add a dedicated type to the filtering just for these shared guests & extend various areas to handle it)
        }
        if (nameMatch || emailMatch) {
          const matchingFields: Array<string> = []
          if (nameMatch) matchingFields.push('name')
          if (emailMatch) matchingFields.push('email')
          const filteredUser: ArkManagerFilteredItem<ProjectUser> = {
            item: user,
            matchingFields
          }
          r.push(filteredUser)
        }
        return r
      }, [] as Array<ArkManagerFilteredItem<ProjectUser>>)
      : undefined
    if (selectedUser && (!(filteredUsers?.find((filteredUser) => filteredUser.item.id === selectedUser.id)))) {
      selectUser(undefined) // if a user was selected but isn't in the filtered list deselect them
    }
    setFilter(filter)
    setFilteredUsers(filteredUsers)
  }

  // const clearFilteredUsers = () => {
  //   setFilter(undefined)
  //   setFilteredUsers(undefined)
  // }

  // -------

  const loadProjectUsers = async () => {
    if (loading === true) return false
    const company = userStore.selectedCompany
    const project = userStore.selectedProject
    if (company && project) {
      try {
        setLoading(true)
        const users = await projectAdminActions.getProjectUsers(company.id, project.id)
        console.log('ProjectUsersPage - loadProjectUsers - users: ', users)
        if (mounted.current) {
          setLoading(false)
          setUsers(users || [])
          setFilteredUsers(undefined)
          // update the selected user if one is currently set (which in turn triggers the sidepanel to update with the refreshed user object)
          if (selectedUser && users) {
            const newUser = users.find((user) => user.id === selectedUser?.id)
            setSelectedUser(newUser)
          }
        }
        if (mounted.current && filter) filterUsers(filter) // re-filter if it was active
      } catch (error) {
        console.error('ProjectUsersPage - loadProjectUsers - error: ', error)
        // TODO: add an error prop & display an error message if this happens
        if (mounted.current) {
          setLoading(false)
          setUsers([])
          setFilteredUsers(undefined)
        }
      }
    }
  }

  const updateProjectUser = (updatedUser: ProjectUser) => {
    // update our users cache with the supplied user object (instead of needing to re-query the api for it)
    if (users) {
      const userIndex = users.findIndex((u: ProjectUser) => u.id === updatedUser.id)
      if (userIndex >= 0) {
        const _users = [...users] // make a shallow copy
        _users[userIndex] = updatedUser
        setUsers(_users)
      }
    }
    // trigger the sidepanel to update with the updated user object
    if (selectedUser && users) {
      const newUser = users.find((user) => user.id === selectedUser?.id)
      setSelectedUser(newUser)
    }
  }

  // -------

  const onDeleteUser = (user: ProjectUser) => {
    console.log('ProjectUsersPage - onDeleteUser - user: ', user)
    /*
    // NB: this seemed to stop working for some reason, flipped to just reloading the data from the api like other pages for now instead
    // remove the deleted user from the user array (instead of making a new api call)
    const users = this.state.users.filter((user) => { return user.id !== userId })
    console.log('ProjectUsersPage - onDeleteUser - users(BEFORE): ', this.state.users.length, ' = ', this.state.users)
    console.log('ProjectUsersPage - onDeleteUser - users(AFTER): ', users.length, ' = ', users)
    this.setState({ users })
    */
    // trigger a programs data re-load so the deleted project no longer shows
    loadProjectUsers()
    selectUser(undefined)
  }

  // -------

  useEffect(() => {
    loadProjectUsers()
  }, [])

  // -------

  const renderUserFilterForm = () => {
    return (
      <ArkManagerFilterForm
        autoComplete={false}
        filterTitle='Filter by name or email'
        filterValue={filter ?? ''}
        onFilterChange={(filter: string) => {
          filterUsers(filter)
        }}
      />
    )
  }

  // -------

  const renderUserTableRowContent = (user: ProjectUser, isSelected: boolean, filter?: string) => {
    const company = userStore.selectedCompany
    const project = userStore.selectedProject
    if (!company || !project) return null
    return (
      <ProjectUserListItem
        active={isSelected}
        companyId={company.id}
        filter={filter}
        key={user.id}
        onClick={() => selectUser(user)}
        onUserUpdated={updateProjectUser}
        projectId={project.id}
        user={user}
      />
    )
  }

  // -------

  const showAddUserModal = () => {
    setShowUserFormModal(true)
  }

  // NB: not currently supporting editing of users via the user form (as thats for invites)
  // showEditUserModal = (user: ProjectUser) => {
  //   console.log('ProjectUsersPage - showEditUserModal - user: ', user.id)
  //   this.setState({ showUserFormModal: true }) //, editUser: user })
  // }

  const hideUserModal = () => {
    setShowUserFormModal(false)
  }

  const renderAddUserForm = () => {
    const company = userStore.selectedCompany
    const project = userStore.selectedProject
    if (!company || !project) return null
    return (
      <ProjectUserForm
        companyId={company.id}
        projectId={project.id}
        projectUsers={users}
        onCancel={() => { hideUserModal() }}
        onSave={() => {
          if (selectedUser) setSelectedUser(undefined) // clear the selected user if one was previously selected
          // trigger a company users data re-load to show the newly added project
          loadProjectUsers()
          // NB: we don't auto close/hide the modal form when it saves, leave it up for a success message to show & the user to dismiss manually
        }}
        onClose={() => { hideUserModal() }}
        insideModal={true}
      />
    )
  }

  const renderAddUserModal = () => {
    return (
      <ArkModal open={showUserFormModal} onClose={() => hideUserModal()}>
        {renderAddUserForm()}
      </ArkModal>
    )
  }

  // -------

  const showAddGuestModal = () => {
    // const isSiteAdmin = this.props.userContext.store.user?.isSiteAdmin() ?? false
    // if (!isSiteAdmin) return
    const isProjectAdminOrHigher = userActions.isProjectAdminOrHigher()
    if (!isProjectAdminOrHigher) return
    setShowGuestFormModal(true)
  }

  const showEditGuestModal = (user: ProjectUser) => {
    if (!PROJECT_GUEST_EDIT_ENABLED) return
    setShowGuestFormModal(true)
    setEditUser(user)
  }

  const hideAddGuestModal = () => {
    // NB: DON'T clear/reset `editUser` here, see `didHideAddGuestModal` which is called once the modal has actually closed & we can safely reset it then
    // NB: this stops a saved edit form flipping the 'updated' success text to 'created' briefly while the modal closes if we reset it straight away here
    setShowGuestFormModal(false)
  }

  // triggered via modal callbacks once the modal has already closed
  const didHideAddGuestModal = () => {
    setShowGuestFormModal(false)
    setEditUser(undefined)
  }

  const renderAddGuestForm = () => {
    const company = userStore.selectedCompany
    const project = userStore.selectedProject
    if (!company || !project) return null
    // const isSiteAdmin = this.props.userContext.store.user?.isSiteAdmin() ?? false
    // if (!isSiteAdmin) return null
    const isProjectAdminOrHigher = userActions.isProjectAdminOrHigher()
    if (!isProjectAdminOrHigher) return
    return (
      <ProjectGuestForm
        companyId={company.id}
        projectId={project.id}
        projectUsers={users}
        user={editUser}
        onCancel={() => { hideAddGuestModal() }}
        onSave={() => {
          if (selectedUser) setSelectedUser(undefined) // clear the selected user if one was previously selected
          // trigger a company users data re-load to show the newly added project
          loadProjectUsers()
          // NB: we don't auto close/hide the modal form when it saves, leave it up for a success message to show & the user to dismiss manually
        }}
        onClose={() => { hideAddGuestModal() }}
        insideModal={true}
      />
    )
  }

  const renderAddGuestModal = () => {
    return (
      <ArkModal open={showGuestFormModal} onClose={() => didHideAddGuestModal()}>
        {renderAddGuestForm()}
      </ArkModal>
    )
  }

  // -------

  const onEditUser = (user: ProjectUser) => {
    // NB: ONLY supporting editing of guest users for now (& only site-admins/god can trigger it currently)
    if (user.isGuest) showEditGuestModal(user)
  }

  // -------

  const showGuestLinkModal = (user: ProjectUser) => {
    if (!user.isGuest) return
    setShowGuestLinkModalForUser(user)
  }

  const hideGuestLinkModal = () => {
    setShowGuestLinkModalForUser(undefined)
  }

  const renderGuestLinkForm = () => {
    const company = userStore.selectedCompany
    const project = userStore.selectedProject
    const user = showGuestLinkModalForUser
    if (!company || !project || !user) return null
    return (<ProjectGuestLinkConstructorView companyId={company.id} projectId={project.id} user={user} />)
  }

  const renderGuestLinkModal = () => {
    return (
      <ArkModal open={showGuestLinkModalForUser !== undefined} onClose={() => hideGuestLinkModal()}>
        {renderGuestLinkForm()}
      </ArkModal>
    )
  }

  // -------

  const company = userStore.selectedCompany
  const project = userStore.selectedProject
  if (!company || !project) return null

  // const isSiteAdmin = this.props.userContext.store.user?.isSiteAdmin() ?? false
  const isProjectAdminOrHigher = userActions.isProjectAdminOrHigher()

  // TESTING: calculate tallies to display for each section e.g: `Admin/Managers (10/14 registered | 13/14 enabled | 2 RePro Admin)`
  const userSectionKeys = ['admin', 'member', 'guest']
  type SectionTallies = { total: number, registered: number, enabled: number, siteAdmins: number }
  const userSectionTallies: Map<string, SectionTallies> = new Map()
  for (const userSectionKey of userSectionKeys) {
    userSectionTallies.set(userSectionKey, { total: 0, registered: 0, enabled: 0, siteAdmins: 0 })
  }
  const _users = filteredUsers ?? users
  for (const _user of _users) {
    const user = _user instanceof ProjectUser ? _user : _user.item
    const isCompanyAdmin = user.isCompanyAdmin()
    const isProjectAdminOrManager = user.isProjectAdminOrManager()
    const isGuestUser = user.isGuest
    const isAdminUser = isCompanyAdmin || isProjectAdminOrManager
    const isMember = !isAdminUser && !isGuestUser
    if (isGuestUser || isAdminUser || isMember) {
      const sectionTallies = userSectionTallies.get((isAdminUser ? 'admin' : (isGuestUser ? 'guest' : 'member')))
      if (sectionTallies) {
        sectionTallies.total++
        if (user.companyStatus === UserCompanyStatus.active) sectionTallies.registered++
        if (user.projectAccessEnabled) sectionTallies.enabled++
        if (user.isSiteAdmin()) sectionTallies.siteAdmins++
      }
    }
  }
  const formatSectionTallies = (tallies: SectionTallies, isGuest: boolean = false) => {
    const total = tallies.total
    return '(' +
      `${tallies.registered}/${total} ${isGuest ? 'created' : 'registered'} | ${tallies.enabled}/${total} enabled` +
      (tallies.siteAdmins > 0 ? ` | ${tallies.siteAdmins} RePro Admin` : '') +
      ')'
  }

  const userSections: Array<SectionSchema> = [
    { key: 'admin', title: 'Admins/Managers', collapsible: true, desc: formatSectionTallies(userSectionTallies.get('admin')!) },
    { key: 'member', title: 'Members', collapsible: true, desc: formatSectionTallies(userSectionTallies.get('member')!) },
    { key: 'guest', title: 'Guest Access', collapsible: true, desc: formatSectionTallies(userSectionTallies.get('guest')!, true) }
  ]

  return (
    <ArkProjectManagerPage
      onRightSidebarClose={() => setSelectedUser(undefined)}
      rightSidebar={selectedUser &&
        <ProjectUserSidebar
          companyId={company.id}
          projectId={project.id}
          user={selectedUser}
          onDeleteUser={onDeleteUser}
          onEditUser={onEditUser}
          onChange={async () => {
            // trigger a project users data re-load so the updated user values show
            // NB: also updates the selectedUser & so in turn updates the sidebar user ref
            await loadProjectUsers()
          }}
          onChangeUser={async (updatedUser: ProjectUser) => {
            updateProjectUser(updatedUser)
          }}
          onGuestLinkConfigurator={(user: ProjectUser) => {
            showGuestLinkModal(user)
          }}
        />
      }
    >
      <ArkManagerContentView
        title={OBJECT_PROJECT_NAME + ' ' + 'access'}
        breadcrumb={[{
          path: ROUTES.getProjectRoute(ROUTES.PROJECT_MANAGER_VIEW, project.id),
          title: ROUTES.formatBreadcrumbRootTitle(project.name, `${OBJECT_PROJECT_NAME} ${SECTION_MANAGER_SUFFIX_NAME}`)
        }]}
      >
        <ArkProjectStatusBanner />
        <ArkManagerListView
          loading={loading}
          items={users}
          sections={userSections}
          sectionItemCheck={(section: SectionSchema, user: ProjectUser) => {
            const isGuest = user.isGuest
            const isCompanyAdmin = user.isCompanyAdmin()
            const isProjectAdminOrManager = user.isProjectAdminOrManager()
            switch (section.key) {
              case 'admin': return isCompanyAdmin || isProjectAdminOrManager
              case 'member': return (!isGuest && !isCompanyAdmin && !isProjectAdminOrManager)
              case 'guest': return isGuest
            }
            return false
          }}
          selectedItem={selectedUser}
          itemRow={(user: ProjectUser, isSelected: boolean) => {
            return renderUserTableRowContent(user, isSelected, filter)
          }}
          // topbar={this.topBarContent}
          topbarAddItemTitle={'ADD ' + OBJECT_TEAM_NAME}
          onAdd={() => showAddUserModal()}
          otherButtons={isProjectAdminOrHigher ? [{ title: 'Add Guest', onClick: showAddGuestModal }] : undefined}
          // FILTERING:
          filter={filter}
          filteredItems={filteredUsers}
          filterForm={renderUserFilterForm()}
          onClearFilter={() => filterUsers('') }
        />
        {renderAddUserModal()}
        {isProjectAdminOrHigher && renderAddGuestModal()}
        {renderGuestLinkModal()}
      </ArkManagerContentView>
    </ArkProjectManagerPage>
  )
}

export default ProjectUsersPage
