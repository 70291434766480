import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'

import { MOCK_API_ENABLED } from 'src/constants/config'
import { Card, Image, Notice } from 'src/core/models'
import { delay } from 'src/core/utilities/delay'

import { MOCK_CARDS, MOCK_IMAGES, MOCK_NOTICES } from './data'

const API_DELAY: number = 2000 // 2 seconds

export type MockAPIContextValue = {
  getCards: () => Promise<Card[]>
  getImages: () => Promise<Image[]>
  getNotices: () => Promise<Notice[]>
  setCards: (cards: Card[]) => Promise<void>
  setImages: (images: Image[]) => Promise<void>
  setNotices: (cards: Notice[]) => Promise<void>
}

const MockAPIContext = createContext<MockAPIContextValue>({} as MockAPIContextValue)

export const useMockAPI = () => useContext(MockAPIContext)

type MockAPIProviderProps = {
  children: ReactNode
}

const MockAPIProvider = (props: MockAPIProviderProps) => {
  if (!MOCK_API_ENABLED) return <>{props.children}</>

  const [_cards, _setCards] = useState<Card[]>(MOCK_CARDS)
  const [_images, _setImages] = useState<Image[]>(MOCK_IMAGES)
  const [_notices, _setNotices] = useState<Notice[]>(MOCK_NOTICES)

  useEffect(() => {
    // console.log('MockAPIProvider - load')
    return () => {
      // console.log('MockAPIProvider - unload')
    }
  }, [])

  const getCards = async (): Promise<Card[]> => {
    // console.log('MockAPIProvider - getCards')
    await delay(API_DELAY)
    return _cards
  }

  const getImages = async (): Promise<Image[]> => {
    // console.log('MockAPIProvider - getImages')
    await delay(API_DELAY)
    return _images
  }

  const getNotices = async (): Promise<Notice[]> => {
    // console.log('MockAPIProvider - getNotices')
    await delay(API_DELAY)
    return _notices
  }

  const setCards = async (cards: Card[]): Promise<void> => {
    // console.log('MockAPIProvider - setCards - cards:', cards)
    await delay(API_DELAY)
    _setCards(cards)
  }

  const setImages = async (images: Image[]): Promise<void> => {
    // console.log('MockAPIProvider - setImages - images:', images)
    await delay(API_DELAY)
    _setImages(images)
  }

  const setNotices = async (notices: Notice[]): Promise<void> => {
    // console.log('MockAPIProvider - setNotices - notices:', notices)
    await delay(API_DELAY)
    _setNotices(notices)
  }

  return (
    <MockAPIContext.Provider value={{
      getCards,
      getImages,
      getNotices,
      setCards,
      setImages,
      setNotices
    }}>
      {props.children}
    </MockAPIContext.Provider>
  )
}

export default MockAPIProvider
