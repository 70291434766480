import { ProjectAddGuestUser, ProjectUser } from '../models'
import ServerAPIClient from './ServerAPIClient'
import { ServerError } from './ServerAPIErrors'

class ServerGuestAPI {
  private _apiClient: ServerAPIClient

  constructor (apiClient: ServerAPIClient) {
    this._apiClient = apiClient
  }

  // -------

  addSharedGuestUserToProject = async (companyId: number, projectId: number, guestUser: ProjectAddGuestUser): Promise<ProjectUser> => {
    const data: {[key: string]: any} = {}
    if (guestUser.name) data.name = guestUser.name
    if (guestUser.desc) data.last_name = guestUser.desc
    try {
      const response = await this._apiClient.apiPost('/auth/guest', data, { 'company-id': companyId, 'project-id': projectId })
      if (response.status === 201 && response.data.result) {
        const guestUserData = response.data.result
        // NB: merging in the additional company_role, company_status, & project_role fields into the main user json so its all parses in one
        const guestUser = ProjectUser.fromJSON(guestUserData.user.id ?? 0, {
          ...guestUserData.user,
          ...{ company_role: guestUserData.company_role },
          ...{ company_status: guestUserData.company_status },
          ...{ project_id: projectId, project_owner: guestUserData.owner, project_role: guestUserData.project_role, project_access_enabled: guestUserData.enabled ?? false }
        })
        if (guestUser) return guestUser
        throw new Error('Failed to parse guest user data')
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerGuestAPI - addSharedGuestUserToProject - error: ', error)
      throw error
    }
  }

  updateSharedGuestUser = async (companyId: number, projectId: number, userId: number, name?: string, desc?: string): Promise<ProjectUser> => {
    console.log('ServerGuestAPI - updateSharedGuestUser - companyId:', companyId, ' projectId:', projectId, ' userId:', userId, ' name:', name, ' desc:', desc)
    try {
      // NB: the api returns the full user object, but for now we just check the response status & return true/false (as the auth api handles the rest of the user object updates currently)
      const data: {[key: string]: any} = {}
      if (name !== undefined) data.name = name
      if (desc !== undefined) data.last_name = desc
      const response = await this._apiClient.apiPut('/auth/guest?user_id=' + userId, data, { 'company-id': companyId, 'project-id': projectId })
      console.log('ServerGuestAPI - updateSharedGuestUser - response: ', response)
      if (response.status === 200 && response.data.result) {
        const guestUserData = response.data.result
        const guestUser = ProjectUser.fromJSON(guestUserData.id ?? 0, {
          ...guestUserData.user,
          ...{ company_role: guestUserData.company_role },
          ...{ company_status: guestUserData.company_status },
          ...{ project_id: projectId, project_owner: guestUserData.owner, project_role: guestUserData.project_role, project_access_enabled: guestUserData.enabled ?? false }
        })
        if (guestUser) return guestUser
        throw new Error('Failed to parse guest user data')
      } else {
        console.log('ServerGuestAPI - updateSharedGuestUser - WARNING: UNEXPECTED STATUS CODE: ', response.status)
        throw new ServerError('Failed to update guest (invalid response)')
      }
    } catch (error) {
      console.error('ServerGuestAPI - updateSharedGuestUser - error: ', error)
      throw error
    }
  }

  // NB: as shared guest users are project specific this actually deletes the guest user completely
  removeSharedGuestUserFromProject = async (companyId: number, projectId: number, userId: number): Promise<boolean> => {
    try {
      const response = await this._apiClient.apiDelete('/auth/guest?user_id=' + userId, undefined, { 'company-id': companyId, 'project-id': projectId })
      return (response.status === 200)
    } catch (error) {
      console.error('ServerGuestAPI - removeUserFromProject - error: ', error)
      throw error
    }
  }
}

export default ServerGuestAPI
