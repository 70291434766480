import React from 'react'
import _ from 'lodash'

import { withUserContext, IUserMultiContext, withProjectAdminContext, IProjectAdminMultiContext } from 'src/core/providers'
import * as ROUTES from 'src/constants/routes'

import { Channel, Group, ProjectUser } from 'src/core/models'

import ArkProjectManagerPage from 'src/manager/project/components/ArkProjectManagerPage/ArkProjectManagerPage'
import ProjectGroupSidebar from './ProjectGroupSidebar'
import GroupForm, { GroupFormMode } from './GroupForm'
import ProjectGroupListItem from './ProjectGroupListItem'

import ArkProjectStatusBanner from 'src/core/components/ArkProjectStatusBanner'
import ArkManagerContentView from 'src/core/components/ArkManagerContentView/ArkManagerContentView'
import ArkManagerListView, { ArkManagerFilteredItem } from 'src/core/components/ArkManagerListView/ArkManagerListView'
import ArkManagerFilterForm from 'src/core/components/ArkManagerListView/ArkManagerFilterForm'
import ArkModal from 'src/core/components/ArkModal'
import { OBJECT_GROUP_NAME, OBJECT_GROUP_NAME_PLURAL, OBJECT_PROJECT_NAME, SECTION_MANAGER_SUFFIX_NAME } from 'src/constants/strings'

interface IProps extends IUserMultiContext, IProjectAdminMultiContext {
}
interface IState {
  loading: boolean
  groups: Array<Group>
  filteredGroups?: Array<ArkManagerFilteredItem<Group>>
  filter?: string
  selectedGroup?: Group
  selectedSubGroup?: Group
  loadingUsers: boolean
  groupUsers?: Array<ProjectUser>
  loadingChannels: boolean
  groupChannels?: Array<Channel>
  editGroup?: Group
  showGroupFormModal: boolean
}

class ProjectGroupsPage extends React.Component<IProps, IState> {
  _isMounted: boolean = false

  constructor (props: IProps) {
    super(props)
    this.state = {
      loading: false,
      groups: [],
      filteredGroups: undefined,
      filter: undefined,
      loadingUsers: false,
      loadingChannels: false,
      showGroupFormModal: false
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.loadGroups()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { loading, groups, selectedGroup, selectedSubGroup, loadingUsers, loadingChannels, groupUsers, groupChannels, filter, filteredGroups } = this.state
    const company = this.props.userContext.store.selectedCompany
    const project = this.props.userContext.store.selectedProject
    if (!company || !project) return null

    const rightSidebar = selectedGroup && (
      <ProjectGroupSidebar
        companyId={company.id}
        projectId={project.id}
        group={selectedSubGroup ?? selectedGroup}
        onChange={() => {
          // trigger a data re-load to show changes to any groups (e.g. users/channels were added/removed from a group)
          this.loadGroups()
          // re-select the group so it reloads its extra data incase they've changed
          if (selectedGroup && selectedSubGroup) {
            this.selectSubGroup(selectedGroup, selectedSubGroup)
          } else if (selectedGroup) {
            this.selectGroup(selectedGroup)
          }
        }}
        onEdit={this.onEdit}
        onDidDelete={this.onDidDelete}
        loadingUsers={loadingUsers}
        loadingChannels={loadingChannels}
        groupUsers={groupUsers}
        groupChannels={groupChannels}
      />
    )

    return (
      <ArkProjectManagerPage
        onRightSidebarClose={() => this.setState({ selectedGroup: undefined })}
        rightSidebar={rightSidebar}
      >
        <ArkManagerContentView
          title={OBJECT_GROUP_NAME_PLURAL}
          breadcrumb={[{
            path: ROUTES.getProjectRoute(ROUTES.PROJECT_MANAGER_VIEW, project.id),
            title: ROUTES.formatBreadcrumbRootTitle(project.name, `${OBJECT_PROJECT_NAME} ${SECTION_MANAGER_SUFFIX_NAME}`)
          }]}
        >
          <ArkProjectStatusBanner />
          <ArkManagerListView
            loading={loading}
            items={groups}
            selectedItem={selectedGroup}
            itemRow={(group: Group, isSelected: boolean) => {
              return this.renderGroupTableRowContent(group, isSelected, filter)
            }}
            // topbar={this.topBarContent}
            topbarAddItemTitle={'CREATE ' + OBJECT_GROUP_NAME}
            onAdd={() => this.showAddGroupModal()}
            // FILTERING:
            filter={filter}
            filteredItems={filteredGroups}
            filterForm={this.renderGroupFilterForm()}
            onClearFilter={() => this.filterGroups('') }
          />
          {this.renderGroupFormModal()}
        </ArkManagerContentView>
      </ArkProjectManagerPage>
    )
  }

  // -------

  renderGroupTableRowContent = (group: Group, isSelected: boolean, filter?: string) => {
    return (
      <ProjectGroupListItem
        active={isSelected}
        filter={filter}
        group={group}
        key={group.id}
        onClick={() => this.selectGroup(group)}
        // onEditClick={() => this.showEditGroupModal(group)}
      />
    )
  }

  // -------

  renderGroupFilterForm = () => {
    const { filter } = this.state
    return (
      <ArkManagerFilterForm
        autoComplete={false}
        filterTitle='Filter by name or description'
        filterValue={filter ?? ''}
        onFilterChange={(filter: string) => {
          this.filterGroups(filter)
        }}
      />
    )
  }

  // -------

  filterGroups = (_filter: string) => {
    const { loading, groups, selectedGroup } = this.state
    if (loading) return
    const filter = _filter.length > 0 ? _filter : undefined
    const filteredGroups = filter
      ? groups.reduce<Array<ArkManagerFilteredItem<Group>>>((r, group) => {
        let nameMatch = false
        let descMatch = false
        if (group.name.toLowerCase().includes(filter.toLowerCase())) {
          nameMatch = true
        }
        if (group.desc?.toLowerCase().includes(filter.toLowerCase())) {
          descMatch = true
        }
        if (nameMatch || descMatch) {
          const matchingFields: Array<string> = []
          if (nameMatch) matchingFields.push('name')
          if (descMatch) matchingFields.push('desc')
          const filteredUser: ArkManagerFilteredItem<Group> = {
            item: group,
            matchingFields
          }
          r.push(filteredUser)
        }
        return r
      }, [] as Array<ArkManagerFilteredItem<Group>>)
      : undefined
    if (selectedGroup && (!(filteredGroups?.find((filteredGroup) => filteredGroup.item.id === selectedGroup.id)))) {
      this.selectGroup(undefined) // if a user was selected but isn't in the filtered list deselect them
    }
    this.setState({ filter, filteredGroups })
  }

  clearFilteredGroups = () => {
    this.setState({ filter: undefined, filteredGroups: undefined })
  }

  // -------

  selectGroup = (group?: Group) => {
    console.log('ProjectGroupsPage - selectGroup - group: ', group)
    this.setState({ selectedGroup: group, selectedSubGroup: undefined })
    if (group) {
      this.loadGroupUsers(group.id)
      this.loadGroupChannels(group.id)
    }
  }

  selectSubGroup = (group?: Group, subGroup?: Group) => {
    console.log('ProjectGroupsPage - selectSubGroup - group: ', group, ' subGroup: ', subGroup)
    this.setState({ selectedGroup: group, selectedSubGroup: subGroup })
    if (subGroup) {
      this.loadGroupUsers(subGroup.id)
      this.loadGroupChannels(subGroup.id)
    }
  }

  // -------

  loadGroups = async () => {
    if (this.state.loading === true) return false
    const company = this.props.userContext.store.selectedCompany
    const project = this.props.userContext.store.selectedProject
    if (company && project) {
      try {
        this.setState({ loading: true })
        const groups = await this.props.projectAdminContext.actions.getProjectGroups(company.id, project.id)
        if (this._isMounted) {
          this.setState({
            loading: false,
            groups: groups || [],
            filteredGroups: undefined
          })
        }
        if (this._isMounted && this.state.filter) this.filterGroups(this.state.filter) // re-filter if it was active
      } catch (error) {
        console.error('ProjectGroupsPage - loadGroups - error: ', error)
        // TODO: add an error prop & display an error message if this happens
        if (this._isMounted) this.setState({ loading: false, groups: [], filteredGroups: undefined })
      }
    }
  }

  loadGroupUsers = async (groupId: number) => {
    if (this.state.loadingUsers === true) return false
    const company = this.props.userContext.store.selectedCompany
    const project = this.props.userContext.store.selectedProject
    if (company && project && groupId) {
      try {
        this.setState({ loadingUsers: true })
        const users = await this.props.projectAdminContext.actions.getProjectGroupUsers(company.id, project.id, groupId)
        if (this._isMounted) this.setState({ loadingUsers: false, groupUsers: users || [] })
      } catch (error) {
        console.error('ProjectGroupsPage - loadChannels - error: ', error)
        if (this._isMounted) this.setState({ loadingUsers: false, groupUsers: [] }) // TODO: add an error prop & display an error message if this happens
      }
    }
  }

  loadGroupChannels = async (groupId: number) => {
    if (this.state.loadingChannels === true) return false
    const company = this.props.userContext.store.selectedCompany
    const project = this.props.userContext.store.selectedProject
    if (company && project && groupId) {
      try {
        this.setState({ loadingChannels: true })
        const channels = await this.props.projectAdminContext.actions.getProjectGroupChannels(company.id, project.id, groupId)
        if (this._isMounted) this.setState({ loadingChannels: false, groupChannels: channels || [] })
      } catch (error) {
        console.error('ProjectGroupsPage - loadChannels - error: ', error)
        if (this._isMounted) this.setState({ loadingChannels: false, groupChannels: [] }) // TODO: add an error prop & display an error message if this happens
      }
    }
  }

  // -------

  onEdit = (_group: Group) => {
    this.showEditGroupModal(_group)
  }

  onDidDelete = (_group: Group) => {
    // trigger a programs data re-load so the deleted project no longer shows
    this.loadGroups()
    this.selectGroup(undefined)
  }

  // -------

  showAddGroupModal = () => {
    this.setState({ showGroupFormModal: true })
  }

  showEditGroupModal = (group: Group) => {
    this.setState({ showGroupFormModal: true, editGroup: group })
  }

  hideGroupModal = () => {
    // NB: DON'T clear/reset `editGroup` here, see `didHideGroupModal` which is called once the modal has actually closed & we can safely reset it then
    // NB: this stops a saved edit form flipping the 'updated' success text to 'created' briefly while the modal closes if we reset it straight away here
    // NB: & also stops the form title flipping from edit to add as it closes via the cancel button
    this.setState({ showGroupFormModal: false }) // editGroup: undefined
  }

  // triggered via modal callbacks once the modal has already closed
  didHideGroupModal = () => {
    this.setState({ showGroupFormModal: false, editGroup: undefined })
  }

  // -------

  renderGroupFormModal = () => {
    return (
      <ArkModal open={this.state.showGroupFormModal} onClose={() => this.didHideGroupModal()}>
        {this.renderGroupAddEditForm(this.state.editGroup)}
      </ArkModal>
    )
  }

  renderGroupAddEditForm = (group?: Group) => {
    const company = this.props.userContext.store.selectedCompany
    const project = this.props.userContext.store.selectedProject
    const parentGroups = this.state.groups.filter((group: Group) => group.isOrganisationGroup)
    if (!company || !project) return null
    return (
      <GroupForm
        mode={this.state.editGroup ? GroupFormMode.Edit : GroupFormMode.Add}
        companyId={company.id}
        projectId={project.id}
        group={group}
        parentGroups={parentGroups}
        onCancel={() => { this.hideGroupModal() }}
        onSave={async () => {
          // trigger a channels data re-load to show the newly added one
          await this.loadGroups()
          if (!this._isMounted) return
          // re-select with the updated group/sub-group so any changes are shown/used
          if (this.state.selectedSubGroup && group && this.state.selectedSubGroup.id === group.id) {
            const updatedGroup = _.find(this.state.groups, (grp) => grp.id === this.state.selectedGroup?.id)
            const updatedSubGroup = _.find(updatedGroup?.subGroups, (grp) => grp.id === this.state.selectedSubGroup?.id)
            this.selectSubGroup(this.state.selectedGroup, updatedSubGroup)
          } else if (this.state.selectedGroup && group && this.state.selectedGroup.id === group.id) {
            const updatedGroup = _.find(this.state.groups, (grp) => grp.id === this.state.selectedGroup?.id)
            this.selectGroup(updatedGroup)
          }
          // NB: we don't auto close/hide the modal form when it saves, leave it up for a success message to show & the user to dismiss manually
        }}
        onDelete={() => {
          // trigger a channels data re-load so the deleted one no longer shows
          this.loadGroups()
          // NB: we don't auto close/hide the modal form when it deletes, leave it up for a delete success message to show & the user to dismiss manually
        }}
        onClose={() => { this.hideGroupModal() }}
        insideModal={true}
      />
    )
  }
}
export default withProjectAdminContext(withUserContext(ProjectGroupsPage))
