import React, { FC, useEffect, useRef, useState } from 'react'

import ArkIconButton from 'src/core/components/ArkIconButton'
import VideoPlayer360 from 'src/core/components/ArkVideoPlayer/VideoPlayer360'
import VideoPlayerSLDP, { VideoPlayerSLDPStatus } from 'src/core/components/ArkVideoPlayer/VideoPlayerSLDP'
import { PROGRAM_360_ENABLED, PROGRAM_360_PREFER_PASSTHROUGH_DEFAULT } from 'src/constants/config'
import { Program } from 'src/core/models'
import { PlayerResolution } from 'src/core/types/player'
import { delay } from 'src/core/utilities/delay'
import { useAudioLevels } from 'src/viewer/providers/AudioLevelsProvider'
import { DEFAULT_CHANNEL } from 'src/core/providers/LocalConfigProvider'
import { useViewer } from 'src/viewer/providers/ViewerProvider'

import ProgramControls from './ProgramControls'
import ProgramOptionsPopup from './ProgramOptionsPopup'
import ProgramStatusView from './ProgramStatusView'
import styles from './ProgramView.module.css'
import ProgramWatchdog, { ProgramWatchdogStatus } from './ProgramWatchdog'
import { getProgramError, getResolutionText, parseAdvancedParameters } from './utilities'

interface ProgramViewProps {
  controls: boolean
  onClick?: () => void
  program: Program
  thumb: boolean
}

const ProgramView: FC<ProgramViewProps> = (props) => {
  const audioLevels = useAudioLevels()
  const viewer = useViewer()

  const programRef = useRef<HTMLDivElement>({} as HTMLDivElement)

  // FIXME rename "custom options" to "program options"

  // custom options
  const [isCustomAbr, setIsCustomAbr] = useState<boolean>(true)
  const [isCustomOptions, setIsCustomOptions] = useState<boolean>(false)
  const [isCustomPassthrough, setIsCustomPassthrough] = useState<boolean>(false)
  const [customAdvancedParameters, setCustomAdvancedParameters] = useState<string>('')
  const [customBuffer, setCustomBuffer] = useState<number>(DEFAULT_CHANNEL.buffer)
  const [customResolution, setCustomResolution] = useState<PlayerResolution>(DEFAULT_CHANNEL.resolution)

  // player
  const [playerImageData, setPlayerImageData] = useState<ImageData | undefined>(undefined)
  const [playerStatus, setPlayerStatus] = useState<VideoPlayerSLDPStatus>({ currentTime: 0, playing: false })

  // program
  const [programWatchdogStatus, setProgramWatchdogStatus] = useState<ProgramWatchdogStatus>(ProgramWatchdogStatus.Connecting)
  const [isProgramFullscreen, setIsProgramFullscreen] = useState<boolean>(false)
  const [isProgramOptionsPopupOpen, setIsProgramOptionsPopupOpen] = useState<boolean>(false)
  const [isProgramPaused, setIsProgramPaused] = useState<boolean>(false)
  const [isProgramRestarting, setIsProgramRestarting] = useState<boolean>(false)
  const [isProgramStopped, setIsProgramStopped] = useState(false)
  const [isProgramThreeSixtyOn, setIsProgramThreeSixtyOn] = useState<boolean>(PROGRAM_360_ENABLED && !!props.program.is360Video)
  const [isProgramThreeSixtyPreferPassthrough, setIsProgramThreeSixtyPreferPassthrough] = useState<boolean>(PROGRAM_360_PREFER_PASSTHROUGH_DEFAULT)
  const [isProgramVolumePopupOpen, setIsProgramVolumePopupOpen] = useState<boolean>(false)

  /**
   * effects
   */

  useEffect(() => {
    if (!viewer.fullscreen) setIsProgramFullscreen(false)
  }, [viewer.fullscreen])

  // renew sldp hotlink
  useEffect(() => {
    if (playerStatus.error && props.program.shouldRenewSLDPHotlink()) {
      console.log(`ProgramView[${props.program.id}] - renew sldp hotlink`)
      viewer.refreshChannel()
    }
  }, [playerStatus, props.program])

  /**
   * actions
   */

  const onClick = () => {
    // console.log(`ProgramView[${props.program.id}] - onClick`)
    if (isProgramFullscreen) return
    if (props.onClick) {
      props.onClick()
    } else if (viewer.getChannelAutoSolo()) {
      viewer.setChannelAutoSoloProgram(viewer.getChannelAutoSoloProgram() === props.program.id
        ? undefined
        : props.program.id
      )
    }
  }

  const onFullscreenClick = () => {
    // console.log(`ProgramView[${props.program.id}] - onFullscreenClick`)
    if (isProgramFullscreen) {
      setIsProgramFullscreen(false)
      document.exitFullscreen()
    } else {
      setIsProgramFullscreen(true)
      programRef.current.requestFullscreen()
    }
  }

  const onRestartClick = async () => {
    // console.log(`ProgramView[${props.program.id}] - onRestartClick`)
    setIsProgramRestarting(true)
    await delay(500)
    setIsProgramRestarting(false)
  }

  /**
   * render
   */

  // console.log(`ProgramView[${props.program.id}] - render`)

  // FIXME refactor render variables for clarity
  // FIXME merge channel & program advanced parameters
  const advancedParameters: string = isCustomOptions ? customAdvancedParameters : viewer.getChannelAdvancedParameters()
  const buffer: number = isCustomOptions ? customBuffer : viewer.getChannelBuffer()
  const isClickable: boolean = !isProgramFullscreen && (!!props.onClick || viewer.getChannelAutoSolo())
  const isRestarting: boolean = viewer.restarting || isProgramRestarting
  const isStopped: boolean = viewer.stopped || isProgramStopped
  const channelResolution: PlayerResolution = viewer.getChannelResolution()
  const resolution: PlayerResolution = isCustomOptions ? customResolution : channelResolution
  const isAutoSolo: boolean = viewer.getChannelAutoSoloProgram() === props.program.id
  const isThreeSixtyForcePassthrough: boolean = PROGRAM_360_ENABLED &&
    !!props.program.is360Video &&
    (isCustomOptions
      ? isProgramThreeSixtyOn && isProgramThreeSixtyPreferPassthrough
      : false)
  const channelPassthrough: boolean = viewer.getChannelPassthrough()
  const isPassthrough: boolean = isThreeSixtyForcePassthrough ||
  (isCustomOptions
    ? isCustomPassthrough
    : channelPassthrough)
  const channelAbr: boolean = viewer.getChannelAbr()
  const isAbr: boolean = isPassthrough ? false : (isCustomOptions ? isCustomAbr : channelAbr)
  const programMute: boolean = viewer.getProgramMute(props.program.id)
  const playerMute: boolean = viewer.getPlayerMute(props.program.id)
  const programVolume: number = viewer.getProgramVolume(props.program.id)
  const playerVolume: number = viewer.getPlayerVolume(props.program.id)
  const url: string | undefined = props.program.getSLDPOutputURL(isPassthrough)
  const showSldpPlayer: boolean = !isRestarting && !isStopped && !!url

  // if (DEBUG_MODE) console.log(`#798 mute test - ProgramView[${props.program.id}] - render - programMute: ${programMute} programVolume: ${programVolume} playerMute: ${playerMute} playerVolume: ${playerVolume}`)

  // debug info
  const debugInfo = [
    `Program ID: ${props.program.id}`,
    `Channel resolution: ${getResolutionText(channelResolution, channelAbr, channelPassthrough)}`,
    `Program resolution: ${isCustomOptions ? getResolutionText(resolution, isAbr, isCustomPassthrough) : 'Inherited'}`,
    `Player resolution: ${playerStatus.currentResolution || ''}`,
    `Buffer: ${buffer.toLocaleString()} ms`,
    `FPS: ${playerStatus.fps || ''}`
  ]

  const optionsComponent = (
    <ProgramOptionsPopup
      abr={isCustomAbr}
      advancedParameters={customAdvancedParameters}
      buffer={customBuffer}
      customOptions={isCustomOptions}
      onAbrChange={setIsCustomAbr}
      onAdvancedParametersChange={setCustomAdvancedParameters}
      onBufferChange={setCustomBuffer}
      onCustomOptionsChange={setIsCustomOptions}
      onOpenChange={setIsProgramOptionsPopupOpen}
      onPassthroughChange={setIsCustomPassthrough}
      onResolutionChange={setCustomResolution}
      onRestartClick={onRestartClick}
      onStoppedChange={setIsProgramStopped}
      onThreeSixtyOnChange={setIsProgramThreeSixtyOn}
      onThreeSixtyPreferPassthroughChange={setIsProgramThreeSixtyPreferPassthrough}
      open={isProgramOptionsPopupOpen}
      passthrough={isCustomPassthrough}
      resolution={customResolution}
      restarting={isRestarting}
      stopped={isProgramStopped}
      threeSixtyAvailable={PROGRAM_360_ENABLED && !!props.program.is360Video}
      threeSixtyOn={isProgramThreeSixtyOn}
      threeSixtyPreferPassthrough={isProgramThreeSixtyPreferPassthrough}
      triggerComponent={
        <ArkIconButton
          active={isCustomOptions}
          name='video-quality'
          open={isProgramOptionsPopupOpen}
          size={22}
          width={35}
        />
      }
    />
  )

  return (
    <div
      className={`${styles.program} ${isClickable ? styles.programClickable : ''}`}
      data-program-id={`${props.program.id}`} // e2e testing identifier - additional data
      data-test-id="ark-program-view" // e2e testing identifier
      onClick={onClick}
      ref={programRef}
    >
      <ProgramWatchdog
        currentTime={playerStatus.currentTime}
        disabled={isProgramPaused}
        onStatusChange={setProgramWatchdogStatus}
        programId={props.program.id}
      >
        {/* sldp video player */}
        {showSldpPlayer && (
          <VideoPlayerSLDP
            abr={isAbr}
            advancedParameters={parseAdvancedParameters(advancedParameters)}
            audioStarted={viewer.audioStarted}
            buffer={buffer}
            hidden={isProgramThreeSixtyOn}
            id={props.program.id.toString()}
            imageDataEnabled={isProgramThreeSixtyOn}
            muted={playerMute}
            onAudioLevelChange={(level) => audioLevels.setProgramAudioLevel(props.program.id, level)}
            onImageDataChange={setPlayerImageData}
            onStatusChange={setPlayerStatus}
            play={!isProgramPaused}
            resolution={resolution}
            url={url!}
            volume={playerVolume}
          />
        )}
      </ProgramWatchdog>

      {/* 360 video player */}
      {isProgramThreeSixtyOn && (
        <VideoPlayer360 active={!props.thumb} imageData={playerImageData} />
      )}

      {/* status */}
      <ProgramStatusView
        playerError={playerStatus.error}
        programError={getProgramError(props.program, isPassthrough)}
        status={programWatchdogStatus}
        thumb={props.thumb}
      />

      {/* controls */}
      <ProgramControls
        audioLevelPostFader={audioLevels.getProgramAudioLevelPostFader(props.program.id)}
        audioLevelPostFaderDisabled={audioLevels.getProgramAudioLevelPostFaderDisabled(props.program.id)}
        audioLevelPreFader={audioLevels.getProgramAudioLevelPreFader(props.program.id)}
        autohide={!isProgramOptionsPopupOpen}
        autoSolo={isAutoSolo}
        containerRef={programRef}
        debugInfo={debugInfo}
        fps={playerStatus.fps}
        fullscreen={isProgramFullscreen}
        hidden={!props.controls}
        mute={programMute}
        noAudio={audioLevels.getProgramNoAudio(props.program.id)}
        onAudioLevelsClick={() => viewer.setShowMixer(true)}
        onFullscreenClick={onFullscreenClick}
        onMuteClick={() => viewer.setProgramMute(props.program.id, !programMute)}
        onPauseClick={() => setIsProgramPaused(!isProgramPaused)}
        onVolumeChange={(volume) => viewer.setProgramVolume(props.program.id, volume)}
        onVolumePopupOpenChange={setIsProgramVolumePopupOpen}
        optionsComponent={optionsComponent}
        paused={isProgramPaused}
        program={props.program}
        resolution={playerStatus.currentResolution}
        solo={viewer.getProgramSolo(props.program.id)}
        threeSixty={isProgramThreeSixtyOn}
        time={playerStatus.currentTime}
        volume={programVolume}
        volumePopupOpen={isProgramVolumePopupOpen}
      />

      {/* border */}
      {!isProgramFullscreen && (
        <div className={`${styles.border} ${isAutoSolo ? styles.borderAutoSolo : ''}`} />
      )}
    </div>
  )
}

export default ProgramView
