/**
 * NodeGraphProvider
 */

import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import _ from 'lodash'

import { PROJECT_NODE_GRAPH_MOCK_DATA } from 'src/constants/config'
import { NodeGraphOptions } from 'src/core/models/user_cache'
import { NavContext } from 'src/core/providers/NavProvider'
import { ProjectAdminContext } from 'src/core/providers/ProjectAdminProvider'
import { UserContext } from 'src/core/providers/UserProvider'
import { delay } from 'src/core/utilities/delay'

import { NodeGraphChannel, NodeGraphData, NodeGraphGroup, NodeGraphProgram, NodeGraphUser } from './types'
import {
  getIsChannelOrphaned,
  getIsGroupOrphaned,
  getIsProgramOrphaned,
  getIsUserOrphaned,
  getSomeChannelsForDefaultGroup
} from './utilities'
import MOCK_DATA from './mock-data'
import { ProjectSummary, ProjectVideoEngine } from 'src/core/models'

export const DEFAULT_GROUP_NAME = 'All Project Users'

interface INodeGraphActions {
  navigateTo: (section: string, id?: number) => void
  resetFilters: () => void
  setShowFilter: (isOpen: boolean) => void
  setIsFullscreen: (isFullscreen: boolean) => void
  setShowAllProjectUsersGroup: (show: boolean) => void
  setShowOrphanedChannels: (show: boolean) => void
  setShowOrphanedGroups: (show: boolean) => void
  setShowOrphanedPrograms: (show: boolean) => void
  setShowOrphanedUsers: (show: boolean) => void
  setShowPrograms: (show: boolean) => void
  setShowUsers: (show: boolean) => void
}
interface INodeGraphStore {
  filteredData?: NodeGraphData
  showFilter: boolean;
  isFullscreen: boolean
  isLoading: boolean
  numberOfChannels: number
  numberOfGroups: number
  numberOfHiddenChannels: number
  numberOfHiddenGroups: number
  numberOfHiddenPrograms: number
  numberOfHiddenUsers: number
  numberOfPrograms: number
  numberOfUsers: number
  showAllProjectUsersGroup: boolean
  showOrphanedChannels: boolean
  showOrphanedGroups: boolean
  showOrphanedPrograms: boolean
  showOrphanedUsers: boolean
  showPrograms: boolean
  showUsers: boolean
  unfilteredData?: NodeGraphData
  projectVideoEngine?: ProjectVideoEngine
}

interface INodeGraphContext {
  actions: INodeGraphActions;
  store: INodeGraphStore;
}

interface NodeGraphProviderProps {
  children: ReactNode
}

export const NodeGraphContext = createContext<INodeGraphContext>({} as INodeGraphContext)

const NodeGraphProvider = (props: NodeGraphProviderProps) => {
  const { actions: navActions } = useContext(NavContext)
  const { actions: projectAdminActions } = useContext(ProjectAdminContext)
  const { actions: userActions, store: userStore } = useContext(UserContext)
  const { selectedCompany: company, selectedProject: project } = userStore
  if (!company || !project) return null

  const { children } = props

  const [data, setData] = useState<NodeGraphData>()
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [showAllProjectUsersGroup, setShowAllProjectUsersGroup] = useState<boolean>(false)
  const [showOrphanedChannels, setShowOrphanedChannels] = useState<boolean>(false)
  const [showOrphanedGroups, setShowOrphanedGroups] = useState<boolean>(false)
  const [showOrphanedPrograms, setShowOrphanedPrograms] = useState<boolean>(false)
  const [showOrphanedUsers, setShowOrphanedUsers] = useState<boolean>(false)
  const [showPrograms, setShowPrograms] = useState<boolean>(false)
  const [showUsers, setShowUsers] = useState<boolean>(false)

  /**
   * user cache
   */

  const saveUserCache = () => {
    if (!data) return
    console.log('NodeGraphProvider - saveUserCache')
    const { userCache } = userStore
    if (!userCache) return
    const projectCache = userCache.getSelectedCompanyProjectCache(company.id, project.id)
    if (!projectCache) return
    projectCache.nodeGraphOptions.showAllProjectUsersGroup = showAllProjectUsersGroup
    projectCache.nodeGraphOptions.showOrphanedChannels = showOrphanedChannels
    projectCache.nodeGraphOptions.showOrphanedGroups = showOrphanedGroups
    projectCache.nodeGraphOptions.showOrphanedPrograms = showOrphanedPrograms
    projectCache.nodeGraphOptions.showOrphanedUsers = showOrphanedUsers
    projectCache.nodeGraphOptions.showPrograms = showPrograms
    projectCache.nodeGraphOptions.showUsers = showUsers
    userCache.setSelectedCompanyProjectCache(company.id, project.id, projectCache)
    userActions.saveUserCache(userCache)
  }

  useEffect(() => {
    saveUserCache()
  }, [
    showAllProjectUsersGroup,
    showOrphanedChannels,
    showOrphanedGroups,
    showOrphanedPrograms,
    showOrphanedUsers,
    showPrograms,
    showUsers
  ])

  /**
   * load data
   */

  const loadData = async () => {
    console.log('NodeGraphProvider - loadData')

    setIsLoading(true)
    setData(undefined)

    // load options from user cache
    if (!userStore.userCache) return
    userStore.userCache.setSelectedCompanyProjectManagerSection(company.id, project.id) // creates project cache if it doesn't exist
    const projectCache = userStore.userCache.getSelectedCompanyProjectCache(company.id, project.id)
    if (!projectCache) return

    let newData: NodeGraphData
    if (PROJECT_NODE_GRAPH_MOCK_DATA) {
      await delay(2000)
      newData = MOCK_DATA
    } else {
      let projectSummary: ProjectSummary
      try {
        projectSummary = await projectAdminActions.getProjectSummary(company.id, project.id)
      } catch (error) {
        // TODO error
        // TODO: ideally should still show some base UI if this api call errors out, at least an error message? currently get a blank page dashabord page
        return
      }

      const channels: NodeGraphChannel[] = _.map(projectSummary.channels, channel => ({
        id: channel.id,
        colour: channel.colour,
        name: channel.name,
        programs: projectSummary.channelProgramsLookup?.get(channel.id) || []
      }))

      const groups: NodeGraphGroup[] = _.map(projectSummary.groups, group => ({
        id: group.id,
        channels: projectSummary.groupChannelsLookup?.get(group.id) || [],
        name: group.name,
        users: projectSummary.groupUsersLookup?.get(group.id) || []
      }))

      const programs: NodeGraphProgram[] = _.map(projectSummary.programs, program => ({
        id: program.id,
        colour: program.colour,
        isOnline: program.isOnline,
        name: program.name,
        shortName: program.shortNameCapitalised()
      }))

      const users: NodeGraphUser[] = _.map(projectSummary.users, user => ({
        id: user.id,
        avatarType: user.userAvatarType(),
        guest: user.isGuest,
        name: user.name()
      }))

      newData = { channels, groups, programs, users }
    }

    // set initial options
    setShowAllProjectUsersGroup(getSomeChannelsForDefaultGroup(newData))
    setShowOrphanedChannels(projectCache.nodeGraphOptions.showOrphanedChannels)
    setShowOrphanedGroups(projectCache.nodeGraphOptions.showOrphanedGroups)
    setShowOrphanedPrograms(projectCache.nodeGraphOptions.showOrphanedPrograms)
    setShowOrphanedUsers(projectCache.nodeGraphOptions.showOrphanedUsers)
    setShowPrograms(projectCache.nodeGraphOptions.showPrograms)
    setShowUsers(projectCache.nodeGraphOptions.showUsers)

    setData(newData)
    await delay(500)
    setIsLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [project])

  /**
   * filtered
   */

  const getFilteredData = (): NodeGraphData | undefined => {
    if (!data) return undefined

    let channels = _.cloneDeep(data.channels)
    let groups = _.cloneDeep(data.groups)
    let programs = _.cloneDeep(data.programs)
    let users = _.cloneDeep(data.users)

    // channels
    if (!showOrphanedChannels) {
      channels = _.filter(channels, channel => !getIsChannelOrphaned(channel, data))
      const channelIds = _.map(channels, 'id')
      _.each(groups, group => {
        group.channels = _.intersection(group.channels, channelIds)
      })
    }

    // groups
    if (!showAllProjectUsersGroup) {
      groups = _.filter(groups, group => group.name !== DEFAULT_GROUP_NAME)
    }
    if (!showOrphanedGroups) {
      groups = _.filter(groups, group => group.name === DEFAULT_GROUP_NAME || !getIsGroupOrphaned(group, data))
    }

    // programs
    if (!showPrograms) {
      programs = []
    } else if (!showOrphanedPrograms) {
      programs = _.filter(programs, program => !getIsProgramOrphaned(program, data))
    }
    const programIds = _.map(programs, 'id')
    _.each(channels, channel => {
      channel.programs = _.intersection(channel.programs, programIds)
    })

    // users
    if (!showUsers) {
      users = []
    } else if (!showOrphanedUsers) {
      users = _.filter(users, user => !getIsUserOrphaned(user, data, showAllProjectUsersGroup))
    }
    const userIds = _.map(users, 'id')
    _.each(groups, group => {
      group.users = _.intersection(group.users, userIds)
    })

    return ({
      channels,
      groups,
      programs,
      users
    })
  }

  /**
   * actions
   */

  const navigateTo = (section: string, id?: number) => {
    console.log('NodeGraphProvider - navigateTo - section:', section, 'id:', id)
    let path = section.replace(':projectId', project.id.toString())
    if (id) path += `/${id}`
    navActions.goto(path)
  }

  const resetFilters = () => {
    console.log('NodeGraphProvider - resetFilters')
    const nodeGraphOptions = new NodeGraphOptions() // defaults
    setShowAllProjectUsersGroup(data ? getSomeChannelsForDefaultGroup(data) : nodeGraphOptions.showAllProjectUsersGroup)
    setShowOrphanedChannels(nodeGraphOptions.showOrphanedChannels)
    setShowOrphanedGroups(nodeGraphOptions.showOrphanedGroups)
    setShowOrphanedPrograms(nodeGraphOptions.showOrphanedPrograms)
    setShowOrphanedUsers(nodeGraphOptions.showOrphanedUsers)
    setShowPrograms(nodeGraphOptions.showPrograms)
    setShowUsers(nodeGraphOptions.showUsers)
  }

  /**
   * render
   */

  const filteredData = getFilteredData()

  const numberOfChannels = _.size(data?.channels)
  const numberOfHiddenChannels = numberOfChannels - _.size(filteredData?.channels)
  const numberOfGroups = _.size(data?.groups)
  const numberOfHiddenGroups = numberOfGroups - _.size(filteredData?.groups)
  const numberOfPrograms = _.size(data?.programs)
  const numberOfHiddenPrograms = numberOfPrograms - _.size(filteredData?.programs)
  const numberOfUsers = _.size(data?.users)
  const numberOfHiddenUsers = numberOfUsers - _.size(filteredData?.users)

  const actions: INodeGraphActions = {
    navigateTo,
    resetFilters,
    setShowFilter,
    setIsFullscreen,
    setShowAllProjectUsersGroup,
    setShowOrphanedChannels,
    setShowOrphanedGroups,
    setShowOrphanedPrograms,
    setShowOrphanedUsers,
    setShowPrograms,
    setShowUsers
  }

  const store: INodeGraphStore = {
    filteredData,
    showFilter,
    isFullscreen,
    isLoading,
    numberOfChannels,
    numberOfGroups,
    numberOfHiddenChannels,
    numberOfHiddenGroups,
    numberOfHiddenPrograms,
    numberOfHiddenUsers,
    numberOfPrograms,
    numberOfUsers,
    showAllProjectUsersGroup,
    showOrphanedChannels,
    showOrphanedGroups,
    showOrphanedPrograms,
    showOrphanedUsers,
    showPrograms,
    showUsers,
    unfilteredData: data,
    projectVideoEngine: project.videoEngine
  }

  return (
    <NodeGraphContext.Provider value={{ actions, store }}>
      {children}
    </NodeGraphContext.Provider>
  )
}

export default NodeGraphProvider
