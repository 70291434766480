import React, { Component } from 'react'
import * as yup from 'yup'

import { withProjectAdminContext, IProjectAdminMultiContext } from 'src/core/providers'

import { Group, GroupType } from 'src/core/models'

import { OBJECT_GROUP_NAME } from 'src/constants/strings'
import { PROJECT_MGR_GROUP_TYPE_ORG_ENABLED } from 'src/constants/config'

import ArkButton from 'src/core/components/ArkButton'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps, ArkFormFieldOption /*, ArkFormFieldPlaceholder */ } from 'src/core/components/ArkForm/ArkForm'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkMessage from 'src/core/components/ArkMessage'

const formSchema = yup.object().shape({
  name: yup.string().min(4).max(25),
  desc: yup.string().min(0).max(255),
  parentGroup: yup.number().transform((currentVal, originalVal) => {
    // TODO: why is a null value coming through as a string of null sometimes (when editing an existing group with no parent group set??)
    return originalVal === null || originalVal === 'null' ? null : currentVal
  }).nullable().min(0)
})

export enum GroupFormMode {
  Add = 'add',
  Edit = 'edit',
}

interface IProps extends IProjectAdminMultiContext {
  mode: GroupFormMode
  companyId: number
  projectId: number
  group?: Group
  parentGroups?: Array<Group>
  onCancel?: Function
  onSave?: Function
  onClose?: Function
  insideModal?: boolean // ArkForm prop - enable when showing this form within a modal (so fieldset label bg's match)
}
interface IState {
  error?: Error
  isSubmitting: boolean
  hasSaved: boolean
  groupType: GroupType // manually track the current selected groupType
}

class GroupForm extends Component<IProps, IState> {
  _isMounted: boolean = false

  constructor (props: IProps) {
    super(props)
    const { group } = this.props
    this.state = {
      isSubmitting: false,
      // error: new Error("ERROR: TEST"),
      hasSaved: false,
      groupType: group?.isOrganisationGroup ? GroupType.org : GroupType.std
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  componentDidUpdate (_prevProps: IProps) {
    // TESTING: update the groupType if the group is changed (NB: not tested yet, maynot be needed for current usage)
    // if ((this.props.group === undefined && prevProps.group !== undefined) ||
    // (this.props.group !== undefined && prevProps.group === undefined) ||
    // (this.props.group?.id !== prevProps.group?.id)) {
    //   this.setState({
    //     groupType: this.props.group?.isOrganisationGroup ? GroupType.org : GroupType.std
    //   })
    // }
  }

  render () {
    const { group, parentGroups } = this.props
    const { error, isSubmitting, hasSaved, groupType } = this.state

    const isDefaultGroup = this.props.mode === GroupFormMode.Edit ? (group?.isDefaultGroup ?? false) : false

    const formFields: Array<ArkFormField> = []
    formFields.push({ type: ArkFormFieldType.Input, key: 'name', label: OBJECT_GROUP_NAME + ' name', required: true, defaultValue: group?.name ?? undefined })
    formFields.push({ type: ArkFormFieldType.Input, key: 'desc', label: OBJECT_GROUP_NAME + ' description', required: false, defaultValue: group?.desc ?? undefined })

    if (PROJECT_MGR_GROUP_TYPE_ORG_ENABLED) {
      // NB: groupType is disabled on the edit form (only allowed to set it when creating a new group)
      const groupTypeOptions: Array<ArkFormFieldOption> = [
        { key: 'std', text: 'Standard', value: GroupType.std },
        { key: 'org', text: 'Organisational', value: GroupType.org }
      ]
      formFields.push({ type: ArkFormFieldType.Radio, key: 'groupType', label: OBJECT_GROUP_NAME + ' type', required: true, defaultValue: group?.isOrganisationGroup ? GroupType.org : GroupType.std, options: groupTypeOptions, disabled: (this.props.mode === GroupFormMode.Edit) })

      const disableParentGroup = isDefaultGroup || groupType === GroupType.org
      const currentParentGroupId = (group?.parentGroupId !== undefined && group?.parentGroupId !== null && !disableParentGroup ? (group?.parentGroupId) : 0)
      const parentGroupOptions: Array<ArkFormFieldOption> = []
      parentGroupOptions.push({ key: 'group_' + 'none', text: 'No parent ' + OBJECT_GROUP_NAME, value: 0 })
      if (parentGroups) {
        for (const parentGroup of parentGroups) {
          parentGroupOptions.push({
            key: 'group_' + parentGroup.id,
            text: parentGroup.name,
            value: parentGroup.id
          })
        }
      }
      formFields.push({
        type: ArkFormFieldType.Dropdown,
        key: 'parentGroup',
        label: 'Parent ' + OBJECT_GROUP_NAME,
        required: false,
        defaultValue: currentParentGroupId,
        value: (disableParentGroup ? 0 : undefined), // NB: force the parentGroup to 0 (no parent) when disabling the field for GroupType.org mode
        options: parentGroupOptions,
        disabled: disableParentGroup,
        fieldProps: { scrolling: true } // NB: enable dropdown scrolling so long lists don't go off screen on (most) smaller resolutions/heights
      })
    }

    formFields.push({
      type: ArkFormFieldType.Group,
      key: 'buttons',
      fields: [
        { type: ArkFormFieldType.CancelButton, key: 'cancel', label: 'CANCEL', fieldProps: { onClick: this.onCancel, floated: 'left' } },
        { type: ArkFormFieldType.OKButton, key: 'submit', label: (this.props.mode === GroupFormMode.Edit ? 'SAVE' : 'ADD'), fieldProps: { loading: isSubmitting, floated: 'right' } }
      ],
      fieldProps: { widths: 'equal' }
    })

    return (
      <>
        <ArkHeader as="h2" inverted>
          {this.props.mode === GroupFormMode.Edit ? 'Edit' : 'Add'} {OBJECT_GROUP_NAME}
        </ArkHeader>
        <p>Only admins and managers will see these group details, they are not visible to standard viewing members.</p>
        {hasSaved && (<>
          <ArkMessage positive>
            <ArkMessage.Header>Group {this.props.mode === GroupFormMode.Edit ? 'Updated' : 'Created'}</ArkMessage.Header>
            <ArkMessage.Item>The group has been {this.props.mode === GroupFormMode.Edit ? 'updated' : 'created'} successfully</ArkMessage.Item>
          </ArkMessage>
          <ArkButton type="button" color="blue" fluid basic size="large" disabled={false} onClick={this.onClose}>
            OK
          </ArkButton>
        </>)}

        {!hasSaved && (
          <ArkForm
            formKey="group"
            inverted
            formError={error}
            formFields={formFields}
            formSchema={formSchema}
            onFormSubmit={this.onFormSubmit}
            onValueChanged={this.onValueChanged}
            showLabels={true}
            insideModal={this.props.insideModal}
          >
          </ArkForm>)}
      </>
    )
  }

  onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    console.log('GroupForm - onFormSubmit - fieldValues: ', fieldValues)
    const { name, desc, parentGroup, groupType } = fieldValues
    const parentGroupId = parentGroup && parentGroup !== '' ? parseInt(parentGroup) : undefined
    console.log('GroupForm - onFormSubmit - name: ', name, ' desc: ', desc, ' parentGroupId: ', parentGroupId, ' groupType: ', groupType)
    if (this.props.mode === GroupFormMode.Add) {
      this.addGroup(fieldValues.name, desc, parentGroupId, groupType)
    } else if (this.props.mode === GroupFormMode.Edit) {
      this.updateGroup(fieldValues.name, desc, parentGroupId)
    }
  }

  onValueChanged = (fieldKey: string, fieldValue: any, oldFieldValue: any) => {
    if (fieldKey === 'groupType') {
      console.log('GroupForm - onValueChanged - fieldKey: ', fieldKey, ' fieldValue: ', fieldValue, ' oldFieldValue: ', oldFieldValue)
      this.setState({
        groupType: fieldValue === GroupType.org ? GroupType.org : GroupType.std
      })
    }
  }

  onCancel = () => {
    console.log('GroupForm - onCancel')
    if (this.props.onCancel) this.props.onCancel()
  }

  onClose = () => {
    console.log('GroupForm - onClose')
    if (this.props.onClose) this.props.onClose()
  }

  // -------

  addGroup = async (name: string, desc?: string, parentGroupId?: number, groupType: GroupType = GroupType.std) => {
    if (this.state.isSubmitting) return
    const { companyId, projectId } = this.props
    this.setState({ isSubmitting: true, error: undefined })
    try {
      const savedGroup = await this.props.projectAdminContext.actions.addProjectGroup(companyId, projectId, name, groupType, parentGroupId, desc)
      console.log('GroupForm - addGroup - savedGroup: ', savedGroup)
      if (savedGroup) {
        if (this._isMounted) this.setState({ isSubmitting: false, hasSaved: true })
        if (this.props.onSave) this.props.onSave()
      } else {
        if (this._isMounted) {
          this.setState({
            isSubmitting: false,
            error: Error('A problem occurred adding the group, please try again.')
          })
        }
      }
    } catch (error) {
      if (this._isMounted) this.setState({ isSubmitting: false, error })
    }
  }

  updateGroup = async (name: string, desc?: string, parentGroupId?: number) => {
    const { companyId, projectId, group } = this.props
    if (this.state.isSubmitting || !group) return
    this.setState({ isSubmitting: true, error: undefined })
    try {
      const updateGroup = new Group(group.id, name, projectId, group.isDefaultGroup, group.isOrganisationGroup, parentGroupId)
      updateGroup.desc = desc
      const savedGroup = await this.props.projectAdminContext.actions.updateProjectGroup(companyId, projectId, updateGroup)
      console.log('GroupForm - updateChannel - savedGroup: ', savedGroup)
      if (savedGroup) {
        if (this._isMounted) this.setState({ isSubmitting: false, hasSaved: true })
        if (this.props.onSave) this.props.onSave()
      } else {
        if (this._isMounted) {
          this.setState({
            isSubmitting: false,
            error: Error('A problem occurred updating the group, please try again.')
          })
        }
      }
    } catch (error) {
      if (this._isMounted) this.setState({ isSubmitting: false, error })
    }
  }
}

export default withProjectAdminContext(GroupForm)
