import { BREADCRUMB_ROOT_SHOW_SELECTION_NAME } from './config'

// public
export const HOME = '/'
export const REGISTER = '/register'
export const LOGIN = '/login'
export const LOGIN_PASSWORD_FORGOT = '/login/forgot-password'
export const LOGIN_PASSWORD_RESET = '/login/reset-password/:resetToken'
export const LOGIN_SSO = '/login/sso' // NB: currently only used for sso callback redirect handling (not the sso login page that initiates the sso flow)
export const LOGIN_EXTERNAL_DEVICE = '/auth' // tvOS login (& maybe other external devices/platforms in the future)
export const REGISTER_SSO = '/register/sso'
export const USER_VERIFY = '/user/verify'
export const USER_VERIFY_TOKEN = '/user/verify/:verifyToken' // NB: don't need to be logged in to call the verify email api endpoint
export const SUPPORT = '/support' // DEBUG ONLY

// invites
export const USER_ACCEPT_COMPANY_INVITE = '/invite/company/:inviteToken'
export const USER_ACCEPT_COMPANY_INVITE_BASE = '/invite/company'

// quickview
export const QUICKVIEW_BASE = '/quickview'

// viewer
export const VIEWER = '/view'
export const VIEW_PROJECT = '/view/:projectId'
export const VIEW_PROJECT_CHANNEL = '/view/:projectId/channel/:channelId'
export const VIEWER_SETTINGS = '/view/:projectId/settings'
export const VIEWER_SUPPORT = '/view/:projectId/support'

// project manager
export const PROJECT_MANAGER_SELECT = '/project'
export const PROJECT_MANAGER_VIEW = '/project/:projectId'
export const PROJECT_MANAGER_DASHBOARD = '/project/:projectId/dashboard'
export const PROJECT_MANAGER_GROUPS = '/project/:projectId/groups'
export const PROJECT_MANAGER_USERS = '/project/:projectId/users'
export const PROJECT_MANAGER_CHANNELS = '/project/:projectId/channels'
export const PROJECT_MANAGER_PROGRAMS = '/project/:projectId/programs'
export const PROJECT_MANAGER_CARDS = '/project/:projectId/cards'
export const PROJECT_MANAGER_NOTICES = '/project/:projectId/notices'
export const PROJECT_MANAGER_ACTIVITY = '/project/:projectId/activity'
export const PROJECT_MANAGER_SETTINGS = '/project/:projectId/settings'
export const PROJECT_MANAGER_SETTINGS_WATERMARK = '/project/:projectId/settings/watermark'
export const PROJECT_MANAGER_BROADCAST = '/project/:projectId/broadcast'
export const PROJECT_MANAGER_SUPPORT = '/project/:projectId/support'

// company manager
// NB: the companyId is NOT controlled via an id in the url like projects
// NB: instead its changed via a drop down & loaded from localStorage
export const COMPANY_MANAGER = '/company'
// export const COMPANY_MANAGER_DASHBOARD = '/company/dashbaord'
export const COMPANY_MANAGER_PROJECTS = '/company/projects'
export const COMPANY_MANAGER_CARDS = '/company/cards'
export const COMPANY_MANAGER_USERS = '/company/users'
export const COMPANY_MANAGER_VIDEO_ENGINES = '/company/videoengines/:veKey?'
export const COMPANY_MANAGER_ACTIVITY = '/company/activity'
export const COMPANY_MANAGER_SETTINGS = '/company/settings'
export const COMPANY_MANAGER_SUPPORT = '/company/support'

// god admin
export const ADMIN = '/admin'
export const ADMIN_COMPANIES = '/admin/companies'
export const ADMIN_ACTIVITY = '/admin/activity'
export const ADMIN_SETTINGS = '/admin/settings'
// god admin - streamhub
export const ADMIN_STREAMHUB = '/admin/streamhub/:serverKey?'
export const ADMIN_STREAMHUB_STREAMS = '/admin/streamhub/:serverKey/streams'
export const ADMIN_STREAMHUB_SOURCES = '/admin/streamhub/:serverKey/sources'
export const ADMIN_STREAMHUB_PROCESSES = '/admin/streamhub/:serverKey/processes'
export const ADMIN_STREAMHUB_ASSETS = '/admin/streamhub/:serverKey/assets'
// god admin - video engines
export const ADMIN_VIDEO_ENGINES = '/admin/videoengines/:veKey?'

// user account
export const ACCOUNT = '/account'
export const ACCOUNT_PROFILE = ACCOUNT + '/profile'
export const ACCOUNT_PROFILE_EDIT_NAME = ACCOUNT_PROFILE + '/name'
export const ACCOUNT_PROFILE_EDIT_EMAIL = ACCOUNT_PROFILE + '/email'
export const ACCOUNT_PROFILE_VERIFY_PHONE = ACCOUNT_PROFILE + '/verify-phone'
export const ACCOUNT_SETTINGS = ACCOUNT + '/settings'
export const ACCOUNT_SETTINGS_2FA = ACCOUNT_SETTINGS + '/2fa'
export const ACCOUNT_SETTINGS_SESSIONS = ACCOUNT_SETTINGS + '/sessions'

// other

// external
export const EXTERNAL_SUPPORT_URL = 'https://support.reprostream.com'

// helpers

export const getProjectRoute = (path, projectId) => {
  return path.replace(':projectId', '' + projectId)
}

export const getProjectChannelRoute = (path, projectId, channelId) => {
  return path.replace(':projectId', '' + projectId).replace(':channelId', '' + channelId)
}

export const formatBreadcrumbRootTitle = (selectionName, sectionTitle) => {
  return BREADCRUMB_ROOT_SHOW_SELECTION_NAME ? selectionName : sectionTitle
}
