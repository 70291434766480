import React from 'react'

import { withProjectAdminContext, IProjectAdminMultiContext } from 'src/core/providers'

import { Group, Program, GroupProgramOperation } from 'src/core/models'

import ArkButton from 'src/core/components/ArkButton'
import ArkDataMappingForm, { ArkDataMappingItemChanges, ArkManagerFilteredItem } from 'src/core/components/ArkDataMappingForm/ArkDataMappingForm'
import ArkHighlightedText from 'src/core/components/ArkHighlightedText'
import ArkManagerFilterForm from 'src/core/components/ArkManagerListView/ArkManagerFilterForm'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { OBJECT_GROUP_NAME, OBJECT_PROGRAM_NAME_PLURAL } from 'src/constants/strings'

import dataMappingFormStyles from 'src/core/components/ArkDataMappingForm/ArkDataMappingForm.module.css'

interface IProps extends IProjectAdminMultiContext {
  companyId: number
  projectId: number
  group: Group
  onChange?: Function
}
interface IState {

  loading: boolean
  groupPrograms: Array<Program>
  projectPrograms: Array<Program>

  filteredProjectPrograms?: Array<ArkManagerFilteredItem<Program>>
  filter?: string

  selectedIds: Array<number>

  savedIds?: Array<number> // had an opertaion save/submit ok (e.g. add/del)
  saveErrors?: Map<number, any>

  showForm: boolean
  isSubmitting: boolean
  hasUpdated: boolean
  error?: Error
}

class ProjectGroupProgramsPanel extends React.Component<IProps, IState> {
  _isMounted: boolean = false

  constructor (props: IProps) {
    super(props)
    this.state = {
      loading: false,
      groupPrograms: [], // only programs mapped to this group
      projectPrograms: [], // all programs within the project
      filteredProjectPrograms: undefined,
      filter: undefined,
      selectedIds: [],
      showForm: false,
      isSubmitting: false,
      hasUpdated: false
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  componentDidUpdate (prevProps: IProps) {
    if (this.props.group.id !== prevProps.group.id) {
      this.resetView()
    }
  }

  render () {
    const { companyId, projectId, group } = this.props
    const { loading, showForm, isSubmitting, hasUpdated, error, projectPrograms, selectedIds, filteredProjectPrograms, filter } = this.state
    if (!group) return (<></>)
    return (
      <ArkPanel bordered={showForm} title={showForm ? OBJECT_GROUP_NAME + ' ' + OBJECT_PROGRAM_NAME_PLURAL : null}>

        {!showForm && (
          <ArkButton type="button" fluid size="large" loading={loading} onClick={this.onShowForm}>EDIT {OBJECT_GROUP_NAME} {OBJECT_PROGRAM_NAME_PLURAL}</ArkButton>
        )}

        {showForm && (
          <>
            <ArkDataMappingForm
              id="group-programs"
              // title="Group Programs"
              sourceItems={projectPrograms /* .map(projectProgram => { return { id: projectProgram.id, title: projectProgram.name } }) */}
              mappedIds={selectedIds}
              isLoading={loading}
              isSaving={isSubmitting}
              successMessage={hasUpdated && 'The group programs have been updated' }{/* TODO: add details of how many where added/updated/removed from the chhanel? */...[]}
              errorMessage={error && error.message}
              onCancel={() => { this.resetView() }}
              onSave={(mappedIds?: Array<number>, changes?: ArkDataMappingItemChanges) => {
                console.log('ProjectGroupProgramsPanel - ArkDataMappingForm - onSave - mappedIds: ', mappedIds, ' changes: ', changes)
                if (mappedIds && changes) {
                  this.saveChanges(companyId, projectId, group, mappedIds, changes)
                }
              }}
              // filtering & custom item rendering support:
              filterForm={this.renderProjectProgramFilterForm()}
              filterText={filter}
              filteredSourceItems={filteredProjectPrograms}
              itemCompare={(newItem: Program, oldItem: Program) => {
                return newItem.id === oldItem.id && newItem.name === oldItem.name
              }}
              itemRow={(program: Program, _isMapped: boolean) => {
                return <ArkHighlightedText highlight={filter} text={program.name}/>
              }}
              onClearFilter={() => this.filterProjectPrograms('')}
            />
            <ArkSpacer />
          </>
        )}

      </ArkPanel>
    )
  }

  // -------

  renderProjectProgramFilterForm = () => {
    const { filter } = this.state
    return (
      <ArkManagerFilterForm
        autoComplete={false}
        className={dataMappingFormStyles.filterForm}
        filterTitle='Filter by name'
        filterValue={filter ?? ''}
        onFilterChange={(filter: string) => {
          this.filterProjectPrograms(filter)
        }}
      />
    )
  }

  // -------

  resetView = () => {
    this.resetForm()
    this.setState({ showForm: false })
  }

  resetForm = () => {
    if (this.state.isSubmitting) return // don't allow resetting while submitting
    this.setState({
      isSubmitting: false,
      hasUpdated: false,
      error: undefined,
      savedIds: undefined,
      saveErrors: undefined
    })
  }

  // -------

  onShowForm = async () => {
    console.log('ProjectGroupProgramsPanel - onShowForm')
    await this.loadProgramssData() // TESTING: moved this to only trigger when the form should show (avoid making api queries till we need the data)
    this.setState({ showForm: true, error: undefined })
  }

  onHideForm = () => {
    console.log('ProjectGroupProgramsPanel - onHideForm')
    this.setState({ showForm: false, error: undefined })
  }

  // -------

  saveChanges = async (companyId: number, projectId: number, group: Group, selectedIds: Array<number>, selectedIdChanges: ArkDataMappingItemChanges) => {
    this.setState({
      isSubmitting: true,
      hasUpdated: false,
      error: undefined,
      savedIds: undefined,
      saveErrors: undefined
    })

    // convert the list off adds & dels to an operations list in the format the api expects
    const operations = this.generateGroupProgramUpdateOperations(group.id, selectedIdChanges)
    console.log('ProjectGroupProgramsPanel - saveChanges - operations: ', operations)

    if (operations.length === 0) {
      console.log('ProjectGroupProgramsPanel - saveChanges - 0 operations - HALT')
      this.setState({ isSubmitting: false, hasUpdated: false }) // TODO: show an error/warning?
      return
    }

    let savedIds: Array<number> | undefined
    let newSelectedIds: Array<number> | undefined
    let saveErrors: Map<number, any> | undefined
    let hasUpdated = false
    let error: Error | undefined

    try {
      // run all the add & delete mapping operations
      const result = await this.props.projectAdminContext.actions.updateProjectGroupPrograms(companyId, projectId, group.id, operations)
      console.log('ProjectGroupProgramsPanel - saveChanges - updateProjectGroupPrograms - result: ', result, ' typeof: ', typeof result, ' instanceof Map: ', result instanceof Map)

      if (result === true) { // all operations succeeded
        if (this._isMounted) {
          hasUpdated = true
          savedIds = operations.map(operation => operation.program_id)
          newSelectedIds = selectedIds
        }
      } else if (result instanceof Map) { // 1 or more operations had errors (but some may have still succeeded)
        // all operations failed
        if (result.size === operations.length) {
          hasUpdated = false
        } else { // only some operations failed
          hasUpdated = true // set true if some added/updated ok
          savedIds = [] // get a list of successful operations
          for (const operation of operations) {
            if (result.has(operation.program_id) === false) {
              savedIds.push(operation.program_id)
            }
          }
          newSelectedIds = selectedIds.filter((selectedId) => !result.has(selectedId))
        }
        saveErrors = result // the result contains errors for each operation that failed
        error = { message: 'A problem occurred saving one or more of the changes, please try again.' } as any
      } else { // general/fallback error (with the whole api call)
        hasUpdated = false
        error = { message: 'A problem occurred saving the changes, please try again.' } as any
      }

      console.log('ProjectGroupProgramsPanel - saveChanges - hasUpdated: ', hasUpdated, ' savedIds: ', savedIds, ' newSelectedIds: ', newSelectedIds)
      if (this._isMounted) {
        this.setState({ isSubmitting: false, hasUpdated, savedIds, selectedIds: newSelectedIds ?? [], saveErrors, error })
      }
      if (hasUpdated) {
        if (this.props.onChange) this.props.onChange()
      }
    } catch (error) {
      if (this._isMounted) {
        this.setState({ isSubmitting: false, hasUpdated, savedIds, saveErrors, error: error as Error })
      }
    }
  }

  // -------

  loadProgramssData = async () => {
    console.log('ProjectGroupProgramsPanel - loadProgramssData')

    await this.loadProjectPrograms()
    await this.loadGroupPrograms()
  }

  loadProjectPrograms = async () => {
    if (this.state.loading === true) return false
    const companyId = this.props.companyId
    const projectId = this.props.projectId
    if (companyId && projectId) {
      try {
        this.setState({ loading: true })
        const programs = await this.props.projectAdminContext.actions.getAllCompanyProjectPrograms(companyId, projectId)
        this.setState({
          loading: false,
          projectPrograms: programs || []
        })
      } catch (error) {
        console.error('ProjectGroupProgramsPanel - loadProjectPrograms - error: ', error)
        this.setState({
          loading: false,
          projectPrograms: []
          // TODO: add an error prop & display an error message if this happens
        })
      }
    }
  }

  loadGroupPrograms = async () => {
    console.log('ProjectGroupProgramsPanel - loadGroupChannels - this.state.loading: ', this.state.loading)
    if (this.state.loading === true) return false
    const companyId = this.props.companyId
    const projectId = this.props.projectId
    const groupId = this.props.group.id
    if (companyId && projectId && groupId) {
      try {
        this.setState({ loading: true })
        const programs = await this.props.projectAdminContext.actions.getProjectGroupPrograms(companyId, projectId, groupId)

        // TESTING: re-set the selectedIds to show the mappings saved from the api
        // NB: this will override any edits/changes to the form selection by the program, if this gets called after initial load
        const selectedIds: Array<number> = []
        if (programs) {
          for (const groupProgram of programs) {
            selectedIds.push(groupProgram.id)
          }
        }
        console.log('ProjectGroupProgramsPanel - loadGroupChannels - selectedIds: ', selectedIds)

        this.setState({
          loading: false,
          groupPrograms: programs || [],
          selectedIds
        })
      } catch (error) {
        console.error('ProjectGroupProgramsPanel - loadChannels - error: ', error)
        this.setState({
          loading: false,
          groupPrograms: []
          // TODO: add an error prop & display an error message if this happens
        })
      }
    }
  }

  // -------

  filterProjectPrograms = (_filter: string) => {
    const { loading, projectPrograms } = this.state // selectedIds
    if (loading) return
    // NB: we currently filter out items even if they're enabled/mapped, but when you submit they will still submit as enabled/mapped (we just don't visually show them for now)
    // TODO: should we show filtered out items that are enabled/mapped in some way, to make it obvious they'll remain selected when you save the mapping form?
    const filter = _filter.length > 0 ? _filter : undefined
    const filteredProjectPrograms = filter
      ? projectPrograms.reduce<Array<ArkManagerFilteredItem<Program>>>((r, program) => {
        let nameMatch = false
        if (program.name.toLowerCase().includes(filter.toLowerCase())) {
          nameMatch = true
        }
        if (nameMatch) {
          const matchingFields: Array<string> = []
          if (nameMatch) matchingFields.push('name')
          const filteredItem: ArkManagerFilteredItem<Program> = {
            item: program,
            matchingFields
          }
          r.push(filteredItem)
        }
        return r
      }, [] as Array<ArkManagerFilteredItem<Program>>)
      : undefined
    this.setState({ filter, filteredProjectPrograms })
  }

  clearFilteredProjectPrograms = () => {
    this.setState({ filter: undefined, filteredProjectPrograms: undefined })
  }

  // -------

  // TESTING: takes the result of ArkDataMappingItemChanges (additions & deletions) & converts it to the format the api endpoint expects
  // TODO: move to the relevant api class so its re-usable else-where...
  generateGroupProgramUpdateOperations = (groupId: number, itemChanges: ArkDataMappingItemChanges) => {
    const addOperations: Array<GroupProgramOperation> = []
    const delOperations: Array<GroupProgramOperation> = []
    for (const addItem of itemChanges.add) {
      addOperations.push({
        operation: 0, // 0 === ADD
        group_id: groupId,
        program_id: addItem
      })
    }
    for (const delItem of itemChanges.del) {
      delOperations.push({
        operation: 2, // 2 == DELETE
        group_id: groupId,
        program_id: delItem
      })
    }
    return [...delOperations, ...addOperations]
  }
}

export default withProjectAdminContext(ProjectGroupProgramsPanel)
