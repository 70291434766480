import React, { ReactNode, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { Label } from 'semantic-ui-react'
import useResizeObserver, { ObservedSize } from 'use-resize-observer'

import {
  AUDIO_LEVELS_ENABLED,
  PROGRAM_CONTROLS_AUTOHIDE_ENABLED,
  PROGRAM_CONTROLS_AUTOHIDE_IDLE_DELAY,
  PROGRAM_CONTROLS_COMPACT_SIZE,
  PROGRAM_CONTROLS_SHOW_DEBUG_INFO
} from 'src/constants/config'
import ArkAudioMeterToolbar from 'src/core/components/ArkAudioMeterToolbar'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkPlayerBadge from 'src/core/components/ArkPlayerBadge'
import ArkSpacer from 'src/core/components/ArkSpacer'
import ArkVolumeInput from 'src/core/components/ArkVolumeInput'
import { Program } from 'src/core/models'

import styles from './ProgramControls.module.css'
import ProgramVolumePopup from './ProgramVolumePopup'
import { formatTime } from './utilities'

// FIXME rename "active" to "selected"

interface ProgramControlsProps {
  audioLevelPostFader: number;
  audioLevelPostFaderDisabled: boolean
  audioLevelPreFader: number;
  autohide: boolean
  autoSolo: boolean
  containerRef: React.RefObject<HTMLDivElement>
  debugInfo: string[]
  fps: number | undefined
  fullscreen: boolean
  hidden: boolean
  mute: boolean
  noAudio: boolean
  onAudioLevelsClick: () => void
  onFullscreenClick: () => void
  onMuteClick: () => void
  onPauseClick: () => void
  onVolumeChange: (value: number) => void
  onVolumePopupOpenChange: (value: boolean) => void // FIXME "onOpen" & "onClose" instead
  optionsComponent: ReactNode
  paused: boolean
  program: Program
  resolution: string | undefined
  solo: boolean
  threeSixty: boolean
  time: number
  volume: number
  volumePopupOpen: boolean;
}

const ProgramControls = (props: ProgramControlsProps) => {
  const {
    audioLevelPostFader,
    audioLevelPostFaderDisabled,
    audioLevelPreFader,
    autohide,
    autoSolo,
    containerRef,
    debugInfo,
    fps,
    fullscreen,
    hidden,
    mute,
    noAudio,
    onAudioLevelsClick,
    onFullscreenClick,
    onMuteClick,
    onPauseClick,
    onVolumeChange,
    onVolumePopupOpenChange,
    optionsComponent,
    paused,
    program,
    resolution,
    solo,
    threeSixty,
    time,
    volume,
    volumePopupOpen
  } = props

  const controlsRef = useRef<HTMLDivElement>(null)
  const isMouseEnteredRef = useRef<boolean>(false)
  const mouseMoveTimeRef = useRef<number>(0)

  const [controlsWidth, setControlsWidth] = useState<number>(0)
  const [show, setShow] = useState<boolean>(!PROGRAM_CONTROLS_AUTOHIDE_ENABLED || !autohide)

  useResizeObserver<HTMLDivElement>({
    onResize: _.debounce((size: ObservedSize) => size.width && setControlsWidth(size.width)),
    ref: controlsRef
  })

  /**
   * autohide
   */
  useEffect(() => {
    if (!PROGRAM_CONTROLS_AUTOHIDE_ENABLED || !autohide || hidden || volumePopupOpen) return

    const onMouseEnter = () => {
      isMouseEnteredRef.current = true
      setShow(true)
    }

    const onMouseLeave = () => {
      isMouseEnteredRef.current = false
      setShow(false)
    }

    const onMouseMove = _.throttle(() => {
      if (!isMouseEnteredRef.current) return
      mouseMoveTimeRef.current = Date.now()
      setShow(true)
    }, 500)

    containerRef.current?.addEventListener('mouseenter', onMouseEnter)
    containerRef.current?.addEventListener('mouseleave', onMouseLeave)
    containerRef.current?.addEventListener('mousemove', onMouseMove)

    const timer = setInterval(() => {
      const elapsed = Date.now() - mouseMoveTimeRef.current
      if (elapsed > PROGRAM_CONTROLS_AUTOHIDE_IDLE_DELAY) setShow(false)
    }, 250)

    return () => {
      containerRef.current?.removeEventListener('mouseenter', onMouseEnter)
      containerRef.current?.removeEventListener('mouseleave', onMouseLeave)
      containerRef.current?.removeEventListener('mousemove', onMouseMove)
      clearInterval(timer)
    }
  }, [autohide, containerRef.current, hidden, volumePopupOpen])

  if (hidden) return null

  const isCompact: boolean = controlsWidth > 0 && controlsWidth < PROGRAM_CONTROLS_COMPACT_SIZE

  return (
    <div className={`${styles.controls} ${show ? styles.show : ''}`} ref={controlsRef}>
      <div className={styles.topBar}>
        <div className={styles.programName}>{program.name}</div>
      </div>
      <div className={styles.topRight}>
        {autoSolo && <Label circular color='green' size='tiny'>AUTO</Label>}
        {solo && (
          <>
            <ArkSpacer small />
            <Label circular color='green' size='tiny'>SOLO</Label>
          </>
        )}
        <ArkIconButton name={fullscreen ? 'fullscreen-off' : 'fullscreen-on'} onClick={onFullscreenClick} size={24} />
      </div>
      {PROGRAM_CONTROLS_SHOW_DEBUG_INFO && (
        <div className={styles.debugInfo}>{_.map(debugInfo, (item, index) => <div key={index}>{item}</div>)}</div>
      )}
      <div className={styles.bottomBar}>
        <ArkIconButton
          data-test-id="ark-program-pause-btn" // e2e testing identifier
          name={paused ? 'play-circle' : 'pause-circle'}
          onClick={onPauseClick}
          size={24}
        />
        <div className={styles.time}>{formatTime(time)}</div>
        <ArkSpacer grow />
        {resolution && (
          <>
            <ArkPlayerBadge title='RES'>{resolution}</ArkPlayerBadge>
            <ArkSpacer small />
          </>
        )}
        {threeSixty && (
          <>
            <ArkPlayerBadge >360°</ArkPlayerBadge>
            <ArkSpacer small />
          </>
        )}
        {fps !== undefined && (
          <>
            <ArkPlayerBadge title='FPS'>{fps}</ArkPlayerBadge>
            <ArkSpacer small />
          </>
        )}

        {optionsComponent}
        <ArkIconButton
          active={mute}
          activeColor='var(--red)'
          name='volume-mute'
          onClick={onMuteClick}
          size={24}
          width={35}
        />
        {isCompact
          ? (
            <ProgramVolumePopup
              onOpenChange={onVolumePopupOpenChange}
              onValueChange={onVolumeChange}
              open={volumePopupOpen}
              value={volume}
            />
          )
          : (
            <>
              <ArkSpacer small />
              <ArkVolumeInput onChange={onVolumeChange} value={volume} />
              <ArkSpacer small />
            </>
          )}
        {AUDIO_LEVELS_ENABLED && (
          <ArkIconButton onClick={onAudioLevelsClick} width={35}>
            <ArkAudioMeterToolbar
              color='var(--yellow)'
              disabled={noAudio}
              value={audioLevelPreFader}
            />
            <ArkSpacer small />
            <ArkAudioMeterToolbar
              color='var(--green)'
              disabled={audioLevelPostFaderDisabled}
              value={audioLevelPostFader}
            />
          </ArkIconButton>
        )}
        <ArkSpacer small />
      </div>
    </div>
  )
}

export default ProgramControls
