import { BaseModel } from './base_model'
import { ActionPermissions, CompanyTranscoderSettings, ProjectCounts, ProjectTranscoderSettings, ProjectVideoEngine, UserProjectRole } from './'
import { ROLE_ADMIN_NAME, ROLE_MEMBER_NAME } from 'src/constants/strings'

export const PROJECT_NO_VIDEO_ENGINE_ID = -1
const PROJECT_VIDEO_ENGINE_DUMMY_STATUS = true // NB: temp dummy video engine enabled/disabled status value until the api provides this data

export interface IProjectUpdateData {
  name?: string
  desc?: string
  force2fa?: boolean // TODO: should we allow/handle this via the main project update call or via dedicated handling instead (& so remove this if so)?
  videoEngineId?: number
  maxUsers?: number // site-admin only?
  // TODO: other updatable fields...
}
export interface IProjectAddData extends IProjectUpdateData {
  name: string // project name is required when adding
  desc?: string
  // TODO: any other required fields needed when adding a project?
}

export class Project extends BaseModel {
  // base/core project fields
  id: number
  name: string
  desc?: string

  // the project version this project was last saved with/at (so the api can detect projects that need updating to a new version when one becomes available)
  projectConfigVersion: number

  // users
  projectMaxUsers: number // max users allowed for this project

  // stream engine 'application' values (to control protocol support at a per project level)
  projectAppId?: string // managers+ only (not returned for the viewer endpoint(s), so made it optional)
  projectAppLogin?: string // managers+ only
  projectAppPass?: string // managers+ only

  // access
  force2fa: boolean
  actionPermissions?: ActionPermissions

  // transcoder settings & status
  // NB: the project `transcoderSynced` value can == false when certain company/org level settings have been changed..
  //     ..& an 'all projects' or 'per project' sync has yet to be run..
  //     ..company level fields that can require manual syncing: flag_allow_abr1080, flag_allow_abr720, flag_allow_watermarking
  transcoderSynced: boolean
  transcoderSettings?: ProjectTranscoderSettings
  companyTranscoderSettings?: CompanyTranscoderSettings

  // a summary of the number of channels, groups & programs within this project
  counts?: ProjectCounts

  // assigned video engine server for this project (NB: this can be undefined if none is currently assigned)
  videoEngine?: ProjectVideoEngine

  constructor (
    id: number,
    name: string,
    desc: string | undefined = undefined,
    projectConfigVersion: number,
    projectMaxUsers: number,
    projectAppId?: string,
    projectAppLogin?: string,
    projectAppPass?: string,
    force2fa: boolean = false,
    actionPermissions?: ActionPermissions,
    transcoderSynced: boolean = false,
    transcoderSettings?: ProjectTranscoderSettings,
    companyTranscoderSettings?: CompanyTranscoderSettings,
    counts?: ProjectCounts,
    videoEngine?: ProjectVideoEngine
  ) {
    super()
    this.id = id
    this.name = name
    this.desc = desc
    this.projectConfigVersion = projectConfigVersion
    this.projectMaxUsers = projectMaxUsers
    this.projectAppId = projectAppId
    this.projectAppLogin = projectAppLogin
    this.projectAppPass = projectAppPass
    this.force2fa = force2fa
    this.actionPermissions = actionPermissions
    this.transcoderSynced = transcoderSynced
    this.transcoderSettings = transcoderSettings
    this.companyTranscoderSettings = companyTranscoderSettings
    this.counts = counts
    this.videoEngine = videoEngine
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (id: number, json: any) : Project | null {
    if (!json) return null
    const actionPermissions = ActionPermissions.fromJSON(json) ?? undefined
    const transcoderSettings = ProjectTranscoderSettings.fromJSON(json) ?? undefined
    const companyTranscoderSettings = json.company !== undefined ? CompanyTranscoderSettings.fromJSON(json.company) ?? undefined : undefined
    const counts = ProjectCounts.fromJSON(json) ?? undefined
    let videoEngine: ProjectVideoEngine | undefined
    if (json.streaming_server) {
      videoEngine = ProjectVideoEngine.fromJSON(json.streaming_server) ?? undefined
    } else if (json.streaming_server_id !== undefined) { // LEGACY: fallback to early beta versions of the MVE api project response which only specified the video engine id
      videoEngine = new ProjectVideoEngine(json.streaming_server_id, 'N/A', PROJECT_VIDEO_ENGINE_DUMMY_STATUS)
    }
    console.log('Project - fromJSON - videoEngine:', videoEngine)
    return new Project(
      id,
      json.project_name,
      json.info,
      json.config_version,
      json.max_users,
      json.app_id,
      json.app_login,
      json.app_password,
      json.enforce_2fa,
      actionPermissions,
      json.is_synchronized,
      transcoderSettings,
      companyTranscoderSettings,
      counts,
      videoEngine
    )
    // TODO: streaming_server_id <<<<
  }

  static propertyToJSONKeyMap () {
    return {
      id: 'id',
      name: 'project_name',
      desc: 'info',
      force2fa: 'enforce_2fa',
      projectMaxUsers: 'max_users',
      // TODO: update for `streaming_server` object (instead of just the id)...
      videoEngineId: 'streaming_server_id'
      // TODO: other properties? (NB: only editable properties need adding here??)
    }
  }
}

export class UserProject extends Project {
  userProjectRole?: UserProjectRole
  userAccessEnabled: boolean

  constructor (
    // Project
    id: number,
    name: string,
    desc: string | undefined = undefined,
    projectConfigVersion: number,
    projectMaxUsers: number,
    projectAppId?: string,
    projectAppLogin?: string,
    projectAppPass?: string,
    force2fa: boolean = false,
    actionPermissions?: ActionPermissions,
    transcoderSynced: boolean = false,
    transcoderSettings?: ProjectTranscoderSettings,
    companyTranscoderSettings?: CompanyTranscoderSettings,
    counts?: ProjectCounts,
    videoEngine?: ProjectVideoEngine,
    // UserProject
    userProjectRole?: UserProjectRole,
    userAccessEnabled: boolean = false
  ) {
    super(id, name, desc, projectConfigVersion, projectMaxUsers, projectAppId, projectAppLogin, projectAppPass, force2fa, actionPermissions, transcoderSynced, transcoderSettings, companyTranscoderSettings, counts, videoEngine)
    this.userProjectRole = userProjectRole
    this.userAccessEnabled = userAccessEnabled
  }

  static fromJSON (id: number, json: any) : UserProject | null {
    if (!json) return null
    const actionPermissions = ActionPermissions.fromJSON(json) ?? undefined
    const transcoderSettings = ProjectTranscoderSettings.fromJSON(json) ?? undefined
    const companyTranscoderSettings = json.company !== undefined ? CompanyTranscoderSettings.fromJSON(json.company) ?? undefined : undefined
    const counts = ProjectCounts.fromJSON(json) ?? undefined
    let videoEngine: ProjectVideoEngine | undefined
    if (json.streaming_server) {
      videoEngine = ProjectVideoEngine.fromJSON(json.streaming_server) ?? undefined
    } else if (json.streaming_server_id !== undefined) { // LEGACY: fallback to early beta versions of the MVE api project response which only specified the video engine id
      videoEngine = new ProjectVideoEngine(json.streaming_server_id, 'N/A', PROJECT_VIDEO_ENGINE_DUMMY_STATUS)
    }
    console.log('UserProject - fromJSON - videoEngine:', videoEngine)
    return new UserProject(
      // Project
      id,
      json.project_name,
      json.info,
      json.config_version,
      json.max_users,
      json.app_id,
      json.app_login,
      json.app_password,
      json.enforce_2fa,
      actionPermissions,
      json.is_synchronized,
      transcoderSettings,
      companyTranscoderSettings,
      counts,
      videoEngine,
      // UserProject
      json.project_role?.id ?? UserProjectRole.unknown,
      json.access_enabled
    )
  }

  static propertyToJSONKeyMap () {
    return {
      ...Project.propertyToJSONKeyMap()
      // TODO: any UserProject specific fields need adding?
    }
  }

  static userProjectRoleName (userProjectRole?: UserProjectRole) {
    if (userProjectRole) {
      switch (userProjectRole) {
        case UserProjectRole.admin: return ROLE_ADMIN_NAME
        case UserProjectRole.member: return ROLE_MEMBER_NAME
      }
    }
    return 'UNKNOWN'
  }
}

export enum ProjectInviteUserType { user, email }
export interface ProjectInviteUser {
  type: ProjectInviteUserType
  userId?: number
  email?: string
  role?: UserProjectRole
}
export interface ProjectInviteUserResult extends ProjectInviteUser {
  result: boolean
  error?: Error
}

export interface ProjectAddGuestUser {
  name?: string
  desc?: string
}
