import _ from 'lodash'

import { Card, Image, Notice } from 'src/core/models'
import { DEFAULT_COMPANY_CARD, DEFAULT_PROJECT_CARD } from 'src/core/models/card'
import { DEFAULT_IMAGE } from 'src/core/models/image'
import { DEFAULT_NOTICE } from 'src/core/models/notice'

export const MOCK_IMAGES: Image[] = [
  {
    ...DEFAULT_IMAGE,
    id: 1,
    file_name_original: 'Testcard_F.jpg',
    url: 'https://upload.wikimedia.org/wikipedia/en/5/52/Testcard_F.jpg'
  },
  {
    ...DEFAULT_IMAGE,
    id: 2,
    file_name_original: 'PM5544_with_non-PAL_signals.png',
    url: 'https://upload.wikimedia.org/wikipedia/commons/c/c4/PM5544_with_non-PAL_signals.png'
  }
]

export const MOCK_CARDS: Card[] = [
  {
    ...DEFAULT_COMPANY_CARD,
    id: 1,
    name: 'Card A',
    description: 'Lorem ipsum dolor sit amet',
    background: _.find(MOCK_IMAGES, { id: 1 })!
  },
  {
    ...DEFAULT_COMPANY_CARD,
    id: 2,
    permission_all_projects: false,
    permission_specific_projects: [30], // card test a
    name: 'Card B',
    description: 'Lorem ipsum dolor sit amet',
    background: _.find(MOCK_IMAGES, { id: 2 })!
  },
  {
    ...DEFAULT_PROJECT_CARD,
    id: 3,
    project_id: 31, // card test b
    name: 'Card C',
    description: 'Lorem ipsum dolor sit amet'
  }
]

export const MOCK_NOTICES: Notice[] = [
  {
    ...DEFAULT_NOTICE,
    id: 1,
    project_id: 17, // james test project
    all_channels: true,
    name: 'Notice A',
    description: 'Lorem ipsum dolor sit amet',
    card: _.find(MOCK_CARDS, { id: 1 })!
  },
  {
    ...DEFAULT_NOTICE,
    id: 2,
    project_id: 17, // james test project
    name: 'Notice B',
    description: 'Lorem ipsum dolor sit amet'
  },
  {
    ...DEFAULT_NOTICE,
    id: 3,
    project_id: 13, // repro test project 2
    name: 'Notice C',
    description: 'Lorem ipsum dolor sit amet'
  }
]
