import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import _ from 'lodash'

import { CARDS_MOCK_API_ENABLED } from 'src/constants/config'
import {
  OBJECT_CARD_NAME,
  OBJECT_COMPANY_SHORTNAME,
  OBJECT_NOTICE_NAME,
  OBJECT_PROJECT_NAME
} from 'src/constants/strings'

import { Card, Project, UserCompany } from '../models'
import { DEFAULT_PROJECT_CARD } from '../models/card'
import ServerAPIClient from '../services/ServerAPIClient'
import { MockAPIContextValue, useMockAPI } from './MockAPIProvider'
import { IServerContext, useServer } from './ServerProvider'
import { IUserContext, useUser } from './UserProvider'

/**
 * similar to <CompanyCardProvider>. changes made here should be considered there
 *
 * interactive functions include dialog boxes (e.g. 'Are you sure you want to delete "Card A"?')
 */

export type ProjectCardContextValue = {
  clearCards: () => void,
  createCard: (props: Partial<Card>) => Promise<Card | undefined>
  createCardInteractive: (props: Partial<Card>) => Promise<Card | undefined>
  deleteCard: (cardId: number) => Promise<boolean>
  deleteCardInteractive: (cardId: number) => Promise<boolean>
  duplicateCard: (cardId: number) => Promise<Card | undefined>
  duplicateCardInteractive: (cardId: number) => Promise<Card | undefined>
  fetchCards: () => Promise<Card[] | undefined>
  fetchCardsInteractive: () => Promise<Card[] | undefined>
  getCards: () => Card[]
  getFetching: () => boolean
  updateCard: (cardId: number, props: Partial<Card>) => Promise<Card | undefined>
  updateCardInteractive: (cardId: number, props: Partial<Card>) => Promise<Card | undefined>
}

const ProjectCardContext = createContext<ProjectCardContextValue>({} as ProjectCardContextValue)

export const useProjectCard = () => useContext(ProjectCardContext)

type ProjectCardProviderProps = {
  children: ReactNode
}

const ProjectCardProvider = (props: ProjectCardProviderProps) => {
  const mockAPI: MockAPIContextValue = useMockAPI()
  const server: IServerContext = useServer()
  const user: IUserContext = useUser()

  const company: UserCompany = user.store.selectedCompany!
  const project: Project = user.store.selectedProject!
  const serverAPIClient: ServerAPIClient = server.store.apiClient!

  const [cards, setCards] = useState<Card[]>([])
  const [fetching, setFetching] = useState<boolean>(true)

  useEffect(() => {
    // console.log('ProjectCardProvider - load')
    return () => {
      // console.log('ProjectCardProvider - unload')
    }
  }, [])

  const clearCards = (): void => {
    // console.log('ProjectCardProvider - clearCards')
    setCards([])
  }

  const createCard = async (props: Partial<Card>): Promise<Card | undefined> => {
    try {
      // console.log('ProjectCardProvider - createCard - props:', props)
      let card: Card
      if (CARDS_MOCK_API_ENABLED) {
        card = { ...DEFAULT_PROJECT_CARD, ...props, id: Date.now(), project_id: project.id }
        const oldMockCards: Card[] = await mockAPI.getCards()
        const newMockCards: Card[] = [...oldMockCards, card]
        await mockAPI.setCards(newMockCards)
      } else {
        const keys: (keyof Card)[] = ['colour']
        const newProps: Partial<Card> = { ..._.pick(DEFAULT_PROJECT_CARD, keys), ...props }
        // FIXME add response type
        const response = await serverAPIClient.apiPost('/projects/card/', newProps, {
          'company-id': company.id,
          'project-id': project.id
        })
        // console.log('ProjectCardProvider - createCard - response:', response)
        // FIXME check response status
        // FIXME add type guard
        card = response.data.message
      }
      const newCards: Card[] = [...cards, card]
      setCards(newCards)
      await user.actions.refreshChannels() // refresh viewer
      return card
    } catch (error) {
      console.error('ProjectCardProvider - createCard - error:', error.message)
    }
  }

  const createCardInteractive = async (props: Partial<Card>): Promise<Card | undefined> => {
    try {
      // console.log('ProjectCardProvider - createCardInteractive - props:', props)
      const card: Card | undefined = await createCard(props)
      if (!card) throw Error('create card failed')
      // window.alert(`"${card.name}" has been created successfully.`) // FIXME add toast
      return card
    } catch (error) {
      console.error('ProjectCardProvider - createCardInteractive - error:', error.message)
      window.alert('Something went wrong.') // FIXME add toast
    }
  }

  const deleteCard = async (cardId: number): Promise<boolean> => {
    try {
      // console.log('ProjectCardProvider - deleteCard - cardId:', cardId)
      const card: Card = _.find(cards, { id: cardId })!
      if (CARDS_MOCK_API_ENABLED) {
        const oldMockCards: Card[] = await mockAPI.getCards()
        const newMockCards: Card[] = _.without(oldMockCards, card)
        await mockAPI.setCards(newMockCards)
      } else {
        // FIXME add response type
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await serverAPIClient.apiDelete(`/projects/card/${cardId}`, {}, {
          'company-id': company.id,
          'project-id': project.id
        })
        // console.log('ProjectCardProvider - deleteCard - response:', response)
        // FIXME check response status
      }
      const newCards: Card[] = _.without(cards, card)
      setCards(newCards)
      await user.actions.refreshChannels() // refresh viewer
      return true
    } catch (error) {
      console.error('ProjectCardProvider - deleteCard - error:', error.message)
      return false
    }
  }

  const deleteCardInteractive = async (cardId: number): Promise<boolean> => {
    try {
      // console.log('ProjectCardProvider - deleteCardInteractive - cardId:', cardId)
      const card: Card = _.find(cards, { id: cardId })!
      const message: string = _.some(card.notices)
        ? `WARNING: "${card.name}" is used by ${_.size(card.notices)} ${OBJECT_NOTICE_NAME}(s). Are you sure you want to delete it?`
        : `Are you sure you want to delete "${card.name}"?`
      if (!window.confirm(message)) return false // FIXME add custom dialog box
      const result: boolean = await deleteCard(cardId)
      if (!result) throw Error('delete card failed')
      // window.alert(`"${card.name}" has been deleted successfully.`) // FIXME add toast
      return true
    } catch (error) {
      console.error('ProjectCardProvider - deleteCardInteractive - error:', error.message)
      window.alert('Something went wrong.') // FIXME add toast
      return false
    }
  }

  const duplicateCard = async (cardId: number): Promise<Card | undefined> => {
    try {
      // console.log('ProjectCardProvider - duplicateCard - cardId:', cardId)
      const oldCard: Card | undefined = _.find(cards, { id: cardId })!
      const keys: (keyof Card)[] = [
        'background_opacity',
        'background_size',
        'colour',
        'colour_scheme',
        'description',
        'footer',
        'message',
        'title'
      ]
      const props: Partial<Card> = {
        ..._.pick(oldCard, keys),
        ...(oldCard.background && { background_id: oldCard.background.id }),
        name: oldCard.entity_type === 'COMPANY'
          ? `${oldCard.name} ${OBJECT_PROJECT_NAME} copy`
          : `${oldCard.name} copy`
      }
      const newCard: Card | undefined = await createCard(props)
      if (!newCard) throw Error('create card failed')
      return newCard
    } catch (error) {
      console.error('ProjectCardProvider - duplicateCard - error:', error.message)
    }
  }

  const duplicateCardInteractive = async (cardId: number): Promise<Card | undefined> => {
    try {
      // console.log('ProjectCardProvider - duplicateCardInteractive - cardId:', cardId)
      const oldCard: Card | undefined = _.find(cards, { id: cardId })!
      const message: string = oldCard.entity_type === 'COMPANY'
        ? `"${oldCard.name}" is an ${OBJECT_COMPANY_SHORTNAME} ${OBJECT_CARD_NAME}. Are you sure you want to duplicate it? This will make a ${OBJECT_PROJECT_NAME} copy.`
        : `Are you sure you want to duplicate "${oldCard.name}"?`
      if (!window.confirm(message)) return // FIXME add custom dialog box
      const newCard: Card | undefined = await duplicateCard(cardId)
      if (!newCard) throw Error('duplicate card failed')
      // window.alert(`"${newCard.name}" has been created successfully.`) // FIXME add toast
      return newCard
    } catch (error) {
      console.error('ProjectCardProvider - duplicateCardInteractive - error:', error.message)
      window.alert('Something went wrong.') // FIXME add toast
    }
  }

  // fetches project cards & company cards
  const fetchCards = async (): Promise<Card[] | undefined> => {
    try {
      // console.log('ProjectCardProvider - fetchCards')
      setCards([])
      setFetching(true)
      let newCards: Card[]
      if (CARDS_MOCK_API_ENABLED) {
        const mockCards: Card[] = await mockAPI.getCards()
        newCards = _.filter(mockCards,
          card =>
            (card.entity_type === 'PROJECT' && card.project_id === project.id) ||
            card.permission_all_projects ||
            _.includes(card.permission_specific_projects, project.id)
        )
      } else {
        // FIXME add response type
        const response = await serverAPIClient.apiGet('/projects/card', {
          'company-id': company.id,
          'project-id': project.id
        })
        // console.log('ProjectCardProvider - fetchCards - response:', response)
        // FIXME check response status
        // FIXME add type guard
        newCards = response.data.message
      }
      setCards(newCards)
      setFetching(false)
      return newCards
    } catch (error) {
      console.error('ProjectCardProvider - fetchCards - error:', error.message)
    }
  }

  const fetchCardsInteractive = async (): Promise<Card[] | undefined> => {
    try {
      // console.log('ProjectCardProvider - fetchCardsInteractive')
      const newCards: Card[] | undefined = await fetchCards()
      if (!newCards) throw Error('fetch cards failed')
      return newCards
    } catch (error) {
      console.error('ProjectCardProvider - fetchCardsInteractive - error:', error.message)
      window.alert('Something went wrong.') // FIXME add toast
    }
  }

  const getCards = (): Card[] => {
    // console.log('ProjectCardProvider - getCards')
    return _.orderBy(cards, ['entity_type', 'name'])
  }

  const getFetching = (): boolean => {
    // console.log('ProjectCardProvider - getFetching')
    return fetching
  }

  const updateCard = async (cardId: number, props: Partial<Card>): Promise<Card | undefined> => {
    try {
      // console.log('ProjectCardProvider - updateCard - cardId:', cardId, 'props:', props)
      const oldCard: Card = _.find(cards, { id: cardId })!
      let newCard: Card
      if (CARDS_MOCK_API_ENABLED) {
        newCard = { ...oldCard, ...props }
        const oldMockCards: Card[] = await mockAPI.getCards()
        const newMockCards: Card[] = _.map(oldMockCards, card => card.id === oldCard.id ? newCard : card)
        await mockAPI.setCards(newMockCards)
      } else {
        // FIXME add response type
        const response = await serverAPIClient.apiPut(`/projects/card/${cardId}`, props, {
          'company-id': company.id,
          'project-id': project.id
        })
        // console.log('ProjectCardProvider - updateCard - response:', response)
        // FIXME check response status
        // FIXME add type guard
        newCard = response.data.message
      }
      const newCards: Card[] = _.map(cards, card => card.id === oldCard.id ? newCard : card)
      setCards(newCards)
      await user.actions.refreshChannels() // refresh viewer
      return newCard
    } catch (error) {
      console.error('ProjectCardProvider - updateCard - error:', error.message)
    }
  }

  const updateCardInteractive = async (cardId: number, props: Partial<Card>): Promise<Card | undefined> => {
    try {
      // console.log('ProjectCardProvider - updateCardInteractive - cardId:', cardId, 'props:', props)
      const oldCard: Card | undefined = _.find(cards, { id: cardId })!
      if (
        _.some(oldCard.notices) &&
        !window.confirm(`WARNING: "${oldCard.name}" is used by ${_.size(oldCard.notices)} ${OBJECT_NOTICE_NAME}(s). Are you sure you want to edit it?`) // FIXME add custom dialog box
      ) return
      const newCard: Card | undefined = await updateCard(cardId, props)
      if (!newCard) throw Error('update card failed')
      // window.alert(`"${newCard.name}" has been updated successfully.`) // FIXME add toast
      return newCard
    } catch (error) {
      console.error('ProjectCardProvider - updateCardInteractive - error:', error.message)
      window.alert('Something went wrong.') // FIXME add toast
    }
  }

  return (
    <ProjectCardContext.Provider value={{
      clearCards,
      createCard,
      createCardInteractive,
      deleteCard,
      deleteCardInteractive,
      duplicateCard,
      duplicateCardInteractive,
      fetchCards,
      fetchCardsInteractive,
      getCards,
      getFetching,
      updateCard,
      updateCardInteractive
    }}>
      {props.children}
    </ProjectCardContext.Provider>
  )
}

export default ProjectCardProvider
