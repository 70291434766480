import React from 'react'
import Avatar from 'react-avatar'

import { Channel, Program } from 'src/core/models'

import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkLoader from 'src/core/components/ArkLoader'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'

import ProjectChannelProgramsPanel from './ProjectChannelProgramsPanel'
import ChannelDeleteView, { ProjectChannelDeleteCallback } from './ChannelDeleteView'

import { DEFAULT_PICKER_COLOR } from 'src/constants/config'
// import { OBJECT_CHANNEL_NAME } from 'src/constants/strings'

import styles from './ProjectChannelSidebar.module.css'

export type ProjectChannelEditCallback = (channel: Channel) => void

interface IProps {
  companyId: number
  projectId: number
  channel?: Channel
  onChange?: Function
  onEdit?: ProjectChannelEditCallback
  onDidDelete?: ProjectChannelDeleteCallback
  loadingPrograms: boolean
  programs: Program[]
}
interface IState {
}

class ProjectChannelSidebar extends React.Component<IProps, IState> {
  render () {
    const { companyId, projectId, channel, onChange, onEdit: _onEdit, onDidDelete, loadingPrograms, programs } = this.props
    if (!channel) return (<></>)

    // FIXME refactor since moving from list item
    const programsComponent = (
      <div className={styles.channelPrograms}>

        <ArkSpacer />

        {loadingPrograms && <ArkLoader small />}

        {/* TODO: decide how to structure these programs, e.g. in a (sub)table? in a ul list or some SUI equiv? or just divs styled manually? */}
        {!loadingPrograms && programs && programs.length > 0 && (
          <div className={styles.channelPrograms + ' ' + styles.list}>
            <h4>Channel Programs:</h4>
            <ul>
              {programs.map((program: Program) => {
                return (
                  <li key={'program_' + program.id}>
                    <Avatar
                      name={program.name ?? ''}
                      initials={program.shortNameCapitalised() ?? ''}
                      maxInitials={3}
                      size="30"
                      round={false}
                      color={program.colour ?? DEFAULT_PICKER_COLOR}
                    />
                  </li>
                )
              })}
            </ul>
          </div>
        )}
        {!loadingPrograms && (!programs || programs.length === 0) && (
          <div>
            ⚠️ No programs
          </div>
        )}
      </div>
    )

    const onEdit = () => {
      if (_onEdit && channel) _onEdit(channel)
    }

    return (
      <>
        <ArkPanel bordered className={styles.sidebarHeader}>
          <ArkPanel.Header className={styles.sidebarTitle} title={channel.name} icon={<Avatar className={styles.channelAvatar} name={channel.name ?? ''} maxInitials={3} size="40" round={false} color={channel.colour ?? DEFAULT_PICKER_COLOR}/>}/>
          <ArkIconButton
            className={styles.menuButton}
            name='settings'
            onClick={onEdit}
            size={24}
          />
        </ArkPanel>

        {/* <ArkButton type="button" fluid size="large" onClick={onEdit}>EDIT {OBJECT_CHANNEL_NAME}</ArkButton> */}

        {/* <ArkPanel bordered>
          <ArkPanel.Properties>
            <ArkPanel.PropertyRow title='ID:' value={channel.id} />
            <ArkPanel.PropertyRow title='Programs:' value={
              <>{channel.programCount ?? 'n/a'}</>}
            />
          </ArkPanel.Properties>
        </ArkPanel> */}

        {programsComponent}

        <ArkSpacer />

        <ProjectChannelProgramsPanel
          companyId={companyId}
          projectId={projectId}
          channel={channel}
          onChange={onChange}
        />

        <ArkSpacer grow />

        <ChannelDeleteView
          companyId={companyId}
          projectId={projectId}
          channel={channel}
          onDidDelete={onDidDelete}
        />

      </>
    )
  }
}

export default ProjectChannelSidebar
