import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import _ from 'lodash'

import { AUTO_SOLO_ENABLED, NOTICES_ENABLED, SOLO_ENABLED } from 'src/constants/config'
import { Channel, Program, Project, UserCompany } from 'src/core/models'
import { DEFAULT_CHANNEL, DEFAULT_PROGRAM, useLocalConfig } from 'src/core/providers/LocalConfigProvider'
import { LocalConfigChannel, LocalConfigProgram } from 'src/core/providers/LocalConfigProvider/types'
import { useUser } from 'src/core/providers/UserProvider'
import { ChannelGridCoords, ChannelGridLayout, ChannelLayout, DEFAULT_COORDS } from 'src/core/types/channel'
import { PlayerResolution } from 'src/core/types/player'
import { delay } from 'src/core/utilities/delay'

import { useViewerNotice } from './ViewerNoticeProvider'

export enum ChannelState {
  Loading,
  NoChannels,
  NoChannel,
  Notices,
  NoPrograms,
  Programs
}

export interface IViewerContext {
  audioStarted: boolean
  autoGridCoords: ChannelGridCoords
  channel: Channel | undefined
  channels: Channel[] | undefined
  fullscreen: boolean
  programs: Program[] | undefined
  project: Project | undefined
  restarting: boolean
  showMixer: boolean
  stopped: boolean
  deselectChannel: () => void
  getChannelAbr: () => boolean
  getChannelAdvancedParameters: () => string
  getChannelAutoSolo: () => boolean
  getChannelAutoSoloProgram: () => number | undefined
  getChannelBuffer: () => number
  getChannelGridLayout: () => ChannelGridLayout
  getChannelLayout: () => ChannelLayout
  getChannelMute: () => boolean
  getChannelPassthrough: () => boolean
  getChannelResolution: () => PlayerResolution
  getChannelSelectedProgram: () => number | undefined
  getChannelState: () => ChannelState
  getChannelVolume: () => number
  getPlayerMute: (id: number) => boolean
  getPlayerVolume: (id: number) => number
  getProgramMute: (id: number) => boolean
  getProgramSolo: (id: number) => boolean
  getProgramVolume: (id: number) => number
  getSomeProgramsSolo: () => boolean
  refreshChannel: () => void
  resetChannel: () => void
  restart: () => void
  selectChannel: (id: number) => void
  setAutoGridCoords: (value: ChannelGridCoords) => void
  setChannelAbr: (value: boolean) => void
  setChannelAdvancedParameters: (value: string) => void
  setChannelAutoSolo: (value: boolean) => void
  setChannelAutoSoloProgram: (id: number | undefined) => void
  setChannelBuffer: (value: number) => void
  setChannelGridLayout: (value: ChannelGridLayout) => void
  setChannelLayout: (value: ChannelLayout) => void
  setChannelMute: (value: boolean) => void
  setChannelPassthrough: (value: boolean) => void
  setChannelResolution: (value: PlayerResolution) => void
  setChannelSelectedProgram: (id: number | undefined) => void
  setChannelVolume: (value: number) => void
  setProgramMute: (id: number, value: boolean) => void
  setProgramSolo: (id: number, value: boolean) => void
  setProgramVolume: (id: number, value: number) => void
  setShowMixer: (value: boolean) => void
  setStopped: (value: boolean) => void
  startAudio: () => void
}

interface ViewerProviderProps {
  children: ReactNode
}

export const ViewerContext = createContext<IViewerContext>({} as IViewerContext)

export const useViewer = () => useContext(ViewerContext)

const ViewerProvider = (props: ViewerProviderProps) => {
  const { children } = props

  const localConfig = useLocalConfig()
  const user = useUser()
  const viewerNotice = useViewerNotice()

  const [audioStarted, _setAudioStarted] = useState<boolean>(false)
  const [autoGridCoords, setAutoGridCoords] = useState<ChannelGridCoords>(DEFAULT_COORDS)
  const [fullscreen, setFullscreen] = useState<boolean>(false)
  const [restarting, _setRestarting] = useState<boolean>(false)
  const [showMixer, setShowMixer] = useState<boolean>(false)
  const [stopped, setStopped] = useState<boolean>(false)

  const channel: Channel | undefined = user.store.selectedChannel
  const channels: Channel[] | undefined = user.store.projectChannels
  const company: UserCompany | undefined = user.store.selectedCompany
  const programs: Program[] | undefined = user.store.channelPrograms
  const project: Project | undefined = user.store.selectedProject

  const companyId: number | undefined = company?.id
  const projectId: number | undefined = project?.id
  const channelId: number | undefined = channel?.id

  // console.log('ViewerProvider - render - companyId:', companyId, 'projectId:', projectId, 'channelId:', channelId)

  const deselectChannel = () => {
    user.actions.deselectCurrentChannel()
  }

  const getChannelAbr = (): boolean => {
    if (!companyId || !projectId || !channelId) return DEFAULT_CHANNEL.abr
    return localConfig.getLocalConfigChannel({ companyId, projectId, channelId }).abr
  }

  const getChannelAdvancedParameters = (): string => {
    if (!companyId || !projectId || !channelId) return DEFAULT_CHANNEL.advancedParameters
    return localConfig.getLocalConfigChannel({ companyId, projectId, channelId }).advancedParameters
  }

  const getChannelAutoSolo = (): boolean => {
    if (!AUTO_SOLO_ENABLED) return false
    if (!companyId || !projectId || !channelId) return DEFAULT_CHANNEL.autoSolo
    return localConfig.getLocalConfigChannel({ companyId, projectId, channelId }).autoSolo
  }

  const getChannelAutoSoloProgram = (): number | undefined => {
    if (!AUTO_SOLO_ENABLED) return undefined
    if (!companyId || !projectId || !channelId) return DEFAULT_CHANNEL.autoSoloProgram
    return localConfig.getLocalConfigChannel({ companyId, projectId, channelId }).autoSoloProgram
  }

  const getChannelBuffer = (): number => {
    if (!companyId || !projectId || !channelId) return DEFAULT_CHANNEL.buffer
    return localConfig.getLocalConfigChannel({ companyId, projectId, channelId }).buffer
  }

  const getChannelGridLayout = (): ChannelGridLayout => {
    if (!companyId || !projectId || !channelId) return DEFAULT_CHANNEL.gridLayout
    return localConfig.getLocalConfigChannel({ companyId, projectId, channelId }).gridLayout
  }

  const getChannelLayout = (): ChannelLayout => {
    if (!companyId || !projectId || !channelId) return DEFAULT_CHANNEL.layout
    return localConfig.getLocalConfigChannel({ companyId, projectId, channelId }).layout
  }

  const getChannelMute = (): boolean => {
    if (!companyId || !projectId || !channelId) return DEFAULT_CHANNEL.mute
    return localConfig.getLocalConfigChannel({ companyId, projectId, channelId }).mute
  }

  const getChannelPassthrough = (): boolean => {
    if (!companyId || !projectId || !channelId) return DEFAULT_CHANNEL.passthrough
    return localConfig.getLocalConfigChannel({ companyId, projectId, channelId }).passthrough
  }

  const getChannelResolution = (): PlayerResolution => {
    if (!companyId || !projectId || !channelId) return DEFAULT_CHANNEL.resolution
    return localConfig.getLocalConfigChannel({ companyId, projectId, channelId }).resolution
  }

  const getChannelSelectedProgram = (): number | undefined => {
    if (!companyId || !projectId || !channelId) return DEFAULT_CHANNEL.selectedProgram
    return localConfig.getLocalConfigChannel({ companyId, projectId, channelId }).selectedProgram
  }

  const getChannelState = (): ChannelState => {
    if (user.store.loadingCompanyData || user.store.loadingProjectData || user.store.loadingChannelData) return ChannelState.Loading
    if (!_.some(channels)) return ChannelState.NoChannels
    if (!channel) return ChannelState.NoChannel
    if (NOTICES_ENABLED && _.some(viewerNotice.getFilteredNotices(channel))) return ChannelState.Notices
    if (!_.some(programs)) return ChannelState.NoPrograms
    return ChannelState.Programs
  }

  const getChannelVolume = (): number => {
    if (!companyId || !projectId || !channelId) return DEFAULT_CHANNEL.volume
    return localConfig.getLocalConfigChannel({ companyId, projectId, channelId }).volume
  }

  const getPlayerMute = (id: number): boolean => {
    if (!companyId || !projectId || !channelId || !id) return DEFAULT_PROGRAM.mute
    if (!audioStarted) return true
    const channelConfig: LocalConfigChannel = localConfig.getLocalConfigChannel({ companyId, projectId, channelId })
    const programConfig: LocalConfigProgram = channelConfig.programs[id]
    if (channelConfig.mute) return true
    if (channelConfig.autoSoloProgram) return channelConfig.autoSoloProgram !== id
    if (_.some(channelConfig.programs, 'solo')) return !programConfig.solo
    if (programConfig.mute) return true
    return false
  }

  const getPlayerVolume = (id: number): number => {
    if (!companyId || !projectId || !channelId || !id) return DEFAULT_PROGRAM.volume
    const channelConfig: LocalConfigChannel = localConfig.getLocalConfigChannel({ companyId, projectId, channelId })
    const programConfig: LocalConfigProgram = channelConfig.programs[id]
    return programConfig.volume * channelConfig.volume
  }

  const getProgramMute = (id: number): boolean => {
    if (!companyId || !projectId || !channelId || !id) return DEFAULT_PROGRAM.mute
    return localConfig.getLocalConfigProgram({ companyId, projectId, channelId, programId: id }).mute
  }

  const getProgramSolo = (id: number): boolean => {
    if (!SOLO_ENABLED) return false
    if (!companyId || !projectId || !channelId || !id) return DEFAULT_PROGRAM.solo
    return localConfig.getLocalConfigProgram({ companyId, projectId, channelId, programId: id }).solo
  }

  const getProgramVolume = (id: number): number => {
    if (!companyId || !projectId || !channelId || !id) return DEFAULT_PROGRAM.volume
    return localConfig.getLocalConfigProgram({ companyId, projectId, channelId, programId: id }).volume
  }

  const getSomeProgramsSolo = (): boolean => {
    if (!companyId || !projectId || !channelId) return false
    const channelConfig: LocalConfigChannel = localConfig.getLocalConfigChannel({ companyId, projectId, channelId })
    return _.some(channelConfig.programs, 'solo')
  }

  const refreshChannel = async () => {
    await user.actions.updateSelectedChannelPrograms()
  }

  const resetChannel = () => {
    if (!companyId || !projectId || !channelId) return
    localConfig.setLocalConfigChannel(DEFAULT_CHANNEL, { companyId, projectId, channelId })
  }

  const restart = async () => {
    _setRestarting(true)
    await delay(1000)
    _setRestarting(false)
  }

  const selectChannel = (id: number) => {
    user.actions.selectChannel(id)
  }

  const setChannelAbr = (value: boolean): void => {
    if (!companyId || !projectId || !channelId) return
    localConfig.setLocalConfigChannel({ abr: value }, { companyId, projectId, channelId })
  }

  const setChannelAdvancedParameters = (value: string): void => {
    if (!companyId || !projectId || !channelId) return
    localConfig.setLocalConfigChannel({ advancedParameters: value }, { companyId, projectId, channelId })
  }

  const setChannelAutoSolo = (value: boolean): void => {
    if (!SOLO_ENABLED) return
    if (!companyId || !projectId || !channelId) return
    let autoSoloProgram: number | undefined
    if (value) {
      if (getChannelLayout() === ChannelLayout.BottomBar) {
        autoSoloProgram = getChannelSelectedProgram()
      } else {
        autoSoloProgram = _.first(programs)?.id
      }
    }
    localConfig.setLocalConfigChannel(
      { autoSolo: value, autoSoloProgram, ...(autoSoloProgram && { selectedProgram: autoSoloProgram }) },
      { companyId, projectId, channelId }
    )
  }

  const setChannelAutoSoloProgram = (id: number | undefined): void => {
    if (!AUTO_SOLO_ENABLED) return
    if (!companyId || !projectId || !channelId) return
    localConfig.setLocalConfigChannel(
      { autoSoloProgram: id, ...(id && { selectedProgram: id }) },
      { companyId, projectId, channelId }
    )
  }

  const setChannelBuffer = (vaue: number): void => {
    if (!companyId || !projectId || !channelId) return
    localConfig.setLocalConfigChannel({ buffer: vaue }, { companyId, projectId, channelId })
  }

  const setChannelGridLayout = (value: ChannelGridLayout): void => {
    if (!companyId || !projectId || !channelId) return
    localConfig.setLocalConfigChannel({ gridLayout: value }, { companyId, projectId, channelId })
  }

  const setChannelLayout = (value: ChannelLayout): void => {
    if (!companyId || !projectId || !channelId) return
    localConfig.setLocalConfigChannel({ layout: value }, { companyId, projectId, channelId })
  }

  const setChannelMute = (value: boolean): void => {
    if (!companyId || !projectId || !channelId) return
    localConfig.setLocalConfigChannel({ mute: value }, { companyId, projectId, channelId })
  }

  const setChannelPassthrough = (value: boolean): void => {
    if (!companyId || !projectId || !channelId) return
    localConfig.setLocalConfigChannel({ passthrough: value }, { companyId, projectId, channelId })
  }

  const setChannelResolution = (value: PlayerResolution): void => {
    if (!companyId || !projectId || !channelId) return
    localConfig.setLocalConfigChannel({ resolution: value }, { companyId, projectId, channelId })
  }

  const setChannelSelectedProgram = (id: number | undefined): void => {
    if (!companyId || !projectId || !channelId) return
    localConfig.setLocalConfigChannel({ selectedProgram: id }, { companyId, projectId, channelId })
  }

  const setChannelVolume = (value: number): void => {
    if (!companyId || !projectId || !channelId) return
    localConfig.setLocalConfigChannel({ volume: value }, { companyId, projectId, channelId })
  }

  const setProgramMute = (id: number, value: boolean): void => {
    if (!companyId || !projectId || !channelId || !id) return
    localConfig.setLocalConfigProgram({ mute: value }, { companyId, projectId, channelId, programId: id })
  }

  const setProgramSolo = (id: number, value: boolean): void => {
    if (!SOLO_ENABLED) return
    if (!companyId || !projectId || !channelId || !id) return
    localConfig.setLocalConfigProgram({ solo: value }, { companyId, projectId, channelId, programId: id })
  }

  const setProgramVolume = (id: number, value: number): void => {
    if (!companyId || !projectId || !channelId || !id) return
    localConfig.setLocalConfigProgram({ volume: value }, { companyId, projectId, channelId, programId: id })
  }

  const startAudio = (): void => {
    _setAudioStarted(true)
    if (!companyId || !projectId || !channelId) return
    localConfig.setLocalConfigChannel({ mute: false }, { companyId, projectId, channelId })
  }

  /**
   * effects
   */

  // handle browser exiting fullscreen (inc. pressing esc key)
  useEffect(() => {
    document.onfullscreenchange = () => { // only supports one global listener
      setFullscreen(!!document.fullscreenElement)
    }
  }, [])

  useEffect(() => {
    const firstProgram: Program | undefined = _.first(programs)
    if (!firstProgram) return

    // auto solo - if auto solo is on and no program is selected, select the first program
    if (getChannelAutoSolo() && !getChannelAutoSoloProgram()) {
      setChannelAutoSoloProgram(firstProgram.id)
    }

    // bottom bar layout - if no program is selected, select the first program
    if (!getChannelSelectedProgram()) {
      setChannelSelectedProgram(firstProgram.id)
    }
  }, [programs])

  /**
   * render
   */

  const context: IViewerContext = {
    audioStarted,
    autoGridCoords,
    channel,
    channels,
    fullscreen,
    programs,
    project,
    restarting,
    showMixer,
    stopped,
    deselectChannel,
    getChannelAbr,
    getChannelAdvancedParameters,
    getChannelAutoSolo,
    getChannelAutoSoloProgram,
    getChannelBuffer,
    getChannelGridLayout,
    getChannelLayout,
    getChannelMute,
    getChannelPassthrough,
    getChannelResolution,
    getChannelSelectedProgram,
    getChannelState,
    getChannelVolume,
    getPlayerMute,
    getPlayerVolume,
    getProgramMute,
    getProgramSolo,
    getProgramVolume,
    getSomeProgramsSolo,
    refreshChannel,
    resetChannel,
    restart,
    selectChannel,
    setAutoGridCoords,
    setChannelAbr,
    setChannelAdvancedParameters,
    setChannelAutoSolo,
    setChannelAutoSoloProgram,
    setChannelBuffer,
    setChannelGridLayout,
    setChannelLayout,
    setChannelMute,
    setChannelPassthrough,
    setChannelResolution,
    setChannelSelectedProgram,
    setChannelVolume,
    setProgramMute,
    setProgramSolo,
    setProgramVolume,
    setShowMixer,
    setStopped,
    startAudio
  }

  return (
    <ViewerContext.Provider value={context}>
      {children}
    </ViewerContext.Provider>
  )
}
export default ViewerProvider
