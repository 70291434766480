import React from 'react'
import _ from 'lodash'

import { ArkManagerFilteredItem } from 'src/core/components/ArkManagerListView/ArkManagerListView'
import { Channel, Notice } from 'src/core/models'
import { OBJECT_CHANNEL_NAME_PLURAL } from 'src/constants/strings'
import { NoticeConditions } from 'src/core/models/notice'
import { decodeNoticeConditions } from 'src/core/utilities/notice'

export const getFilteredNotices = (notices: Notice[], filter: string): ArkManagerFilteredItem<Notice>[] => {
  if (!filter) return []
  return _.chain(notices)
    .map(card => {
      const matchingFields: string[] = []
      if (card.name.toLowerCase().includes(filter.toLowerCase())) matchingFields.push('name')
      if (card.description.toLowerCase().includes(filter.toLowerCase())) matchingFields.push('description')
      return _.some(matchingFields) && { item: card, matchingFields }
    })
    .compact()
    .value()
}

export const getNoticeChannelsText = (notice: Notice, channels?: Channel[]): JSX.Element | string => {
  if (notice.all_channels) return `All ${OBJECT_CHANNEL_NAME_PLURAL.toLowerCase()}`
  if (_.some(notice.channels)) {
    return (
      <div>
        <div>{`${OBJECT_CHANNEL_NAME_PLURAL}:`}</div>
        {_.map(notice.channels, id => {
          const channel: Channel | undefined = _.find(channels, { id })
          return <div key={id}>{`• ${channel ? channel.name : id}`}</div>
        })}
      </div>
    )
  }
  return `No ${OBJECT_CHANNEL_NAME_PLURAL}`
}

export const getNoticeConditionsText = (notice: Notice): JSX.Element | string => {
  if (notice.conditions) {
    const conditions: NoticeConditions = decodeNoticeConditions(notice.conditions)
    if (conditions.dateRange) {
      const startDate = new Date(conditions.dateRange.start)
      const endDate = new Date(conditions.dateRange.end)
      return <div>
        <div>Date & time range:</div>
        <div>{`${startDate.toUTCString().replace(':00 GMT', ' UTC')} >`}</div>
        <div>{endDate.toUTCString().replace(':00 GMT', ' UTC')}</div>
      </div>
    }
  }
  return 'No conditions'
}

export const getNoticeDismissibleText = (notice: Notice) : string => {
  if (notice.dismissible) return 'Yes'
  if (notice.dismissible_admin) return 'Admins'
  return 'No'
}
