/**
 * Config
 */

import { IconName } from 'src/core/components/ArkIcon'

import { ArkSectionSubMenuItem } from '../core/models'
import * as ROUTES from './routes'

import { CARDS_ENABLED, MENUS_WIP_PAGES_HIDDEN, NOTICES_ENABLED } from './config'
import {
  OBJECT_BROADCAST_NAME,
  OBJECT_CARD_NAME_TITLE,
  OBJECT_CHANNEL_NAME_PLURAL,
  OBJECT_COMPANY_NAME_PLURAL,
  OBJECT_COMPANY_SHORTNAME,
  OBJECT_GROUP_NAME_PLURAL,
  OBJECT_NOTICE_NAME_TITLE,
  OBJECT_PROGRAM_NAME_PLURAL,
  OBJECT_PROJECT_NAME,
  OBJECT_PROJECT_NAME_PLURAL,
  OBJECT_USER_NAME_PLURAL,
  PAGE_ACTIVITY_NAME,
  PAGE_DASHBOARD_NAME,
  PAGE_SETTINGS_NAME,
  PAGE_SUPPORT_NAME,
  PAGE_STREAMHUB_NAME,
  PAGE_VIDEO_ENGINES_NAME
} from './strings'

export const viewerMenuItmes = (projectId?: number, channelId?: number) : Array<ArkSectionSubMenuItem> => [
  {
    path: ROUTES.VIEW_PROJECT,
    title: OBJECT_CHANNEL_NAME_PLURAL,
    iconName: 'channel',
    projectId,
    channelId,
    formatPath: (path: string) => {
      if (channelId) {
        return ROUTES.VIEW_PROJECT_CHANNEL.replace(':projectId', projectId ? '' + projectId : '').replace(':channelId', channelId ? '' + channelId : '')
      }
      return path.replace(':projectId', projectId ? '' + projectId : '')
    }
  },
  { path: ROUTES.VIEWER_SETTINGS, title: PAGE_SETTINGS_NAME, iconName: 'settings', projectId, hidden: MENUS_WIP_PAGES_HIDDEN }
]

export const projectMenuItems = (projectId?: number) : Array<ArkSectionSubMenuItem> => [
  { path: ROUTES.PROJECT_MANAGER_VIEW, title: PAGE_DASHBOARD_NAME, iconName: 'dashboard', projectId },
  { path: ROUTES.PROJECT_MANAGER_USERS, title: OBJECT_PROJECT_NAME + ' ' + 'Access', iconName: 'user-project', projectId },
  { path: ROUTES.PROJECT_MANAGER_GROUPS, title: OBJECT_GROUP_NAME_PLURAL, iconName: 'group', projectId },
  { path: ROUTES.PROJECT_MANAGER_PROGRAMS, title: OBJECT_PROGRAM_NAME_PLURAL, iconName: 'program', projectId },
  { path: ROUTES.PROJECT_MANAGER_CHANNELS, title: OBJECT_CHANNEL_NAME_PLURAL, iconName: 'channel', projectId },
  ...(CARDS_ENABLED ? [{ path: ROUTES.PROJECT_MANAGER_CARDS, title: OBJECT_CARD_NAME_TITLE, iconName: 'card' as IconName, projectId }] : []),
  ...(NOTICES_ENABLED ? [{ path: ROUTES.PROJECT_MANAGER_NOTICES, title: OBJECT_NOTICE_NAME_TITLE, iconName: 'notice' as IconName, projectId }] : []),
  { path: ROUTES.PROJECT_MANAGER_BROADCAST, title: OBJECT_BROADCAST_NAME, iconName: 'broadcast', projectId },
  // { path: ROUTES.PROJECT_MANAGER_ACTIVITY, title: PAGE_ACTIVITY_NAME, iconName: 'activity', projectId },
  { path: ROUTES.PROJECT_MANAGER_SETTINGS, title: PAGE_SETTINGS_NAME, iconName: 'settings', projectId }
]

export const companyMenuItems = () : Array<ArkSectionSubMenuItem> => [
  { path: ROUTES.COMPANY_MANAGER, title: PAGE_DASHBOARD_NAME, iconName: 'dashboard' },
  { path: ROUTES.COMPANY_MANAGER_PROJECTS, title: OBJECT_PROJECT_NAME_PLURAL, iconName: 'project' },
  ...(CARDS_ENABLED ? [{ path: ROUTES.COMPANY_MANAGER_CARDS, title: OBJECT_CARD_NAME_TITLE, iconName: 'card' as IconName }] : []),
  { path: ROUTES.COMPANY_MANAGER_USERS, title: OBJECT_COMPANY_SHORTNAME + ' ' + OBJECT_USER_NAME_PLURAL, iconName: 'user-company' },
  { path: ROUTES.COMPANY_MANAGER_VIDEO_ENGINES.replace('/:veKey?', ''), title: PAGE_VIDEO_ENGINES_NAME, iconName: 'video-engine' }, // TODO: icon
  { path: ROUTES.COMPANY_MANAGER_ACTIVITY, title: PAGE_ACTIVITY_NAME, iconName: 'activity', hidden: MENUS_WIP_PAGES_HIDDEN },
  { path: ROUTES.COMPANY_MANAGER_SETTINGS, title: PAGE_SETTINGS_NAME, iconName: 'settings' }
]

export const adminMenuItems = () : Array<ArkSectionSubMenuItem> => [
  { path: ROUTES.ADMIN, title: PAGE_DASHBOARD_NAME, iconName: 'dashboard' },
  { path: ROUTES.ADMIN_COMPANIES, title: OBJECT_COMPANY_NAME_PLURAL, iconName: 'company' },
  { path: ROUTES.ADMIN_ACTIVITY, title: PAGE_ACTIVITY_NAME, iconName: 'activity', hidden: MENUS_WIP_PAGES_HIDDEN },
  { path: ROUTES.ADMIN_VIDEO_ENGINES.replace('/:veKey?', ''), title: PAGE_VIDEO_ENGINES_NAME, iconName: 'video-engine' }, // TODO: icon
  { path: ROUTES.ADMIN_STREAMHUB.replace('/:serverKey?', ''), title: PAGE_STREAMHUB_NAME, iconName: 'streamhub' },
  { path: ROUTES.ADMIN_SETTINGS, title: PAGE_SETTINGS_NAME, iconName: 'settings', hidden: MENUS_WIP_PAGES_HIDDEN }
]

export const supportMenuItems = () : Array<ArkSectionSubMenuItem> => [
  { path: ROUTES.SUPPORT, title: PAGE_SUPPORT_NAME, iconName: 'support', target: '_blank' } // className: '__support',
]
